import { documentApi, projectApi } from "apis";

const useProjectPackagingSystem = ({
  projectId,
  languageCode,
  setSilentRefresh = null,
}) => {
  const onDetach = async record => {
    const { data: projectData } = await projectApi.getProjectById({
      projectId,
      languageCode,
    });
    const { packagingSystemToProductRelations } = projectData;
    let filtered = packagingSystemToProductRelations.filter(relation => {
      return relation.manufacturedItemId !== record.id;
    });
    await projectApi.patchPackagingSystemToProduct(projectId, filtered);
    if (setSilentRefresh) setSilentRefresh(true);
  };

  const extractPackagingComponentDocuments = data => {
    const Objectkeys = Object.keys(data);
    let packagingComponentDocuments = [];
    Objectkeys.forEach(key => {
      if (data[key].hasOwnProperty("packagingComponentDocuments")) {
        packagingComponentDocuments = data[key].packagingComponentDocuments;
      }
    });

    return packagingComponentDocuments;
  };

  const getFilesFromData = (data: any) => {
    const packagingComponentDocuments =
      extractPackagingComponentDocuments(data);

    return (
      packagingComponentDocuments
        ?.flatMap(d => d?.packagingDocumentFiles)
        ?.map(({ uploadedDocuments }) => uploadedDocuments[0]) ?? []
    );
  };

  const clearPackagingComponentDocumentsFormData = data => {
    const Objectkeys = Object.keys(data);
    Objectkeys.forEach(key => {
      if (data[key].hasOwnProperty("packagingComponentDocuments")) {
        data[key].packagingComponentDocuments = [];
      }
      if (data[key]?.hasOwnProperty("packagingSystemId")) {
        delete data[key].packagingSystemId;
      }
    });
  };

  const onTemporaryDetach = async (record, taskId) => {
    const { data: taskData } = await projectApi.getTaskByTaskId({
      taskId,
      languageCode,
    });
    if (taskData.manufacturedItemId === record.id) {
      const { templateBlockId, dataAsJson } = taskData.form?.formBlocks?.[0];
      const auxiliaryData = { packagingSystemId: null };
      const data = JSON.parse(dataAsJson);
      const files = getFilesFromData(data);
      if (files.length) {
        await Promise.allSettled(
          files.map(({ fileId, versionId }) =>
            documentApi.deleteFileVersionByVersionId({ fileId, versionId })
          )
        );
      }

      clearPackagingComponentDocumentsFormData(data);
      await projectApi.saveTaskFormByIdWithPackageSystem({
        taskId,
        templateBlockId,
        dataAsJson: JSON.stringify(data),
        auxiliaryData,
      });
    }

    if (setSilentRefresh) setSilentRefresh(true);
  };

  return {
    onDetach,
    onTemporaryDetach,
  };
};

export default useProjectPackagingSystem;
