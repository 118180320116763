import React from "react";
import PropTypes from "prop-types";
import { Container as ContainerProject } from "@trace-one/design-system";

const Container = props => {
  return <ContainerProject>{props.children}</ContainerProject>;
};

Container.propTypes = {
  children: PropTypes.any,
};

export default Container;
