import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import styles from "./Timeline.module.less";
import moment from "moment-business-days";
import { getLocalDate } from "utils/DateUtils";
import {
  selectStartDate,
  selectEndDate,
} from "common/selectors/projects.selectors";
import { FormattedMessage, useIntl } from "react-intl";
import { timelineSteps } from "routes/Projects/defineMessages";

const Timeline = () => {
  const startDate = useSelector(selectStartDate);
  const endDate = useSelector(selectEndDate);
  const today = moment();
  const fromTodayToStart = Math.round(moment(startDate).businessDiff(today));
  const fromStartToEnd = Math.round(
    moment(startDate).businessDiff(moment(endDate))
  );
  const intl = useIntl();
  return (
    <ul className={styles.timeline}>
      <li className={classNames(styles.dashed, styles.time)}>
        <em className={styles.circle}></em>
        <div className={classNames(styles.stepText, styles.todayText)}>
          {intl.formatMessage(timelineSteps.today)}
        </div>
        <p className={styles.duration}>
          {fromTodayToStart}
          <FormattedMessage
            id="projects.creation.summary.timeline.daysBegin"
            defaultMessage=" DAY"
          />
          {`${fromTodayToStart <= 1 ? "" : "S"}`}
        </p>
      </li>
      <li
        className={classNames(styles.solid, styles.time, styles.circleElement)}
      >
        <div className={styles.dateElement}>
          {startDate && getLocalDate(startDate)}
        </div>
        <em className={styles.circle}></em>
        <div className={classNames(styles.stepText, styles.startText)}>
          {intl.formatMessage(timelineSteps.start)}
        </div>
      </li>
      <li className={classNames(styles.dashed, styles.time)}>
        <p className={styles.duration}>
          {fromStartToEnd}
          <FormattedMessage
            id="projects.creation.summary.timeline.daysEnd"
            defaultMessage=" DAY"
          />
          {`${fromStartToEnd <= 1 ? "" : "S"}`}
        </p>
      </li>
      <li className={styles.solid}></li>
      <li>
        <ul>
          <li
            className={classNames(
              styles.solid,
              styles.time,
              styles.circleElement
            )}
          >
            <div className={styles.dateElement}>
              {endDate && getLocalDate(endDate)}
            </div>
            <em className={styles.circle}></em>
            <div className={classNames(styles.stepText, styles.endText)}>
              {intl.formatMessage(timelineSteps.end)}
            </div>
          </li>
        </ul>
      </li>
    </ul>
  );
};

export default Timeline;
