import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isEqual } from "lodash";
import {
  resetProjectCreation,
  setCreationPopUp,
  setDisplayToasterSaving,
  setDuplicateProjectCreation,
} from "redux/actions/ProjectCreation";
import { registerStepperData } from "redux/actions/ProjectUI";
import { Title, useLanguagePreference } from "@trace-one/react-components";
import { toaster as toasterService } from "@trace-one/design-system";
import {
  selectProjectCreationCurrentData,
  selectIsSaved,
  selectDisplayToasterSaving,
  selectProjectCreationSavedData,
  selectIsTeamsLoading,
} from "common/selectors/projects.selectors";
import {
  selectUserId,
  selectOwningCompanyId,
} from "common/selectors/users.selectors";
import { saveDraft } from "redux/actions/ProjectCreation";
import { setWarningFields } from "redux/actions/ProjectUI";
import { getWarnFields, canAccess } from "./helpers";
import { CREATION_STEP } from "utils/ProjectUtils";
import Buttons from "./components/Buttons";
import { PromptPopup } from "./components/PromptPopup/PromptPopup.js";
import Steps from "./components/Stepper";
import Definition from "./Definition";
import { Products } from "./Products";
import { updateStepper, isValid } from "./helpers";
import { Planning } from "./Planning";
import { Summary } from "./Summary";
import { Team } from "./Team";
import SubtitleContent from "./components/SubtitleContent";
import { alertTrad, titleCreation } from "routes/Projects/defineMessages";
import useRedirectOnCreationActions from "utils/useRedirectOnCreationActions";
import styles from "./Creation.module.less";

const Creation = () => {
  const intl = useIntl();
  const isSaved = useSelector(selectIsSaved);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { step } = useParams();
  const projectData = useSelector(selectProjectCreationCurrentData);
  const createModalUrl = `/projects/creation/products/products-creation`;
  const displayToasterSaving = useSelector(selectDisplayToasterSaving);
  const ownerUserId = useSelector(selectUserId);
  const { formatMessage } = useIntl();
  const durationToaster = 3;
  const projectDataSaved = useSelector(selectProjectCreationSavedData);
  const isModified = !isEqual(projectData, projectDataSaved);
  const languageCode = useLanguagePreference();
  const companyId = useSelector(selectOwningCompanyId);
  const isLoading = useSelector(selectIsTeamsLoading);
  const [shouldrefresh, setRefresh] = useState(false);
  const { nextLocationUrl, handleRedirect, setNextLocationUrl } =
    useRedirectOnCreationActions();

  useEffect(() => {
    if (history.location.pathname === createModalUrl) {
      setRefresh(true);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (isSaved && projectData.id && displayToasterSaving) {
      toasterService.open({
        type: "confirmation",
        message: formatMessage(alertTrad.successSavingMessage),
        description: formatMessage(alertTrad.successSavingDescription),
        duration: durationToaster,
      });
      dispatch(setDisplayToasterSaving(false));
    }
  }, [isSaved, projectData.id]);

  useEffect(() => {
    return () => dispatch(resetProjectCreation());
  }, [dispatch, history]);

  useEffect(() => {
    const setStepperData = () => {
      dispatch(
        registerStepperData(updateStepper({ history, projectData, intl }))
      );
    };
    setStepperData();
  }, [projectData, dispatch, history, intl, location.pathname]);

  const saveAsDraft = async () => {
    // This prevent refresh interfere with cancel creation / save as draft
    // if (!isModified) return;

    if (isValid({ step: CREATION_STEP.definition, projectData })) {
      await dispatch(
        saveDraft({ projectData, ownerUserId, languageCode, companyId, step })
      );

      dispatch(setWarningFields([]));
      dispatch(setDuplicateProjectCreation());
      handleRedirect(nextLocationUrl);
    } else {
      const warnFields = getWarnFields(
        CREATION_STEP.definition,
        projectData,
        intl
      );
      const firstField = document.getElementsByName(warnFields[0].name)[0];
      const heightHeader =
        document.getElementsByTagName("header")[0]?.offsetHeight + 5;
      window.scrollTo(0, firstField?.offsetTop - heightHeader || 0);
      dispatch(setCreationPopUp(false));
      dispatch(setWarningFields(warnFields));
    }
    return true;
  };

  const renderStep = () => {
    if (canAccess({ step, projectData })) {
      switch (location.pathname) {
        case "/projects/creation/definition":
          return <Definition />;
        case "/projects/creation/products":
        case "/projects/creation/products/products-selection":
        case "/projects/creation/products/products-creation":
          return <Products />;
        case "/projects/creation/planning":
          return (
            <Planning shouldrefresh={shouldrefresh} setRefresh={setRefresh} />
          );
        case "/projects/creation/team":
        case "/projects/creation/team/select-team":
          return <Team />;
        case "/projects/creation/summary":
          return <Summary />;
        default:
          return null;
      }
    } else {
      return history.push("/projects/creation/definition");
    }
  };

  return (
    <div className={styles.creationWrapper}>
      <Title
        value={intl.formatMessage(titleCreation)}
        subtitle={<SubtitleContent />}
        level={1}
      />
      <Steps saveAsDraft={saveAsDraft} />
      {renderStep()}
      {!isLoading && (
        <Buttons
          saveAsDraft={saveAsDraft}
          isModified={isModified}
          setRefresh={setRefresh}
        />
      )}
      <PromptPopup
        handleRedirect={handleRedirect}
        nextLocationUrl={nextLocationUrl}
        setNextLocationUrl={setNextLocationUrl}
        saveAsDraft={saveAsDraft}
        isModified={isModified}
      />
    </div>
  );
};

export default Creation;
