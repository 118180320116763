import { defineMessages } from "react-intl";

export const filterIdToName = (isMyProjectsFilterActive, intl) => {
  const filterInProject = defineMessages({
    myProject: {
      id: "constants.projects.myProjectFilter",
      defaultMessage: "My projects",
    },
    myDraft: {
      id: "constants.projects.myDraftFilter",
      defaultMessage: "My draft projects",
    },
    draft: {
      id: "constants.projects.draftFilter",
      defaultMessage: "Draft projects",
    },
    myInProgress: {
      id: "constants.projects.myInProgressFilter",
      defaultMessage: "My in progress projects",
    },
    inProgress: {
      id: "constants.projects.inProgressFilter",
      defaultMessage: "In Progress projects",
    },
    myLate: {
      id: "constants.projects.myLateFilter",
      defaultMessage: "My Late projects",
    },
    late: {
      id: "constants.projects.lateProjectFilter",
      defaultMessage: "Late projects",
    },
    myScheduled: {
      id: "constants.projects.myScheduledFilter",
      defaultMessage: "My scheduled projects",
    },
    scheduled: {
      id: "constants.projects.scheduledFilter",
      defaultMessage: "Scheduled projects",
    },
  });
  return {
    myProjects: intl.formatMessage(filterInProject.myProject),
    draft: `${
      isMyProjectsFilterActive
        ? intl.formatMessage(filterInProject.myDraft)
        : intl.formatMessage(filterInProject.draft)
    }`,
    inProgress: `${
      isMyProjectsFilterActive
        ? intl.formatMessage(filterInProject.myInProgress)
        : intl.formatMessage(filterInProject.inProgress)
    }`,
    late: `${
      isMyProjectsFilterActive
        ? intl.formatMessage(filterInProject.myLate)
        : intl.formatMessage(filterInProject.late)
    }`,
    scheduled: `${
      isMyProjectsFilterActive
        ? intl.formatMessage(filterInProject.myScheduled)
        : intl.formatMessage(filterInProject.scheduled)
    }`,
  };
};

export const filterIds = {
  myProjects: "myProjects",
  inProgressProjects: "inProgress",
  draftProjects: "draft",
  lateProjects: "late",
  scheduledProjects: "scheduled",
};

export const filterIdToKpi = {
  myProjects: "myProjects",
  draftProjects: "draftProjects",
  lateProjects: "lateProjects",
  futureProjects: "scheduledProjects",
  inProgressProjects: "inProgressProjects",
};

export const statusNames = {
  Undefined: "Undefined",
  Draft: "Draft",
  Scheduled: "Scheduled",
  InProgress: "InProgress",
  Completed: "Completed",
  Cancelled: "Cancelled",
  OnHold: "OnHold",
  Archived: "Archived",
};

export const progressCodesNames = {
  Ahead: "Ahead",
  OnTime: "OnTime",
  Behind: "Behind",
};

export const companyActivityType = {
  retailer: 1,
  supplier: 2,
  trader: 5,
  broker: 6,
  distributor: 7,
  wholesaler: 8,
  packer: 9,
  laboratory: 10,
  certificationBody: 11,
  accreditationBody: 12,
  standardAgency: 13,
  servicesCompany: 14,
  contentAgency: 15,
  other: 16,
  software: 17,
  translationAgency: 18,
  designAgency: 19,
  executionAgency: 20,
  photoengravingAgency: 21,
};

export const getStatusName = (status, intl) => {
  const statusNameInProjet = defineMessages({
    undefined: {
      id: "constants.projects.statusUndefined",
      defaultMessage: "-",
    },
    Draft: {
      id: "constants.projects.statusDraft",
      defaultMessage: "Draft",
    },
    Archived: {
      id: "constants.projects.statusArchived",
      defaultMessage: "Archived",
    },
    Scheduled: {
      id: "constants.projects.statusScheduled",
      defaultMessage: "Scheduled",
    },
    InProgress: {
      id: "constants.projects.statusInProgress",
      defaultMessage: "In Progress",
    },
    Completed: {
      id: "constants.projects.statusCompleted",
      defaultMessage: "Completed",
    },
    Cancelled: {
      id: "constants.projects.statusCancelled",
      defaultMessage: "Cancelled",
    },
    OnHold: {
      id: "constants.projects.statusOnHold",
      defaultMessage: "On Hold",
    },
    Future: {
      id: "constants.projects.future",
      defaultMessage: "Future",
    },
  });
  try {
    return intl.formatMessage(statusNameInProjet[status]);
  } catch (e) {
    return "";
  }
};

export const getProgressName = (status, intl) => {
  const progressTradColumn = defineMessages({
    onTime: {
      id: "constants.projects.onTime",
      defaultMessage: "On time",
    },
    ahead: {
      id: "constants.projects.ahead",
      defaultMessage: "Ahead",
    },
    behind: {
      id: "constants.projects.behind",
      defaultMessage: "Behind",
    },
  });
  try {
    return intl.formatMessage(progressTradColumn[status]);
  } catch (e) {
    return "";
  }
};

const statusStyles = {
  Undefined: "default",
  Draft: "grey",
  Archived: "grey",
  Scheduled: "purple",
  InProgress: "blue",
  OnHold: "orange",
  Completed: "green",
  Cancelled: "error",
  Future: "purple",
};

const progressStyles = {
  Undefined: "default",
  Behind: "red",
  OnTime: "orange",
  Ahead: "green",
};

export const getStatusStyle = status => {
  return statusStyles[status];
};

export const getProgressStyle = progressCode => {
  return progressStyles[progressCode];
};

const daysOrDay = (days, intl) => {
  const dayOrDays = defineMessages({
    day: {
      id: "constants.projects.day",
      defaultMessage: "day",
    },
    days: {
      id: "constants.projects.days",
      defaultMessage: "days",
    },
  });
  return days && days > 1
    ? intl.formatMessage(dayOrDays.days)
    : intl.formatMessage(dayOrDays.day);
};

const progressTradColumn = defineMessages({
  onTime: {
    id: "constants.projects.onTime",
    defaultMessage: "On time",
  },
  ahead: {
    id: "constants.projects.ahead",
    defaultMessage: "Ahead",
  },
  behind: {
    id: "constants.projects.behind",
    defaultMessage: "Behind",
  },
});

export const progressTypes = {
  ahead: "Ahead",
  onTime: "OnTime",
  behind: "Behind",
};

export const getProgress = (days, progressCode, intl) => {
  switch (progressCode) {
    case progressTypes.onTime:
      return `${intl.formatMessage(progressTradColumn.onTime)}`;
    case progressTypes.behind:
      return `${intl.formatMessage(
        progressTradColumn.behind
      )} (${days} ${daysOrDay(days, intl)})`;
    case progressTypes.ahead:
      return `${intl.formatMessage(
        progressTradColumn.ahead
      )} (${days} ${daysOrDay(days, intl)})`;
    default:
      return "-";
  }
};
