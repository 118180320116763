import React from "react";
import { Route } from "react-router-dom";
import { Errors } from "routes/Errors";
import PropTypes from "prop-types";

export const AuthorizedRoute = ({ isActive, ...rest }) => {
  if (!isActive) {
    return (
      <Route>
        <Errors hasCustomClickAction={true} propStatus={403} />
      </Route>
    );
  }
  return <Route {...rest} />;
};

AuthorizedRoute.propTypes = {
  isActive: PropTypes.bool,
};
