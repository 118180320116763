import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { uniqBy } from "lodash";
import {
  selectCategories,
  selectValidatedProducts,
} from "common/selectors/filteredProducts.selectors";
import {
  fetchCategoriesForValidatedProducts,
  removeValidatedProduct,
} from "redux/actions/FilteredProducts";
import PopupDeleteProduct from "../PopupDeleteProduct";
import TraceOneTable from "components/TraceOneTable";
import { columnTitle } from "routes/Projects/defineMessages";
import { useLanguagePreference } from "@trace-one/react-components";
import { cumdApi } from "../../../../../apis";
import useTableLocalPagination from "components/TraceOneTable/useTableLocalPagination";
import { Icon } from "@trace-one/design-system";
import styles from "./Table.module.less";

export const findCategoryById = (categories, dataItem) => {
  if (dataItem.category && dataItem.category.categoryItemId) {
    return categories.find(
      category => category.categoryItemId === dataItem.category.categoryItemId
    );
  }
};

export const enritchItem = (products, categories) =>
  products
    .filter(
      product =>
        !!product.isManufacturedItem ||
        !products.some(localProduct => product.id === localProduct.tradeItemId)
    )
    .map(dataItem => {
      const category = findCategoryById(categories, dataItem);
      return {
        ...dataItem,
        categoryName: (category && category.categoryItemName) || "",
      };
    });

const TableProducts = () => {
  const languageCode = useLanguagePreference();
  const [products, setProducts] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const data = useSelector(selectValidatedProducts);
  const categories = useSelector(selectCategories);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [displayPopupDeleteProduct, setDisplayPopupDeleteProduct] =
    useState(false);
  const [productIdToRemove, setProductIdToRemove] = useState(null);

  const isCompanyLoaded = companyId => {
    return suppliers.some(supplier => supplier.id === companyId);
  };

  useEffect(() => {
    const supplierCompanyIds = products.reduce((acc, item) => {
      if (item.supplierCompanyId && !isCompanyLoaded(item.supplierCompanyId)) {
        return [...acc, item.supplierCompanyId];
      }
      return acc;
    }, []);
    if (supplierCompanyIds.length > 0) {
      cumdApi
        .getCompaniesCollection({
          companyIds: supplierCompanyIds,
        })
        .then(({ data: companies }) => {
          setSuppliers(suppliersState => {
            const suppliers = companies.map(company => ({
              id: company.companyId,
              name: company.companyDisplayName,
            }));

            return uniqBy([...suppliersState, ...suppliers], "id");
          });
        });
    }
  }, [products]);

  useEffect(() => {
    fetchCategories();
  }, [data]);

  useEffect(() => {
    initTable();
  }, [categories]);

  const fetchCategories = async () => {
    setLoading(true);
    if (products && products.length < data.length) {
      await dispatch(
        fetchCategoriesForValidatedProducts({ languageCode, data })
      );
    } else {
      initTable();
    }
    setLoading(false);
  };

  const initTable = () => {
    setProducts(enritchItem(data, categories));
  };

  const columns = [
    {
      title: "GTIN",
      dataIndex: "gtin",
      key: "gtin",
      ellipsis: true,
      width: 100,
    },
    {
      title: formatMessage(columnTitle.titleProductName),
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
      width: 200,
    },
    {
      title: formatMessage(columnTitle.titleBrand),
      dataIndex: "brandName",
      key: "brandName",
      ellipsis: true,
      width: 200,
    },
    {
      title: formatMessage(columnTitle.titleCategory),
      dataIndex: "categoryName",
      key: "categoryName",
      ellipsis: true,
      width: 200,
    },
    {
      title: formatMessage(columnTitle.supplierName),
      dataIndex: "supplierCompanyName",
      key: "supplierCompanyName",
      ellipsis: true,
      width: 200,
      render: (data, rest) => {
        if (rest.supplierCompanyId == null) {
          return "-";
        }
        const supplier = suppliers.find(
          supplier => supplier.id === rest.supplierCompanyId
        );
        return supplier ? supplier.name : "-";
      },
    },
    {
      title: (
        <span className="table-header-icon-container">
          <Icon name="settings" color="white" />
        </span>
      ),
      dataIndex: "action",
      key: "id",
      render: (_, record) => (
        <Icon
          name="Close"
          style={{ cursor: "pointer" }}
          className={styles.cog}
          onClick={() => dispatch(removeValidatedProduct(record.id))}
        />
      ),
      width: 40,
    },
  ];

  const { paginationData, handleTableChange } = useTableLocalPagination({
    data: products,
  });

  return (
    <div>
      <p className={styles.numOfSelect}>
        {`${products.length} `}
        <FormattedMessage
          id="projects.creation.components.table.countProduct"
          defaultMessage="Existing product(s)"
        />
        {formatMessage(columnTitle.selected)}
      </p>
      <TraceOneTable
        noDataText={formatMessage(columnTitle.titleEmptyTable)}
        columns={columns}
        pagination={paginationData}
        dataSource={paginationData.dataSource}
        loading={isLoading}
        onChange={handleTableChange}
        languageCode={languageCode}
        scroll={{ x: 1500 }}
      />
      <PopupDeleteProduct
        isDisplay={displayPopupDeleteProduct}
        onClose={() => setDisplayPopupDeleteProduct(false)}
        onNoValidate={() => setDisplayPopupDeleteProduct(false)}
        onValidate={() => {
          dispatch(removeValidatedProduct(productIdToRemove));
          setProductIdToRemove(null);
          setDisplayPopupDeleteProduct(false);
        }}
      />
    </div>
  );
};

export default TableProducts;
