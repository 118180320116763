/* istanbul ignore file */
import { axiosInstance } from "./axiosInstance";
import { portalTypeId } from "constants/index";
import { isEmpty } from "lodash";

const refListsNeededCompanyId = ["ProductType"];

export default class RLMD extends axiosInstance {
  getItemsCollections = ({
    languageCode,
    portalTypeId,
    idCollection = [],
    companyId,
  }) => {
    if (isEmpty(idCollection)) {
      //check if idCollection is empty, because api not allow that
      return Promise.resolve({ data: [] });
    }

    return this.instance.post(
      "/api/referencelist/items/collections",
      {
        idCollection,
      },
      {
        params: {
          languageCode,
          portalTypeId,
          companyId,
        },
      }
    );
  };

  getCategoryitemsCollections = ({ languageCode, idCollection }) => {
    if (isEmpty(idCollection)) {
      //check if idCollection is empty, because api not allow that
      return Promise.resolve({ data: [] });
    }

    return this.instance.post(
      "/api/categoryitems/collections",
      {
        idCollection,
      },
      {
        params: {
          languageCode,
        },
      }
    );
  };

  fetchReferenceListItems = ({ languageCode, id, companyId }) =>
    this.instance.get(`/api/referencelist/${id}/items`, {
      params: {
        languageCode,
        portalTypeId: portalTypeId,
        companyId,
      },
    });

  getReferenceListByName = ({
    referenceListName,
    name,
    languageCode,
    parentItemId,
    companyId,
  }) => {
    if (
      refListsNeededCompanyId.includes(referenceListName) &&
      companyId == null
    ) {
      throw new Error(
        `A company Id is needed for the ref list ${referenceListName}`
      );
    }

    return this.instance.get(
      `/api/referencelist/name/${referenceListName || name}/items`,
      {
        params: {
          portalTypeId,
          languageCode,
          parentItemId,
          companyId,
        },
        useCache: true,
      }
    );
  };

  getCategorylists = ({ companyId }) =>
    this.instance.get("/api/categorylists", {
      params: { companyId },
      useCache: true,
    });

  getCategorylistsHierarchyById = ({ categoryId, languageCode }) =>
    this.instance.get(`/api/categorylists/${categoryId}/hierarchy`, {
      params: { languageCode },
      useCache: true,
    });

  getCategorylistsByDirectChildIds = ({ categoryId, languageCode }) =>
    this.instance.get(`/api/categorylists/${categoryId}/directChildIds`, {
      params: { languageCode },
      useCache: true,
    });

  getItemsById = ({ itemId, languageCode, countryCode, companyId, cemId }) =>
    this.instance.get(`/api/referencelist/items/${itemId}`, {
      params: { languageCode, portalTypeId, countryCode, companyId, cemId },
    });
}
