import {
  SET_STEPPER_DATA,
  SET_WARNING_FIELDS,
  RECEIVED_TYPES_FOR_SELECT,
  SET_SELECTED_TYPE,
} from "../../../constants";
import { projectApi } from "apis";

export const getProjectTypes = ({ companyId, languageCode }) => {
  return async dispatch => {
    if (!companyId) return null;
    try {
      const { data } = await projectApi.getProjectTypes({
        ownerCompanyId: companyId,
        languageCode,
        active: true,
      });
      dispatch(setTypesInSelect(data));
    } catch (error) {
      console.error(error);
    }
  };
};

export const registerStepperData = data => {
  return {
    type: SET_STEPPER_DATA,
    payload: data,
  };
};

export const setWarningFields = warnings => {
  return {
    type: SET_WARNING_FIELDS,
    payload: warnings,
  };
};

export const setTypesInSelect = types => {
  return {
    type: RECEIVED_TYPES_FOR_SELECT,
    payload: types,
  };
};

export const setSelectedType = type => {
  return {
    type: SET_SELECTED_TYPE,
    payload: type,
  };
};
