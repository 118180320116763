import { config } from "services/messages/config";

export const configOidc = {
  authority: config.IDSV_URL,
  client_id: "traceone.internal.project",
  redirect_uri: `${config.BASE_URL}/callback`,
  response_type: "code",
  scope: "openid t1_api t1_global_ids",
  post_logout_redirect_uri: `${config.BASE_URL}`,
  automaticSilentRenew: true,
  silent_redirect_uri: `${config.BASE_URL}/silent-refresh`,
  loadUserInfo: true,
};

export const permissionsType = {
  projectsWrite: "projects.write",
  projectTeamsRead: "projectTeams.read",
  projectTeamsWrite: "projectTeams.write",
  projectTeamsDelete: "projectTeams.delete",
  projectTypesWrite: "projectTypes.write",
  projectsPackagingSystemsWrite: "projects.packagingSystems.write",
  packagingSystemsWrite: "packagingSystems.write",
  packagingComponentsWrite: "packagingComponents.write",
  projectsPackagingSystemsRead: "projects.packagingSystems.read",
  packagingSystemsRead: "packagingSystems.read",
  packagingComponentsRead: "packagingComponents.read",
};

export const getPermissions = ({
  permissions,
  global_user_is_admin,
  isMasquerade = false,
}) => {
  const arrayPermissions = permissions?.split(",");

  const isAdmin =
    global_user_is_admin?.toString().toLowerCase() === "true" || false;

  const projectsWrite =
    arrayPermissions?.includes(permissionsType.projectsWrite) || false;

  const projectTeamsRead =
    arrayPermissions?.includes(permissionsType.projectTeamsRead) || false;

  const projectTeamsWrite =
    arrayPermissions?.includes(permissionsType.projectTeamsWrite) || false;

  const projectTypesWrite =
    arrayPermissions?.includes(permissionsType.projectTypesWrite) || false;

  const projectTeamsDelete =
    arrayPermissions?.includes(permissionsType.projectTeamsDelete) || false;

  const projectsPackagingSystemsWrite =
    arrayPermissions?.includes(permissionsType.projectsPackagingSystemsWrite) ||
    false;

  const packagingSystemsWrite =
    arrayPermissions?.includes(permissionsType.packagingSystemsWrite) || false;

  const packagingComponentsWrite =
    arrayPermissions?.includes(permissionsType.packagingComponentsWrite) ||
    false;

  const projectsPackagingSystemsRead =
    arrayPermissions?.includes(permissionsType.projectsPackagingSystemsRead) ||
    false;

  const packagingSystemsRead =
    arrayPermissions?.includes(permissionsType.packagingSystemsRead) || false;

  const packagingComponentsRead =
    arrayPermissions?.includes(permissionsType.packagingComponentsRead) ||
    false;

  return {
    isAdmin,
    projectsWrite,
    projectTeamsRead,
    projectTeamsWrite,
    projectTeamsDelete,
    projectTypesWrite,
    projectsPackagingSystemsWrite,
    packagingSystemsWrite,
    packagingComponentsWrite,
    projectsPackagingSystemsRead,
    packagingSystemsRead,
    packagingComponentsRead,
    isMasquerade,
  };
};
