/* istanbul ignore file */
import { axiosInstance } from "./axiosInstance";

const WEB_NOTIF_PREFIX = "/api/webNotifications";
const OCC_USER_INSTANCE_PREFIX = "/api/occurrenceUserInstances";

export default class NOTIFICATIONS extends axiosInstance {
  getNotifications = ({
    NotificationEventUtcDateTimeAfter,
    SearchText,
    SenderZone,
    LanguageCode,
    Take,
    Skip,
  }) =>
    this.instance.get(WEB_NOTIF_PREFIX, {
      params: {
        NotificationEventUtcDateTimeAfter,
        SearchText,
        SenderZone,
        LanguageCode,
        Take,
        Skip,
      },
    });

  getCount = (WithDetailsPerSender = true) =>
    this.instance.get(`${WEB_NOTIF_PREFIX}/countUnread`, {
      params: { WithDetailsPerSender },
    });

  deleteNotifications = occurrenceUserInstanceIds =>
    this.instance.delete(`${OCC_USER_INSTANCE_PREFIX}/list`, {
      data: {
        occurrenceUserInstanceIds,
      },
    });

  setReadStatus = (occurrenceUserInstanceIds, newStatus) =>
    this.instance.patch(`${OCC_USER_INSTANCE_PREFIX}/list/updateStatus`, {
      occurrenceUserInstanceIds,
      newStatus,
    });

  getNotificationPreferences = ({ languageCode, senderZone, level }) =>
    this.instance.get("/api/notificationPreferences", {
      params: {
        languageCode,
        senderZone,
        level,
      },
    });

  postNotificationPreferences = ({ notificationPreferences }) =>
    this.instance.post("/api/notificationPreferences", {
      notificationPreferences,
    });

  getNotificationIds = ({
    languageCode,
    senderZone,
    searchText,
    NotificationEventUtcDateTimeAfter,
  }) =>
    this.instance.get(`${WEB_NOTIF_PREFIX}/ids`, {
      params: {
        languageCode,
        senderZone,
        searchText,
        NotificationEventUtcDateTimeAfter,
      },
    });
}
