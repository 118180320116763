/* istanbul ignore file */
import { axiosInstance } from "./axiosInstance";

export default class DOCUMENT extends axiosInstance {
  uploadFile = ({ file, docType, ownerCompanyId }) =>
    this.instance.post(`/api/v2/files/upload`, file, {
      useSilentError: true,
      params: { fileType: docType, ownerCompanyId },
    });

  downloadFile = ({ fileId, ...rest }) =>
    this.instance.get(`/api/v2/files/${fileId}/download`, {
      responseType: "blob",
      params: { ...rest },
    });

  getDocApiConfig = () => this.instance.get(`/api/configuration`);

  getfileDetail = ({ fileId, languageCode }) =>
    this.instance.get(`/api/v2/files/${fileId}/detail`, {
      languageCode,
    });

  getFilesCollection = fileIds => {
    let _fileIds = fileIds.filter(fileId => !!fileId);
    if (fileIds?.length === 0) {
      //check if fileIds is empty, because api not allow that
      return Promise.resolve({ data: [] });
    }
    return this.instance.post(`/api/v2/files/collection`, {
      fileIds: _fileIds,
    });
  };

  getFileDetailById = fileId =>
    this.instance.get(`/api/v2/files/${fileId}/detail`);

  getFileDetailByIdAndVersionId = ({ fileId, versionId }) =>
    this.instance.get(`/api/v2/files/${fileId}`, {
      params: {
        versionId,
      },
    });

  uploadNewVersion = ({ fileId, file, docType }) =>
    this.instance.post(`/api/v2/files/${fileId}/uploadNewVersion`, file, {
      useSilentError: true,
      params: { fileType: docType },
    });

  lockFile = ({ fileId }) => this.instance.put(`/api/v2/files/${fileId}/lock`);

  metaDataAddTofile = ({ fileId, metaData }) =>
    this.instance.put(`/api/v2/files/${fileId}/MetaData`, {
      createdOrUpdatedMetaData: {
        ...metaData,
      },
    });

  getDMSfileTypes = ({ languageCode }) =>
    this.instance.get(`/api/FileTypes`, { languageCode });

  deleteFileVersionByVersionId = ({ fileId, versionId }) =>
    this.instance.delete(`/api/v2/files/${fileId}/versions/${versionId}`);

  deleteFileWithAllVersions = ({ fileId }) =>
    this.instance.delete(`api/v2/files/${fileId}`);
}
