import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactModal from "react-modal";
import { AuthenticationProvider, OidcSecure } from "@axa-fr/react-oidc-context";
import { LoadingPage } from "@trace-one/react-components";
import { Store, persistor } from "./redux";
import App from "./routes";
import * as serviceWorker from "./serviceWorker";
import "styles/_app.less";
import "./index.less";
import Wrapper from "./components/Wrapper/Wrapper";
import { configOidc } from "services/messages/auth.js";
import { PersistGate } from "redux-persist/integration/react";

ReactModal.setAppElement("#root");

const renderLoadingPage = title => () =>
  (
    <LoadingPage
      title={title}
      subtitle={"Please wait, your page will be displayed"}
    />
  );

ReactDOM.render(
  <Provider store={Store}>
    <AuthenticationProvider
      configuration={configOidc}
      authenticating={renderLoadingPage("LOADING...")}
      callbackComponentOverride={renderLoadingPage("LOADING...")}
    >
      <OidcSecure>
        <PersistGate loading={null} persistor={persistor}>
          <Wrapper>
            <App />
          </Wrapper>
        </PersistGate>
      </OidcSecure>
    </AuthenticationProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
