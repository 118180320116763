import { defineMessages } from "react-intl";

export const messages = defineMessages({
  errorUpload: {
    id: "projects.projectFolder.componentDesign.errorUpload",
    defaultMessage: "Please provide a supported file type",
  },
  errorOversizeFile: {
    id: "projects.projectFolder.documents.errorOversizeFile",
    defaultMessage: "The file's size exceeds the limit of 600 MB",
  },
});
