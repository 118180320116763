import { SET_ERRORS_EVENTS, RESET_ERRORS_EVENTS } from "../../../constants";

export const setErrorEvents = payload => {
  return {
    type: SET_ERRORS_EVENTS,
    payload,
  };
};

export const resetErrorsEvents = () => ({ type: RESET_ERRORS_EVENTS });
