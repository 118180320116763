export const selectUserId = ({ user: { userId } }) => userId;
export const selectUserIsLoading = ({ user: { isLoading } }) => isLoading;
export const selectUserLogin = ({ user: { userLogin } }) => userLogin;
export const selectUserName = ({ user: { userFirstName, userLastName } }) =>
  `${userFirstName} ${userLastName}`;
export const selectUserPhotoUrl = ({ user: { userPhotoUrl } }) => userPhotoUrl;
export const selectUserJobTitle = ({ user: { userJobTitle } }) => userJobTitle;
export const selectUserCompanyName = ({ user: { userCompanyName } }) =>
  userCompanyName;
export const selectOwningCompanyId = ({ user: { owningCompanyId } }) =>
  owningCompanyId;
export const selectUserApplications = ({ user: { applications } }) =>
  applications;
export const selectHasUserAccessToApplication = ({
  user: { applications },
}) => {
  return (
    applications.findIndex(
      ({ applicationTypeId }) => applicationTypeId === 50
    ) !== -1
  );
};
export const selectTonApplicationUrl = ({ user: { applications } }) => {
  const tonApplicationIndex = applications.findIndex(
    ({ applicationTypeId }) => applicationTypeId === 40
  );
  if (tonApplicationIndex !== -1) {
    const tonApp = applications[tonApplicationIndex];
    return tonApp.getEndpointUrl;
  } else {
    return "";
  }
};
export const selectCompanyLogoUrl = ({ user: { companyLogoUrl } }) =>
  companyLogoUrl;
export const selectUserLanguagePreference = ({
  user: { userLanguagePreference },
}) => userLanguagePreference;
export const selectPermissions = ({ user: { permissions } }) => permissions;
export const selectCompanyActivityId = ({ user: { companyActivityId } }) =>
  companyActivityId;
export const selectUserCompanyActivityName = ({
  user: { userCompanyActivityName },
}) => userCompanyActivityName;
