import { selectData } from "common/selectors/projects.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCreationPopUp } from "redux/actions/ProjectCreation";

type ReturnTypes = {
  handleCreationPopUp: ({ ...props }: any) => boolean;
};

const useTrigerCreationPopUp = (): ReturnTypes => {
  const projectData = useSelector(selectData);
  const dispatch = useDispatch();
  const history = useHistory();

  const isPermittedRoute = ({ pathname }) => {
    const isDisable =
      !projectData.projectName &&
      !projectData.projectTypeId &&
      !projectData.description;

    const permittedRoutes = [
      "/projects/creation/definition",
      "/projects/creation/products",
      "/projects/creation/products/products-selection",
      "/projects/creation/products/products-creation",
      "/projects/creation/planning",
      "/projects/creation/team",
      "/projects/creation/summary",
      "/projects/creation/team/select-team",
    ];

    return permittedRoutes.includes(pathname) && !isDisable;
  };

  const handleCreationPopUp = ({ ...props }: any): boolean => {
    if (isPermittedRoute(history.location)) {
      history.push(history.location, {
        ...props,
      });
      dispatch(setCreationPopUp(true));
      return true;
    }

    return false;
  };

  return { handleCreationPopUp };
};

export default useTrigerCreationPopUp;
