import React, { useState } from "react";
import PropTypes from "prop-types";
import { Label, Spinner } from "@trace-one/react-components";
import SelectSearch from "components/Forms/SelectSearch";
import styles from "./TeamsRetail.module.less";
import { useIntl } from "react-intl";
import { teamsRetailPlaceholder } from "routes/Projects/defineMessages";
import useGetUsersOnSearch from "../../hooks/useGetUsersOnSearch";

export const TeamsRetail = ({ responsibilities, onChange, isDisabled }) => {
  const { formatMessage } = useIntl();
  const [responsibility, setResponsibility] = useState();
  const { isLoading, newUsers, setSearchValue } =
    useGetUsersOnSearch(responsibility);

  const onResponsibilityChange = async (value, id) => {
    const enritchedResponsibilities = responsibilities.map(responsibility => {
      if (responsibility.id === id) {
        if (value) {
          return { ...responsibility, userIdSelected: value };
        } else {
          const { userIdSelected, ...newResponsibility } = responsibility;
          return newResponsibility;
        }
      }
      return responsibility;
    });
    onChange(enritchedResponsibilities);
  };

  const onUserSearch = (val, id) => {
    setSearchValue(val);
    setResponsibility(id);
  };

  return (
    <>
      {responsibilities?.map(({ id, name, userIdSelected, users }) => (
        <div key={id} className={styles.wrapper}>
          <Label title={name} mode={"horizontal"}>
            <SelectSearch
              data-test-id="teamRetailerResponsibility"
              selectid={id}
              disabled={isDisabled}
              value={userIdSelected}
              onChange={onResponsibilityChange}
              onClear={onResponsibilityChange}
              optionData={
                newUsers.length > 0
                  ? newUsers?.filter(user =>
                      user?.responsibilities.includes(id)
                    )
                  : users
              }
              placeHolder={formatMessage(teamsRetailPlaceholder.placeholder, {
                name: name,
              })}
              style={{ width: 400 }}
              onSearch={val => onUserSearch(val, id)}
              notFoundContent={
                isLoading ? <Spinner center size="small" /> : "Not Found"
              }
              filterOption={false}
              onDropdownVisibleChange={() => onUserSearch("", "")}
            />
          </Label>
        </div>
      ))}
    </>
  );
};

TeamsRetail.propTypes = {
  responsibilities: PropTypes.array,
  onChange: PropTypes.func,
};

TeamsRetail.defaultProps = {
  isDisabled: false,
};
