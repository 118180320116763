import { defineMessages } from "react-intl";
import English from "translations/en-US.json";
import French from "translations/fr-FR.json";

export const navConfig = (intl, permissions) => {
  const { projectsWrite } = permissions;
  const navTranslate = defineMessages({
    home: {
      id: "services.messages.utils.navHome",
      defaultMessage: "Home",
    },
    projects: {
      id: "services.messages.utils.navProjects",
      defaultMessage: "Projects",
    },
    tasks: {
      id: "services.messages.utils.navTasks",
      defaultMessage: "Tasks",
    },
    projectCreation: {
      id: "services.messages.utils.projectCreation",
      defaultMessage: "Project Creation",
    },
    projectList: {
      id: "services.messages.utils.projectList",
      defaultMessage: "Projects List",
    },
    taskList: {
      id: "services.messages.utils.taskList",
      defaultMessage: "Tasks List",
    },
    calendar: {
      id: "services.messages.utils.calendar",
      defaultMessage: "Calendar",
    },
  });
  const navConfig = [
    {
      text: intl.formatMessage(navTranslate.home),
      path: "/home",
      subNav: [],
    },
    {
      text: intl.formatMessage(navTranslate.projects),
      path: "/projects",
      subNav: [
        {
          isVisible: projectsWrite,
          text: intl.formatMessage(navTranslate.projectCreation),
          path: "/projects/creation/definition",
          subNav2: [],
        },
        {
          text: intl.formatMessage(navTranslate.projectList),
          path: "/projects/projects-list",
          subNav2: [],
        },
      ],
    },
    {
      text: intl.formatMessage(navTranslate.tasks),
      path: "/tasks",
      subNav: [
        {
          text: intl.formatMessage(navTranslate.taskList),
          path: "/tasks/tasks-list",
          subNav2: [],
        },
        {
          text: intl.formatMessage(navTranslate.calendar),
          path: "/tasks/calendar",
          subNav2: [],
        },
      ],
    },
  ];
  const navConfigFiltered = navConfig
    .filter(cur => cur.isVisible !== false)
    .map(cur => {
      const newSubNav = cur.subNav.filter(cur => cur.isVisible !== false);
      cur.subNav = newSubNav;
      return cur;
    });

  return navConfigFiltered;
};

export const adminNavConfig = (intl, permissions) => {
  const { projectTeamsWrite, projectTeamsRead, projectTypesWrite } =
    permissions;
  const adminNavTranslate = defineMessages({
    workflowManag: {
      id: "services.messages.utils.workflowManag",
      defaultMessage: "Workflow Management",
    },
    userManag: {
      id: "services.messages.utils.userManag",
      defaultMessage: "Team Management",
    },
  });
  const adminNavConfig = [
    {
      isVisible: projectTypesWrite,
      text: intl.formatMessage(adminNavTranslate.workflowManag),
      path: "/admin/workflow-management/management",
      subNav2: [],
    },
    {
      isVisible: projectTeamsRead || projectTeamsWrite,
      text: intl.formatMessage(adminNavTranslate.userManag),
      path: "/admin/user-management/teams",
      subNav2: [],
    },
  ];
  const adminNavConfigFiltered = adminNavConfig
    .filter(cur => cur.isVisible !== false)
    .map(cur => {
      const newSubNav = cur.subNav2.filter(cur => cur.isVisible !== false);
      cur.subNav2 = newSubNav;
      return cur;
    });

  return adminNavConfigFiltered;
};

export const applicationLanguages = {
  "en-US": English,
  "fr-FR": French,
};

/**
 *
 * @param isManufacturedItem value available only in the items which are coming from the select product modal or from the backend (not from the create product modal)
 * @param tradeItemId value which is available only on the manufactured items after processing the create product modal values.
 * @returns true if the item is a manufactured item and false if it's not
 */
const isManufacturedItem = ({ isManufacturedItem, tradeItemId }) => {
  return isManufacturedItem || tradeItemId != null;
};

export const getProductIds = products => {
  const [manufacturedItems, tradeItems] = products.reduce(
    ([manufIds, tradeIds], item) => {
      const { id } = item;
      if (isManufacturedItem(item)) {
        manufIds = [...manufIds, ...[id]];
      } else {
        tradeIds = [...tradeIds, ...[id]];
      }

      return [manufIds, tradeIds];
    },
    [[], []]
  );

  return [manufacturedItems, tradeItems];
};
