import React from "react";
import { ErrorPage } from "@trace-one/react-components";
import { useIntl } from "react-intl";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useParams } from "react-router-dom";
import styles from "./Errors.module.less";
import propTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectTonApplicationUrl } from "common/selectors/users.selectors";

export const Errors = ({ propStatus, hasCustomClickAction }) => {
  const { status } = useParams();
  const { logout } = useReactOidc();
  const tonApplicationUrl = useSelector(selectTonApplicationUrl);
  let finalStatus;
  const intl = useIntl();
  if (propStatus) {
    finalStatus = propStatus;
  } else if (status) {
    finalStatus = parseInt(status);
  } else {
    finalStatus = 404;
  }

  if (finalStatus === 401) {
    logout();
  } else {
    return (
      <div className={styles.errorContainer}>
        <ErrorPage
          hasCustomClickAction={hasCustomClickAction}
          onButtonPress={() => {
            window.location.href = tonApplicationUrl;
          }}
          status={finalStatus}
          languageCode={intl.locale}
        />
      </div>
    );
  }
};

Errors.defaultProps = {
  hasCustomClickAction: false,
};

Errors.propTypes = {
  propStatus: propTypes.number,
  hasCustomClickAction: propTypes.bool,
};
