import { stepperName, warningTranslated } from "routes/Projects/defineMessages";
import { CREATION_STEP } from "utils/ProjectUtils";
import { isToday } from "./components/Buttons/helpers";

export const isValid = ({ step, projectData }) => {
  switch (step) {
    case CREATION_STEP.definition:
    case CREATION_STEP.products:
      return (
        projectData && !!projectData.projectName && !!projectData.projectTypeId
      );
    case CREATION_STEP.planning:
      return (
        projectData &&
        !!projectData.projectName &&
        !!projectData.projectTypeId &&
        (!!projectData.startDate || projectData.selectedProducts.length === 0)
      );
    case CREATION_STEP.team:
      return (
        projectData &&
        !!projectData.projectName &&
        !!projectData.projectTypeId &&
        (!!projectData.startDate ||
          projectData.selectedProducts.length === 0) &&
        !!projectData.responsibilities
      );
    case CREATION_STEP.summary:
      return (
        projectData &&
        !!projectData.projectName &&
        !!projectData.projectTypeId &&
        (!!projectData.startDate ||
          projectData.selectedProducts.length === 0) &&
        !!projectData.responsibilities
      );
    default:
      return false;
  }
};

export const canAccess = ({ step, projectData }) => {
  switch (step) {
    case CREATION_STEP.definition:
      return true;
    case CREATION_STEP.products:
    case CREATION_STEP.planning:
      return isValid({ step: CREATION_STEP.definition, projectData });
    case CREATION_STEP.team:
      return isValid({ step: CREATION_STEP.planning, projectData });
    case CREATION_STEP.summary:
      return isValid({ step: CREATION_STEP.team, projectData });
    default:
      return false;
  }
};

const isPlanningChanged = (projectData, taskClone) => {
  const isStartDateToday = isToday(projectData.startDate);
  const isTaskChanged =
    JSON.stringify(projectData.tasks) === JSON.stringify(taskClone.current);

  return isStartDateToday && isTaskChanged;
};

const isTeamChanged = (projectData, teamClone) => {
  const isTeamChanged =
    JSON.stringify(projectData.responsibilities) ===
    JSON.stringify(teamClone.current);

  return isTeamChanged;
};

export const canSkip = ({
  step,
  projectData,
  taskClone,
  isModified,
  teamClone,
}) => {
  switch (step) {
    case CREATION_STEP.definition:
      return isModified;
    case CREATION_STEP.products:
      return isModified;
    case CREATION_STEP.planning:
      let correctEvaluationPlanning;
      if (isModified) {
        if (projectData.selectedProducts.length > 0) {
          correctEvaluationPlanning = isModified;
        } else {
          correctEvaluationPlanning = !isPlanningChanged(
            projectData,
            taskClone
          );
        }
      } else {
        correctEvaluationPlanning = isModified;
      }
      return correctEvaluationPlanning;
    case CREATION_STEP.team:
      let correctEvaluationTeam;
      if (isModified) {
        correctEvaluationTeam = !isTeamChanged(projectData, teamClone);
      } else correctEvaluationTeam = isModified;
      return correctEvaluationTeam;
    default:
      return false;
  }
};

export const isCurrentLocation = (history, url) => {
  return history.location.pathname.replace(/\/$/, "") === url;
};

export const updateStepper = ({ history, projectData, intl }) => {
  return [
    {
      key: CREATION_STEP.definition,
      title: intl.formatMessage(stepperName.definition),
      isCompleted: isValid({ step: CREATION_STEP.definition, projectData }),
      link: "/projects/creation/definition",
      active: isCurrentLocation(history, "/projects/creation/definition"),
      canAccess: true,
    },
    {
      key: CREATION_STEP.products,
      title: intl.formatMessage(stepperName.products),
      isCompleted: isValid({ step: CREATION_STEP.products, projectData }),
      link: "/projects/creation/products",
      active: isCurrentLocation(history, "/projects/creation/products"),
      canAccess: true,
    },
    {
      key: CREATION_STEP.planning,
      title: intl.formatMessage(stepperName.planning),
      isCompleted: isValid({ step: CREATION_STEP.planning, projectData }),
      link: "/projects/creation/planning",
      active: isCurrentLocation(history, "/projects/creation/planning"),
      canAccess: canAccess({ step: CREATION_STEP.planning, projectData }),
    },
    {
      key: CREATION_STEP.team,
      title: intl.formatMessage(stepperName.team),
      isCompleted: isValid({ step: CREATION_STEP.team, projectData }),
      link: "/projects/creation/team",
      active: isCurrentLocation(history, "/projects/creation/team"),
      canAccess: canAccess({ step: CREATION_STEP.team, projectData }),
    },
    {
      key: CREATION_STEP.summary,
      title: intl.formatMessage(stepperName.summary),
      isCompleted: isValid({ step: CREATION_STEP.summary, projectData }),
      link: "/projects/creation/summary",
      active: isCurrentLocation(history, "/projects/creation/summary"),
      canAccess: canAccess({ step: CREATION_STEP.summary, projectData }),
    },
  ];
};

export const getWarnFields = (item, data, intl) => {
  const fields = [];
  switch (item) {
    case CREATION_STEP.definition:
      [
        {
          name: "projectName",
          message: intl.formatMessage(warningTranslated.warnName),
        },
        {
          name: "projectTypeId",
          message: intl.formatMessage(warningTranslated.warnType),
        },
      ].forEach(field => {
        if (!data[field.name] || data[field.name] === "") {
          fields.push(field);
        }
      });
      break;
    case CREATION_STEP.planning:
      [
        {
          name: "startDate",
          message: intl.formatMessage(warningTranslated.warnDate),
        },
      ].forEach(field => {
        if (!data[field.name] || data[field.name] === "") {
          fields.push(field);
        }
      });
      break;
    case "projectFolder":
      [
        {
          name: "projectName",
          message: intl.formatMessage(warningTranslated.warnProjectFolderName),
        },
        {
          name: "projectTypeId",
          message: intl.formatMessage(warningTranslated.warnProjectFolderType),
        },
        {
          name: "startDate",
          message: intl.formatMessage(warningTranslated.warnProjectFolderDate),
        },
      ].forEach(field => {
        if (!data[field.name] || data[field.name] === "") {
          fields.push(field);
        }
      });
      break;
    default:
      break;
  }
  return fields;
};
