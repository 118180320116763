import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@trace-one/react-components";
import { projectDeleteProduct } from "routes/Projects/defineMessages";
import styles from "./PopupDeleteProduct.module.less";

const crossFolder = require("./folder-cross.svg");
const closeCross = require("./close-cross.svg");

const PopupDeleteProduct = ({
  onClose,
  onNoValidate,
  onValidate,
  isDisplay = false,
}) => {
  const { formatMessage } = useIntl();
  const { title, text1, text2, question, cancel, confirm } =
    projectDeleteProduct;
  if (!isDisplay) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <span
          className={styles.closePop}
          onClick={onClose}
          data-test-id="onClose"
        >
          <img src={closeCross} alt="" />
        </span>
        <span className={styles.folder}>
          <img src={crossFolder} alt="" />
        </span>
        <h1 className={styles.title}>{formatMessage(title)}</h1>
        <p className={styles.text}>{formatMessage(text1)}</p>
        <p className={styles.text}>{formatMessage(text2)}</p>
        <p className={styles.question}>{formatMessage(question)}</p>
        <div className={styles.btnWrapper}>
          <Button onClick={onValidate} data-test-id="onValidate">
            {formatMessage(confirm)}
          </Button>
          <Button
            data-test-id="onNoValidate"
            className={styles.btnBottom}
            onClick={onNoValidate}
          >
            {formatMessage(cancel)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PopupDeleteProduct;
