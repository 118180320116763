import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import styles from "./Stepper.module.less";
import { Link } from "react-router-dom";
import { selectStepperData } from "common/selectors/projects.selectors";

const Stepper = ({ saveAsDraft }) => {
  const data = useSelector(selectStepperData);

  return (
    <ul className={styles.wrapper}>
      {data.map((item, i) => {
        return (
          <li className={styles.item} key={item.title}>
            <span
              className={classNames(styles.dotCircle, {
                [styles.active]: item.active,
                [styles.completed]: item.isCompleted,
                [styles.canAccess]: item.canAccess,
              })}
            >
              {i + 1}
            </span>
            {i < data.length - 1 && (
              <span
                className={classNames(styles.sep, {
                  [styles.active]: item.active,
                  [styles.completed]: item.isCompleted,
                })}
              ></span>
            )}
            {item.canAccess && !item.active ? (
              <Link
                to={item.link}
                onClick={saveAsDraft}
                className={classNames(styles.title, {
                  [styles.active]: item.active,
                  [styles.completed]: item.isCompleted,
                  [styles.canAccess]: item.canAccess,
                })}
              >
                {item.title}
              </Link>
            ) : (
              <span
                className={classNames(styles.title, {
                  [styles.active]: item.active,
                  [styles.completed]: item.isCompleted,
                  [styles.canAccess]: item.canAccess,
                })}
              >
                {item.title}
              </span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Stepper;
