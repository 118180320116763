import { projectApi } from "apis";
import { associatePackagingProps } from "./types";
import { useHistory, useParams } from "react-router-dom";

const useAssociatePackaging = ({
  taskId,
  projectData,
  selectedProducts,
  setSilentRefresh,
}: associatePackagingProps) => {
  const history = useHistory();
  const { id: projectId } = useParams<any>();

  const associatePackaging = async packagingSystemIdArray => {
    const psIdSelected = packagingSystemIdArray[0];
    const packagingSystemIds = [
      ...projectData.packagingSystems.map(({ id }) => id),
      psIdSelected,
    ];
    try {
      await projectApi.patchPackagingSystemToProject(
        projectData.id,
        packagingSystemIds.reduce(
          (acc, cur) => (!acc.some(item => item === acc) ? [...acc, cur] : acc),
          []
        )
      );

      const psprMapOrigin = new Map(
        projectData.packagingSystemToProductRelations.map(
          ({ packagingSystemId, manufacturedItemId }) => [
            manufacturedItemId,
            packagingSystemId,
          ]
        )
      );

      const psprMapNew = new Map(
        selectedProducts?.map(idProduct => [idProduct, psIdSelected])
      );

      const psprMerged = new Map([...psprMapOrigin, ...psprMapNew]);

      const packagingSystemToProductRelations = Array.from(
        psprMerged,
        ([key, value]) => ({
          packagingSystemId: value,
          manufacturedItemId: key,
        })
      );

      await projectApi
        .patchPackagingSystemToProduct(
          projectData.id,
          packagingSystemToProductRelations
        )
        .then(() => {
          history.push(`/projects/projectfolder/${projectId}/products`);
        });

      if (setSilentRefresh) setSilentRefresh(true);
    } catch (error) {
      console.error(error);
    }
  };

  const temporaryAssociatePackaging = async packagingSystemIdArray => {
    if (packagingSystemIdArray) {
      const psIdSelected = packagingSystemIdArray[0];
      try {
        const psprMapNew = new Map(
          selectedProducts?.map(idProduct => [idProduct, psIdSelected])
        );
        const packagingSystemToProductRelations = Array.from(
          psprMapNew,
          ([key, value]) => ({
            packagingSystemId: value,
          })
        );

        const auxiliaryData = packagingSystemToProductRelations[0];

        await projectApi.saveTaskFormByIdWithPackageSystem({
          taskId: taskId,
          templateBlockId: projectData.templateBlockId,
          dataAsJson: projectData.dataAsJson,
          auxiliaryData,
        });
        if (setSilentRefresh) setSilentRefresh(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  return { associatePackaging, temporaryAssociatePackaging };
};

export default useAssociatePackaging;
