import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeams, fetchTeamSuppliers } from "redux/actions/ProjectCreation";
import {
  selectIsTeamsLoading,
  selectProjectTypeId,
  selectTeamResponsibilities,
  selectSelectedTeam,
} from "common/selectors/projects.selectors";
import { registerData } from "redux/actions/ProjectCreation";
import { selectOwningCompanyId } from "common/selectors/users.selectors";
import { selectValidatedProducts } from "common/selectors/filteredProducts.selectors";
import { RetailParticipants } from "./components/RetailParticipants";
/* import { SuppliersSection } from "./components/SuppliersSection"; */
import { useLanguagePreference, Spinner } from "@trace-one/react-components";
import styles from "./Team.module.less";

export const Team = () => {
  const responsibilities = useSelector(selectTeamResponsibilities);
  const isLoading = useSelector(selectIsTeamsLoading);
  const languageCode = useLanguagePreference();
  const projectTypeId = useSelector(selectProjectTypeId);
  const selectedProducts = useSelector(selectValidatedProducts);
  const companyId = useSelector(selectOwningCompanyId);
  const dispatch = useDispatch();
  const selectedTeam = useSelector(selectSelectedTeam);

  useEffect(() => {
    dispatch(registerData({ responsibilities }));
  }, [responsibilities]);

  useEffect(() => {
    Promise.resolve(
      dispatch(
        fetchTeams({ projectTypeId, languageCode, companyId, selectedTeam })
      )
    ).then(() => {
      const manufacturedItems = selectedProducts.filter(
        p => p.isManufacturedItem
      );
      dispatch(fetchTeamSuppliers(manufacturedItems));
    });
  }, [languageCode]);

  if (isLoading)
    return (
      <div className={styles.teamSpinner}>
        <Spinner center size="large" />
      </div>
    );
  return (
    <>
      <RetailParticipants />
      {/*
       * This section is temporarily removed
       * 92210 - Supplier user receives 403 error when saving third party company on Team tab
       * <SuppliersSection />
       */}
    </>
  );
};
