import { UNACCEPTED_FILE_TYPES } from "../constants/index";

export const getParsedData = data => {
  let result = {};

  try {
    result = JSON.parse(data);
  } catch (_) {}

  return result;
};

export const sortLatestByLockedStatus = (arr, key) => {
  const filterByLockedStatus = arr.filter(({ status }) => status === "Locked");
  const latestLockedFiles = filterByLockedStatus.sort(
    (a, b) => new Date(b[key]).getTime() - new Date(a[key]).getTime()
  );

  return latestLockedFiles;
};

export const uniqueArray = (arr, uniqueBy) => {
  const uniqueIds = [];

  const unique = arr.filter(element => {
    const isDuplicate = uniqueIds.includes(element[uniqueBy]);
    if (!isDuplicate) {
      uniqueIds.push(element[uniqueBy]);
      return true;
    }
    return false;
  });

  return unique;
};

export const isFileTypeAllowed = fileName => {
  const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();

  return UNACCEPTED_FILE_TYPES.indexOf(fileExtension) < 0;
};

export const shouldPaginationBeDisplay = totalItemsNumber =>
  totalItemsNumber > 10;

export const convertDateFormat = dateString => {
  const dateObj = new Date(dateString);
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const getPaginatedData = (productData, pagination) => {
  const startIndex = (pagination.current - 1) * pagination.pageSize;
  const endIndex = startIndex + pagination.pageSize;
  const paginatedData = productData.slice(startIndex, endIndex);
  return paginatedData;
};
