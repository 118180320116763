/* istanbul ignore file */
import { axiosInstance } from "./axiosInstance";
import { isEmpty } from "lodash";

export default class PRODUCTMD extends axiosInstance {
  getTradeItemsCollections = ({ idCollection, portalTypeId }) => {
    if (isEmpty(idCollection)) {
      return Promise.resolve({ data: [] });
    }

    return this.instance.post("/api/tradeitems/collections", {
      idCollection,
      portalTypeId,
    });
  };
  getManufacturedItemsCollections = ({ ownerCompanyId, idCollection }) => {
    if (isEmpty(idCollection)) {
      return Promise.resolve({ data: [] });
    }

    return this.instance.post(
      "/api/manufactureditems/collections",
      { idCollection },
      {
        params: {
          ownerCompanyId,
        },
      }
    );
  };
  createTradeItem = ({ ...tradeItem }) => {
    const data = tradeItem.body ? tradeItem.body : tradeItem;
    const { status: tradeItemStatusId, ...rest } = data;
    const params = { tradeItemStatusId, ...rest };
    return this.instance.post("/api/tradeItem", { ...params });
  };

  createManufacturedItem = ({ id, body }) =>
    this.instance.post(`/api/tradeitem/${id}/manufacturedItem`, {
      ...body,
    });

  getBrands = ({ ownerCompanyId, includeInactive, languageCode }) =>
    this.instance.get("/api/brands", {
      params: { ownerCompanyId, includeInactive, languageCode },
    });

  getTradeItemsById = ({ tradeItemId }) =>
    this.instance.get(`/api/tradeitems/${tradeItemId}`);

  getTradeItems = ({
    offset: take,
    tradeItemStatuses,
    searchText,
    ...filter
  }) =>
    this.instance.post(
      "/api/tradeitems/filters",
      {
        tradeItemStatuses,
        searchText,
      },
      {
        params: {
          take,
          ...filter,
        },
      }
    );

  getManufacturedItems = ({
    offset: take,
    manufacturedItemStatuses,
    ...filter
  }) =>
    this.instance.post(
      "/api/manufactureditems/filters",
      { manufacturedItemStatuses },
      {
        params: {
          take,
          ...filter,
        },
      }
    );
}
