import { cumdApi, productApi, projectApi, referenceListApi } from "apis";
import { portalTypeId } from "constants/index";
import {
  getAugmentedManufacturedItems,
  idCollectionFromProducts,
} from "redux/actions/FilteredProducts";
import { companyActivityType } from "constants/projects";
import { uniq, isEmpty } from "lodash";

export const getProject = async (projectId, languageCode, owningCompanyId) => {
  try {
    const { data: projectData } = await projectApi.getProjectById({
      projectId,
      languageCode,
    });

    const getAllResponsibilities = async () => {
      try {
        const { data: refResponsibilities } =
          await referenceListApi.getReferenceListByName({
            referenceListName: "Team_Member_Responsibility",
            languageCode,
            portalTypeId,
            companyId: projectData?.ownerCompanyId,
          });
        return refResponsibilities;
      } catch (error) {
        console.error(error);
      }
    };

    const refResponsibilities = await getAllResponsibilities();

    const getUsersTeam = async () => {
      if (!projectData.team.length) return [];

      const userIds = [
        ...new Set(
          projectData.team
            .filter(({ userId }) => userId)
            .map(({ userId }) => userId)
        ),
      ];

      const { data } = await cumdApi.getPublicUsers({
        userIds,
      });

      return projectData.team.map(teamCurrent => {
        const userTeam = data.find(
          ({ userId }) => userId === teamCurrent.userId
        );
        return {
          ...teamCurrent,
          userName: userTeam
            ? `${userTeam?.userFirstName} ${userTeam?.userLastName}`
            : "",
          responsibilityName: refResponsibilities.find(
            ({ id }) => id === teamCurrent.responsibilityId
          ).text,
        };
      });
    };

    let companyLogoUrl,
      companyDisplayName = null;
    if (owningCompanyId !== projectData.ownerCompanyId) {
      ({
        data: { companyLogoUrl, companyDisplayName },
      } = await cumdApi.getCompanyById({
        companyId: projectData.ownerCompanyId,
      }));
    }

    const getUserFullName = async userId => {
      const {
        data: { userFirstName, userLastName },
      } = await cumdApi.getUserById({
        userId,
      });
      return `${userFirstName} ${userLastName}`;
    };

    const getRessourceLabelForGantt = async () => {
      const userIds = projectData.bryntumProject.resources.rows.map(
        ({ id }) => id
      );

      const { data: usersRessource } = await cumdApi.getPublicUsers({
        userIds,
      });

      return usersRessource.map(({ userId, userLastName, userFirstName }) => ({
        userId,
        userLastName,
        userFirstName,
        userName: `${userFirstName} ${userLastName}`,
      }));
    };
    const results = {
      ...projectData,
      team: await getUsersTeam(),
      ganttRessourceLabel: await getRessourceLabelForGantt(),
      userName: await getUserFullName(projectData.ownerUserId),
      tradeItems: projectData.tradeItems ?? [],
      ...(owningCompanyId !== projectData.ownerCompanyId && {
        companyLogoUrl,
        companyDisplayName,
      }),
      allResponsibilities: refResponsibilities,
    };

    return results;
  } catch (error) {
    console.error(error);
  }
};

export const getProducts = async ({
  companyId,
  tradeIds,
  manufIds,
  languageCode,
}) => {
  try {
    const { getTradeItemsCollections, getManufacturedItemsCollections } =
      productApi;
    const { data: tradeItems } = await getTradeItemsCollections({
      idCollection: tradeIds,
    });

    const { data: manufacturedItems } = await getManufacturedItemsCollections({
      ownerCompanyId: companyId,
      idCollection: manufIds,
    });

    // get product and supplier data for manuf items
    const augmentedManufacturedItems = await getAugmentedManufacturedItems(
      manufacturedItems,
      companyId
    );

    const items = [...tradeItems, ...augmentedManufacturedItems];

    if (items.length) {
      const { data: netContentUnits } =
        await referenceListApi.getItemsCollections({
          languageCode,
          portalTypeId,
          idCollection: [
            ...new Set(
              items.map(({ netContentUnit }) => netContentUnit).filter(Boolean)
            ),
          ],
          companyId,
        });

      return items.map(product => {
        const unit = netContentUnits.find(
          ({ id }) => id === product.netContentUnit
        );
        return {
          ...product,
          netContentUnitText: unit?.text ? unit.text : null,
        };
      });
    } else {
      return items;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProductsCategories = async ({ products, languageCode }) => {
  try {
    const { data: netContentUnits } =
      await referenceListApi.getCategoryitemsCollections({
        languageCode,
        idCollection: idCollectionFromProducts(products),
      });
    return netContentUnits;
  } catch (error) {
    console.error(error);
  }
};

export const getUsersByResponbility = async (responsibilityId, companyId) => {
  const {
    data: { users },
  } = await cumdApi.getUsersByResponsibilityId({
    responsibilityId,
    companyId,
  });

  return users.map(({ userId, userFirstName, userLastName }) => ({
    value: userId,
    text: `${userFirstName} ${userLastName}`,
  }));
};
const getPublicUser = async userId => {
  if (!userId) return [];
  const { data } = await cumdApi.getPublicUsers({
    userIds: [userId],
  });

  return data.map(({ userId, userFirstName, userLastName }) => ({
    value: userId,
    text: `${userFirstName} ${userLastName}`,
  }));
};

const buildRetailerTeams = (responsibilities, teams) => {
  return responsibilities.map(responsibility => {
    const getUserSelected = () => {
      const teamSelected = teams.find(
        team => team.responsibilityId === responsibility.id
      )?.userId;
      return teamSelected ?? null;
    };
    const userSelected = getUserSelected();
    return userSelected
      ? { ...responsibility, userIdSelected: userSelected }
      : { ...responsibility };
  });
};

async function* generateSuppliersTeam({
  manufacturedItems,
  supplierResponsibilities,
  teams,
  currentUserCompanyActivityId,
}) {
  if (manufacturedItems?.length === 0) return Promise.resolve([]);
  const companyIds = [
    ...new Set(
      manufacturedItems.map(({ supplierCompanyId }) => supplierCompanyId)
    ),
  ];
  const { data: companiesSupplier } = await cumdApi.getPublicCompanies({
    companyIds,
  });

  const suppliersWithoutUsers = companyIds.map(supplierCompanyId => {
    const roles = supplierResponsibilities.map(({ id, name }) => {
      const [{ userId, invitedEmail, collaborationId, invitedUserId } = {}] =
        teams.filter(
          ({ companyActivityCode: code, companyId, responsibilityId }) =>
            code === companyActivityType.supplier &&
            companyId === supplierCompanyId &&
            responsibilityId === id
        );

      return {
        companyId: supplierCompanyId,
        id,
        name,
        users: [],
        userId,
        userIdSelected: invitedUserId,
        invitedUserId,
        invitedEmail,
        collaborationId,
      };
    });

    return {
      supplierCompanyId: supplierCompanyId,
      supplierCompanyName: companiesSupplier.find(
        ({ companyId }) => companyId === supplierCompanyId
      )?.companyDisplayName,
      count: manufacturedItems.filter(
        ({ supplierCompanyId: id }) => id === supplierCompanyId
      ).length,
      roles,
    };
  });
  yield suppliersWithoutUsers;

  const suppliers = suppliersWithoutUsers.map(async supplierCompany => {
    let roles = supplierCompany.roles.map(async role => ({
      ...role,
      users:
        currentUserCompanyActivityId === companyActivityType.retailer
          ? await getUsersByResponbility(role.id, role.companyId)
          : await getPublicUser(role.userId),
    }));
    roles = await Promise.all(roles);

    return {
      ...supplierCompany,
      roles,
    };
  });
  return await Promise.all(suppliers);
}
const generateThirdPartyTeam = async teams => {
  const teamCompanyIds = teams.map(team => team.companyId);
  if (isEmpty(teams)) return [];
  const { data } = await cumdApi.getPublicCompanies({
    companyIds: uniq(teamCompanyIds),
  });

  return data.map(company => ({
    companyId: company.companyId,
    companyName: company.companyDisplayName,
    companyActivityCode: company.companyActivityId,
    roles: teams.filter(team => team.companyId === company.companyId),
  }));
};
export async function* fetchTeams({
  teams = [],
  companyId,
  manufacturedItems,
  refResponsibilities,
  dataResponsibilities,
  currentUserCompanyActivityId,
}) {
  try {
    const excludedCompanyActivityCodes = [
      companyActivityType.retailer,
      companyActivityType.supplier,
    ];

    const retailerTeam =
      teams.find(
        team => team.companyActivityCode === companyActivityType.retailer
      ) || {};

    const retailerResponsibilities =
      dataResponsibilities.find(
        ({ companyActivityCode }) =>
          companyActivityCode === companyActivityType.retailer
      )?.responsibilityIds || [];

    const supplierResponsibilities =
      dataResponsibilities
        .find(
          ({ companyActivityCode }) =>
            companyActivityCode === companyActivityType.supplier
        )
        ?.responsibilityIds.map(responsibilityId => ({
          id: responsibilityId,
          companyActivityCode: companyActivityType.supplier,
          name: refResponsibilities.find(
            refResponsibility => refResponsibility.id === responsibilityId
          ).text,
        })) || [];

    const buildReponsibilities = retailerResponsibilities.map(
      async responsibilityId => {
        const userName = teams.find(
          team => team.responsibilityId === responsibilityId
        )?.userName;

        return {
          id: responsibilityId,
          companyActivityCode: companyActivityType.retailer,
          companyId,
          name: refResponsibilities.find(
            refResponsibility => refResponsibility.id === responsibilityId
          ).text,
          users:
            currentUserCompanyActivityId === companyActivityType.retailer
              ? await getUsersByResponbility(responsibilityId, companyId)
              : await getPublicUser(retailerTeam?.userId),

          userName,
        };
      }
    );

    const thirdPartyTeam = teams
      .filter(
        team => !excludedCompanyActivityCodes.includes(team.companyActivityCode)
      )
      .map(async responsability => ({
        id: responsability.responsibilityId,
        companyActivityCode: responsability.companyActivityCode,
        companyId: responsability.companyId,
        name: refResponsibilities.find(
          refResponsibility =>
            refResponsibility.id === responsability.responsibilityId
        ).text,
        userIdSelected: responsability.invitedUserId,
        userId: responsability.userId,
        invitedUserId: responsability.invitedUserId,
        invitedEmail: responsability.invitedEmail,
        collaborationId: responsability.collaborationId,
        users:
          currentUserCompanyActivityId === companyActivityType.retailer
            ? await getUsersByResponbility(
                responsability.responsibilityId,
                responsability.companyId
              )
            : await getPublicUser(responsability.userId),
      }));

    const responsibilities = await Promise.all(buildReponsibilities);
    const supplierTeamGen = generateSuppliersTeam({
      manufacturedItems,
      teams,
      supplierResponsibilities,
      currentUserCompanyActivityId,
    });

    const result = {
      retailerTeams: buildRetailerTeams(responsibilities, teams),
      supplierTeams: (await supplierTeamGen.next()).value,
      thirdPartyTeams: await generateThirdPartyTeam(
        await Promise.all(thirdPartyTeam)
      ),
      supplierResponsibilities,
    };
    yield result;
    result.supplierTeams = (await supplierTeamGen.next()).value;
    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
}

export const fetchTeamsModal = async ({
  userId,
  languageCode,
  companyId,
  owningCompanyId,
}) => {
  try {
    const { data: teams } = await projectApi.getTeamsByFilter({
      userId: userId,
      ownerCompanyId: owningCompanyId,
    });

    const { data: productBrands } = await productApi.getBrands({
      ownerCompanyId: companyId,
    });

    const userIds = teams.reduce((acc, { responsibilities }) => {
      const ids = responsibilities.map(({ userId }) => userId);
      return [...acc, ...ids];
    }, []);
    const ids = [...new Set(userIds)];
    const users = await getUserData(ids);

    const idCategories = teams.reduce((acc, curr) => {
      if (
        curr.productCategoryId &&
        !acc.some(
          productCategoryId => productCategoryId === curr.productCategoryId
        )
      ) {
        return [...acc, curr.productCategoryId];
      }
      return [...acc];
    }, []);
    const { data: categories } =
      await referenceListApi.getCategoryitemsCollections({
        languageCode,
        idCollection: [...new Set(idCategories)],
      });

    const catTeam = teams.map(team => ({
      ...team,
      productCategoryName: categories.find(
        cat => cat.categoryItemId === team.productCategoryId
      )?.categoryItemName,
      brandName:
        productBrands.find(({ id }) => id === team.brandId)?.brandName ?? "-",
      responsibilities: team.responsibilities.map(responsibility => {
        const user = users.find(user => user.userId === responsibility.userId);
        return user
          ? {
              ...responsibility,
              colour: user.colour,
              userFirstName: user.userFirstName,
              userLastName: user.userLastName,
              userPhotoUrl: user.userPhotoUrl,
            }
          : responsibility;
      }),
    }));

    return {
      users,
      teams: catTeam,
    };
  } catch (error) {
    console.error("error", error);
    return {};
  }
};

const getUserData = async ids => {
  let { data: users } = await cumdApi.getUsersCollection({ userIds: ids });

  users = users.map((user, i) => {
    return {
      ...user,
      colour: getColour(),
    };
  });

  return users;
};

const getColour = () => {
  const teamColours = [
    "#60D483",
    "#FF9F3E",
    "#FF5F52",
    "#2699FB",
    "#9F9F9F",
    "#4BB6E3",
  ];
  const min = 0;
  const max = 6;
  const int = ~~(Math.random() * (max - min)) + min;

  return teamColours[int];
};
