import { useEffect, useState } from "react";
import { selectOwningCompanyId } from "common/selectors/users.selectors";
import { getProject } from "components/Project/API";
import { useSelector } from "react-redux";
const useGetPackagingSystemCreationdata = (projectId, languageCode) => {
  const [projectData, setProjectData] = useState();
  const owningCompanyId = useSelector(selectOwningCompanyId);
  const getProjectDetails = async () => {
    try {
      const projectDetails = await getProject(
        projectId,
        languageCode,
        owningCompanyId
      );
      setProjectData(projectDetails);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    getProjectDetails();
  }, [projectId, languageCode, owningCompanyId]);
  return { projectData };
};
export default useGetPackagingSystemCreationdata;
