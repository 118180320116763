export const getTabWithPath = (tabs, path) => {
  return tabs.find(tab => tab.path.startsWith(path));
};

export const getProjectDefinitionData = fullProjectData => {
  const {
    name,
    projectTypeId,
    projectTypeName,
    description,
    status,
    projectCode,
  } = fullProjectData;

  return {
    name,
    projectTypeId,
    projectTypeName,
    description,
    status,
    projectCode,
  };
};

export const CREATION_STEP = {
  definition: "definition",
  products: "products",
  planning: "planning",
  team: "team",
  summary: "summary",
};

export const getTeamUserColor = () => {
  const teamColours = [
    "#60D483",
    "#FF9F3E",
    "#FF5F52",
    "#2699FB",
    "#9F9F9F",
    "#4BB6E3",
  ];
  const min = 0;
  const max = 6;
  const int = ~~(Math.random() * (max - min)) + min;

  return teamColours[int];
};
