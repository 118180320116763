import React, { useState } from "react";
import { PackagingSystem } from "@trace-one/business-components";
import { useLanguagePreference, Spinner } from "@trace-one/react-components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "common/selectors/users.selectors";
import { useAssociatePackaging } from "hooks";
import useGetPackagingSystemCreationdata from "./useGetPackagingSystemData";
import styles from "components/FormSchema/widgets/PackagingSystemWidget/createPackagingSystem/EditPackagingSystem/packagingSystem.module.less";
const AssociatePackagingSystem = () => {
  const history = useHistory();
  const languageCode = useLanguagePreference();
  const companyId = useSelector(selectOwningCompanyId);
  const [loading, setLoading] = useState<boolean>(false);
  const { associatedProductId, id: projectId } = useParams<any>();
  const { projectData } = useGetPackagingSystemCreationdata(
    projectId,
    languageCode
  );
  const { associatePackaging } = useAssociatePackaging({
    projectData,
    selectedProducts: [associatedProductId],
    setSilentRefresh: () => {},
    taskId: null,
  });
  return (
    <div className={styles.packagingSystem}>
      {loading ? (
        <div className={styles.spinner}>
          <Spinner size="large" />
        </div>
      ) : (
        <PackagingSystem
          languageCode={languageCode}
          onAssociate={params => {
            setLoading(true);
            associatePackaging(params);
          }}
          ownerCompanyId={companyId}
          closePackagingSystemCreation={() => {
            history.push(`/projects/projectfolder/${projectId}/products`);
          }}
        />
      )}
    </div>
  );
};
export default AssociatePackagingSystem;
