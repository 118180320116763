export const REGISTER_DATA = "register data";
export const SET_WARNING_FIELDS = "set warning fields";
export const portalTypeId = "2eef4dc6-6282-450b-9f81-a5e14c59a893";
export const retailerCompanyActivityGuid =
  "1243372f-3ab8-404e-9684-590d90dead90";
export const applicationId = { project: 50 };
export const TOLPDFTRON =
  "Trace One SAS(traceone.com/en):OEM:See Agreement::B+:AMS(20221230):4FB53D120477580A7360B13AC9A2737860614F8B5C700A1BBD12C401ED2CB9A6328431F5C7";

export const MEASUREMENT_TOOLS = [
  "AnnotationCreateDistanceMeasurement",
  "AnnotationCreateDistanceMeasurement2",
  "AnnotationCreateDistanceMeasurement3",
  "AnnotationCreateDistanceMeasurement4",
  "AnnotationCreatePerimeterMeasurement",
  "AnnotationCreatePerimeterMeasurement2",
  "AnnotationCreatePerimeterMeasurement3",
  "AnnotationCreatePerimeterMeasurement4",
  "AnnotationCreateAreaMeasurement",
  "AnnotationCreateAreaMeasurement2",
  "AnnotationCreateAreaMeasurement3",
  "AnnotationCreateAreaMeasurement4",
  "AnnotationCreateEllipseMeasurement",
  "AnnotationCreateEllipseMeasurement2",
  "AnnotationCreateEllipseMeasurement3",
  "AnnotationCreateEllipseMeasurement4",
  "AnnotationCreateRectangularAreaMeasurement",
  "AnnotationCreateRectangularAreaMeasurement2",
  "AnnotationCreateRectangularAreaMeasurement3",
  "AnnotationCreateRectangularAreaMeasurement4",
  "AnnotationCreateCloudyRectangularAreaMeasurement",
  "AnnotationCreateCloudyRectangularAreaMeasurement2",
  "AnnotationCreateCloudyRectangularAreaMeasurement3",
  "AnnotationCreateCloudyRectangularAreaMeasurement4",
];

export const roleIds = {
  projectParticipant: "5db26f3a-a16a-481b-8c3e-d57effcde09b",
  projectPackagingManager: "d6abd7d5-3272-4c7b-9adf-e649e98d6b9e",
  projectPackagingParticipant: "0ea7f50c-2355-46b3-9cbe-4666a55705b2",
};

export const packAddOnOfferIdsByActivityCode = {
  // Suplier
  2: "79361285-f2c3-4af6-ac88-05ee55ffe114",
  // Laboratory
  10: "4a04696a-fda7-49a0-ad64-6dada72db336",
  // Certification Body
  11: "a752d517-6aff-410c-836d-4d17d2ae19a5",
  // Translation Agency
  18: "95eef868-30be-42e0-bc40-bedfa0a85e3a",
  // Design Agency
  19: "58a5bb3f-d7a3-472f-a898-5c4ec479172d",
  // Photoengraving Agency
  21: "4a04696a-fda7-49a0-ad64-6dada72db336",
};

export const DEFAULT_OFFER_ID = "2738d955-8b61-4c21-983c-083ef10209e3";

export const projectDocumentStatus = {
  validated: "Validated",
};

export const packagingDocumentStatus = {
  validated: "Validated",
};

export const metaDataSearchForFileUpload = [
  {
    key: "project_file_state",
    value: "Validated",
    operator: "Equal",
  },
];

export const ACCEPTED_FILE_TYPES =
  ".pdf, .jpg, .jpeg, .png, .tiff, .bmp, .gif, .xls, .xlsx, .ppt, .pptx, .doc, .docx";

export const ACCEPTED_FILE_TYPES_PDFTRON_LEFT_PANEL_UPLOAD = ".pdf,";

export const UNACCEPTED_FILE_TYPES = [
  "png",
  "jpeg",
  "xlsx",
  "pptx",
  "docx",
  "zip",
  "doc",
  "ai",
  "xls",
  "tif",
  "jpg",
  "psd",
  "ap",
  "eps",
  "txt",
  "xlsm",
  "htm",
  "mod",
  "art",
  "rtf",
  "ppt",
  "rar",
  "msg",
  "dxf",
  "mx4",
  "icc",
  "mht",
  "DWG",
  "docm",
  "odt",
  "csv",
  "7z",
  "tiff",
  "bmp",
  "gif",
];

export const ISO_STRING_FORMAT = "YYYY-MM-DD[T]HH:mm:ss[Z]";
export const DATE_FORMAT_YMD = "YYYY.MM.DD";
export const DATE_FORMAT_DMY = "DD/MM/YYYY";

export const MAX_PRODUCTS_CREATION = 50;

// Creation / definition
export const SET_STEPPER_DATA = "set stepper data";
export const SEARCH_WITH_FILTERS = "search with filters";
export const RECEIVED_TYPES_FOR_SELECT = "received types for select";
export const SET_DUPLICATE_PROJECT_CREATION = "SET_DUPLICATE_PROJECT_CREATION";
export const TYPES_FOR_SELECT_LOADING = "types for select loading";
export const RESET_PROJECT = "reset project";
export const SET_TEAM = "SET_TEAM";
export const SET_TEAM_RETAILER = "SET_TEAM_RETAILER";
export const SET_TEAM_SUPPLIER = "SET_TEAM_SUPPLIER";
export const SET_DISPLAY_TOASTER_SAVING = "SET_DISPLAY_TOASTER_SAVING";
export const SET_SELECTED_TEAM = "SET_SELECTED_TEAM";
export const UPDATE_SELECTED_TEAM = "UPDATE_SELECTED_TEAM";
export const REMOVE_SELECTED_TEAM = "REMOVE_SELECTED_TEAM";
export const SET_SELECTED_SUPPLIER = "SET_SELECTED_SUPPLIER";
export const UPDATE_SELECTED_SUPPLIER = "UPDATE_SELECTED_SUPPLIER";
export const REMOVE_SELECTED_SUPPLIER = "REMOVE_SELECTED_SUPPLIER";

export const SET_BRYNTUM_DATA = "SET_BRYNTUM_DATA";
export const SET_BRYNTUM_USER_RESPONSIBILITIES =
  "SET_BRYNTUM_USER_RESPONSIBILITIES";
export const SET_BRYNTUM_IS_LOADING = "SET_BRYNTUM_IS_LOADING";

export const SET_GANTT_SAVE_DATA = "SET_GANTT_SAVE_DATA";

export const SET_SELECTED_TYPE = "SET_SELECTED_TYPE";
export const SET_PROJECT_TYPES = "SET_PROJECT_TYPES";

export const SET_IS_TEAMS_LOADING = "IS_TEAMS_LOADING";

export const SET_TREE_PRODUCT_CATEGORIES = "SET_TREE_PRODUCT_CATEGORIES";
export const SET_TRADE_ITEMS = "SET_TRADE_ITEMS";
export const SET_IS_TRADE_ITEMS_LOADING = "SET_IS_TRADE_ITEMS_LOADING";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const HANDLE_CHANGE_FILTER = "HANDLE_CHANGE_FILTER";
export const SET_PRODUCT_BRANDS = "SET_PRODUCT_BRANDS";
export const SET_IS_PRODUCT_BRANDS_LOADING = "SET_IS_PRODUCT_BRANDS_LOADING";
export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES";
export const SET_PRODUCT_NET_CONTENT_UNITS = "SET_PRODUCT_NET_CONTENT_UNITS";
export const SET_IS_PRODUCT_NET_CONTENT_UNITS_LOADING =
  "SET_IS_PRODUCT_NET_CONTENT_UNITS_LOADING";
export const SET_IS_TREE_CATEGORIES_LOADING = "SET_IS_TREE_CATEGORIES_LOADING";
export const SET_SUPPLIERS = "SET_SUPPLIERS";

export const SET_SELECTED_PRODUCTS = "SET_SELECTED_PRODUCTS";
export const SET_IS_SELECTED_PRODUCTS_LOADING =
  "SET_IS_SELECTED_PRODUCTS_LOADING";
export const SET_VALIDATED_PRODUCTS = "SET_VALIDATED_PRODUCTS";
export const REMOVE_VALIDATED_PRODUCT = "REMOVE_VALIDATED_PRODUCT";
export const IS_CATEGORIES_FOR_VALIDATED_PRODCTS_LOADING =
  "IS_CATEGORIES_FOR_VALIDATED_PRODCTS_LOADING";
export const SET_CATEGORIES_FOR_VALIDATED_PRODCTS =
  "SET_CATEGORIES_FOR_VALIDATED_PRODCTS";
export const SET_IS_DIRTY = "SET_IS_DIRTY";

export const SET_IS_SAVE_AS_DRAFT_LOADING = "SET_IS_SAVE_AS_DRAFT_LOADING";

export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const RESET_PROJECT_CREATION_STATE = "RESET_PROJECT_CREATION_STATE";
export const SET_PROJECT_CREATION_TEAM_SELECTED_SEARCH =
  "SET_PROJECT_CREATION_TEAM_SELECTED_SEARCH";
export const SET_CREATION_POP_UP = "SET_CREATION_POP_UP";

export const DISPLAY_PROJECT_DETAILS = "DISPLAY_PROJECT_DETAILS";
export const SET_TASKS = "SET_TASKS";
export const SET_COUNTERS = "SET_COUNTERS";

export const SET_SELECTED_PRODUCTS_CURRENT_AND_TOTAL =
  "SET_SELECTED_PRODUCTS_CURRENT_AND_TOTAL";
export const SET_FILTERED_PRODUCTS_PAGE_SIZE =
  "SET_FILTERED_PRODUCTS_PAGE_SIZE";
export const SET_TASKS_CALENDAR = "SET_TASKS_CALENDAR";
export const SET_DOCUMENT_LABELS = "SET_DOCUMENT_LABELS";
export const SET_DOCUMENT_TASKDETAIL = "SET_DOCUMENT_TASKDETAIL";

export const SET_PROJECT_FOLDER = "SET_PROJECT_FOLDER";
export const SET_DUPLICATE_PROJECT_FOLDER = "SET_DUPLICATE_PROJECT_FOLDER";
export const SET_IS_PROJECT_FOLDER_LOADING = "SET_IS_PROJECT_FOLDER_LOADING";
export const SET_IS_PROJECT_FOLDER_TEAM_LOADING =
  "SET_IS_PROJECT_FOLDER_TEAM_LOADING";
export const SET_IS_SUPPLIER_TEAM_LOADING = "SET_IS_SUPPLIER_TEAM_LOADING";
export const SET_PROJECT_TEAM_SUPPLIER = "SET_PROJECT_TEAM_SUPPLIER";
export const SET_PROJECT_TEAM_SELECTED_SEARCH =
  "SET_PROJECT_TEAM_SELECTED_SEARCH";
export const SET_PRODUCTS_PROJECT_FOLDER = "SET_PRODUCTS_PROJECT_FOLDER";
export const SET_CURRENT_PRODUCTS_PROJECT_FOLDER =
  "SET_CURRENT_PRODUCTS_PROJECT_FOLDER";
export const SET_IS_PRODUCTS_PROJECT_FOLDER_LOADING =
  "SET_IS_PRODUCTS_PROJECT_FOLDER_LOADING";
export const SET_IS_SAVING = "SET_IS_SAVING";
export const SET_IS_SAVED = "SET_IS_SAVED";
export const SET_IS_SAVED_ERROR = "SET_IS_SAVED_ERROR";
export const SET_IS_ACTIVATED = "SET_IS_ACTIVATED";
export const CANCEL_PROJECT_CHANGES = "CANCEL_PROJECT_CHANGES";
export const SET_PROJECT_FOLDER_END_DATE = "SET_PROJECT_FOLDER_END_DATE";
export const SET_PROJECT_FOLDER_START_DATE = "SET_PROJECT_FOLDER_START_DATE";
export const SET_PROJECT_TEAMS = "SET_PROJECT_TEAMS";
export const SET_PROJECT_TEAM_RETAILER = "SET_PROJECT_TEAM_RETAILER";
export const SET_MODE_GANTT_PROJECT_FOLDER = "SET_MODE_GANTT_PROJECT_FOLDER";

export const GET_ACCESS_TOKEN_PENDING = "GET_ACCESS_TOKEN_PENDING";
export const PENDING = "PENDING";
export const SET_OIDC_ACCESS_TOKEN = "SET_OIDC_ACCESS_TOKEN";

// Administration
export const SET_RETAILERS = "SET_RETAILERS";
export const SET_RETAILER = "SET_RETAILER";
export const SET_RETAILER_ID = "SET_RETAILER_ID";
export const SET_VALID_WORKFLOW = "SET_VALID_WORKFLOW";
export const SET_VERIFYING_WORKFLOW = "SET_VERIFYING_WORKFLOW";
export const SET_FILENAME = "SET_FILENAME";
export const SET_DEPLOYING_WORKFLOW = "SET_DEPLOYING_WORKFLOW";
export const SET_DEPLOYED_WORKFLOW = "SET_DEPLOYED_WORKFLOW";
export const SET_BASE64_FILE = "SET_BASE64_FILE";
export const SET_HAS_ERROR = "SET_HAS_ERROR";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_PROJECTTYPES_LOADING = "SET_PROJECTTYPES_LOADING";
export const SET_FILE_BASE64 = "SET_FILE_BASE64";
export const RESET_TRANSLATION = "RESET_TRANSLATION";
export const RESET = "RESET";
export const RESET_FILE = "RESET_FILE";
export const SET_TRANSLATION_ERROR = "SET_TRANSLATION_ERROR";
export const SET_IMPORT_SUCCESS = "SET_IMPORT_SUCCESS";
export const SET_IMPORT_DETAILS = "SET_IMPORT_DETAILS";
export const SET_IS_DEPLOYING_IMPORT = "SET_IS_DEPLOYING_IMPORT";
export const SET_MANAGEMENT_PROJECT_TYPES = "SET_MANAGEMENT_PROJECT_TYPES";
export const SET_MANAGEMENT_LOADING = "SET_MANAGEMENT_LOADING";
export const RESET_MANAGEMENT = "RESET_MANAGEMENT";
export const SET_MANAGEMENT_ERROR = "SET_MANAGEMENT_ERROR";

export const SET_USER = "SET_USER";
export const SET_USER_LOADING = "SET_USER_LOADING";
export const SET_USER_APPLICATIONS = "SET_USER_APPLICATIONS";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const SET_COMPANY_ACTIVITY_ID = "SET_COMPANY_ACTIVITY_ID";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const SET_USER_LANGUAGE_PREFERENCE = "SET_USER_LANGUAGE_PREFERENCE";

// Create products
export const SET_REGISTER_SAVE = "SET_REGISTER_SAVE";
export const INC_SUCCESS_TOTAL = "INC_SUCCESS_TOTAL";
export const SET_TOTAL_CREATED = "SET_TOTAL_CREATED";
export const UPDATE_TOTAL_CREATED = "UPDATE_TOTAL_CREATED";
export const RESET_CREATE_PRODUCTS = "RESET_CREATE_PRODUCTS";
export const STORE_GTIN = "STORE_GTIN";

// team
export const CREATE_TEAM = "CREATE_TEAM";
export const SAVE_TEAM_SUCCESS = "SAVE_TEAM_SUCCESS";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DUPLICATE_TEAM_SUCCESS = "DUPLICATE_TEAM_SUCCESS";
export const EDIT_TEAM_SUCCESS = "EDIT_TEAM_SUCCESS";
export const SET_TEAMS = "SET_TEAMS";
export const SET_USERS = "SET_USERS";
export const SET_TEAMS_LOADING = "SET_TEAMS_LOADING";

// ganntView
export const SET_GANTT_VIEW_DISPLAY_TASK = "SET_GANTT_VIEW_DISPLAY_TASK";
export const SET_GANTT_VIEW_EXPAND = "SET_GANTT_VIEW_EXPAND";
export const SET_GANTT_VIEW_DISPLAY_CRITICAL_PATH =
  "SET_GANTT_VIEW_DISPLAY_CRITICAL_PATH";
export const SET_GANTT_VIEW_DISPLAY_FUTURE_TASKS =
  "SET_GANTT_VIEW_DISPLAY_FUTURE_TASKS";
export const SET_GANTT_VIEW_DISPLAY_PROJECTIONS =
  "SET_GANTT_VIEW_DISPLAY_PROJECTIONS";
export const SET_GANNT_VIEW_FILTER_POPOVER = "SET_GANNT_VIEW_FILTER_POPOVER";
export const SET_GANTT_VIEW_IS_LOCKED_COLLAPSED =
  "SET_GANTT_VIEW_IS_LOCKED_COLLAPSED";
export const SET_GANTT_VIEW_IS_NORMAL_COLLAPSED =
  "SET_GANTT_VIEW_IS_NORMAL_COLLAPSED";
export const SET_GANTT_VIEW_SLIDER_POSITION = "SET_GANTT_VIEW_SLIDER_POSITION";

export const SET_ERRORS_EVENTS = "SET_ERRORS_EVENTS";
export const RESET_ERRORS_EVENTS = "RESET_ERRORS_EVENTS";

export const RMLD_IDS = {
  componentTypes: "b171e5be-f0b5-433f-a27f-7a06a640ccfc",
};
export const ERROR_CODE_EXTENSION = "2c8ff17e-3ee9-4304-b65e-4d245ffbc168";
export const ERROR_CODE_EXTENSION_VALIDATED =
  "63dfda5e-bf42-4aa9-be25-393a4351e87a";
export const ERROR_CODE_OVERSIZE_FILE = "216d53bd-8a86-44b3-8a42-bdc0e4ab08bf";
export const ERROR_CODE_INVALID_DURATION = "InvalidConditionDurationProperties";
export const ERROR_CODE_INVALID_TIMER = "IncorrectTimerIntermediateCatchEvent";

// Project (ManufItem / ComponentDesign)
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const SET_DOCUMENTS_LOADING = "SET_DOCUMENTS_LOADING";
export const SET_DOCUMENTS_PROJECT_DETAILS = "SET_DOCUMENTS_PROJECT_DETAILS";
export const SET_DOCUMENTS_URL = "SET_DOCUMENTS_URL";
export const SET_DOCUMENTS_MODAL_LOADING = "SET_DOCUMENTS_MODAL_LOADING";
export const SET_DOCUMENTS_MODAL_STATUS = "SET_DOCUMENTS_MODAL_STATUS";
export const PRODUCT_DESIGN = "PRODUCT_DESIGN";
export const COMPONENT_DESIGN = "COMPONENT_DESIGN";
export const PROJECT_DESIGN = "PROJECT_DESIGN";
export const SHOULD_REFETCH_DOCUMENTS = "SHOULD_REFETCH_DOCUMENTS";
export const SET_DOCUMENTS_LOADED = "SET_DOCUMENTS_LOADED";
export const SET_DOCUMENTS_ALERT_MESSAGE = "SET_DOCUMENTS_ALERT_MESSAGE";
export const SET_DOCUMENTS_ALERT_MESSAGES = "SET_DOCUMENTS_ALERT_MESSAGES";

export const ERROR_CODE_TASK_UNACCESABLE =
  "3ae99e47-d8f9-4680-b6a9-e386332c270f";
export const ERROR_CODE_DUPICATE_NAME = "4d7a1a96-5e47-4f96-a417-abd4e17ff691";

// Collapse
export const SET_COLLAPSE_KEYS = "SET_COLLAPSE_KEYS";
