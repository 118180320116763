import { Button, ConfirmationPopover, Icon } from "@trace-one/react-components";
import ProjectLaunchModal from "components/ProjectLaunchModal/ProjectLaunchModal";
import { SetStateAction, Dispatch } from "react";
import { translatedButtons } from "routes/Projects/defineMessages";
import styles from "../../Creation.module.less";
import useButtons from "./hooks/useButtons";
const Buttons = ({
  saveAsDraft,
  isModified,
  setRefresh,
}: {
  saveAsDraft: () => Promise<void>;
  isModified: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const {
    intl,
    history,
    projectData,
    stepperData,
    showModal,
    popoverVisible,
    isSaveAsDraftLoading,
    isActivating,
    projectname,
    startDate,
    redirectTo,
    handleNext,
    handleGoToProject,
    handleSaveAndGo,
    handleGoWithoutSaving,
    launchProject,
    setShowModal,
    handleSkip,
    setPopoverVisible,
    closeModal,
  } = useButtons({ saveAsDraft, isModified, setRefresh });

  const renderButton = () => {
    if (history.location.pathname === "/projects/creation/summary") {
      return (
        <Button onClick={() => setShowModal(true)} data-test-id="nextButton">
          {intl.formatMessage(translatedButtons.launch)} &gt;
        </Button>
      );
    }
    return (
      <Button
        loading={isSaveAsDraftLoading}
        data-test-id="nextButton"
        onClick={() => handleNext(stepperData)}
      >
        {stepperData && handleSkip(stepperData)
          ? intl.formatMessage(translatedButtons.skip)
          : intl.formatMessage(translatedButtons.next)}
      </Button>
    );
  };
  return (
    <div className={styles.bottomButtonContainer}>
      <div className={styles.leftButtonsContainer}>
        <Button
          type="link"
          onClick={() => redirectTo("/projects/projects-list")}
          icon={<Icon name="bin" color="#0099a4" />}
        >
          {intl.formatMessage(translatedButtons.cancelCreation)}
        </Button>
      </div>
      <div className={styles.rightButtonsContainer}>
        <ConfirmationPopover
          contentMessage={
            <div className={styles.popoverMessageContainer}>
              {intl.formatMessage(translatedButtons.warningMessage)}
            </div>
          }
          icon="circle-info"
          onConfirmation={() => handleSaveAndGo()}
          onCancel={() => handleGoWithoutSaving()}
          cancelButtonMessage={intl.formatMessage(
            translatedButtons.goWithoutSaving
          )}
          confirmationButtonMessage={intl.formatMessage(
            translatedButtons.saveAndGo
          )}
          placement="top"
          trigger="click"
          getPopupContainer={trigger => trigger.parentNode}
          visible={popoverVisible}
          onVisibleChange={() => popoverVisible && setPopoverVisible(false)}
          cancelButtonClassname="cancelButton"
          confirmButtonClassname="buttonDisable"
          iconColor="#ff9f3e"
        >
          <Button
            disabled={!projectData.id}
            data-test-id="go-to-project"
            type="link"
            onClick={() => handleGoToProject(stepperData)}
            className="goToProject"
            style={{ marginRight: "10px" }}
          >
            {intl.formatMessage(translatedButtons.goToProject)}
          </Button>
        </ConfirmationPopover>
        {renderButton()}
        <ProjectLaunchModal
          showModal={showModal}
          launchProject={launchProject}
          closeModal={closeModal}
          isActivating={isActivating}
          projectName={projectname}
          startDate={startDate}
        />
      </div>
    </div>
  );
};

export default Buttons;
