import { defineMessages } from "react-intl";

export const projectListTitle = defineMessages({
  id: "projectList.title",
  defaultMessage: "projects list",
});

export const additionalInformations = defineMessages({
  projectAdditionalInformation: {
    id: "projectsList.components.table.expand.projectAdditionalInformation",
    defaultMessage: "Additional informations",
  },
  projectOwner: {
    id: "projectsList.components.table.expand.projectOwner",
    defaultMessage: "Project owner",
  },
  projectCode: {
    id: "projectsList.components.table.expand.projectCode",
    defaultMessage: "Project code",
  },
  projectPrevisionalEndDate: {
    id: "projectsList.components.table.expand.projectPreviosionalEndDate",
    defaultMessage: "Previsional end date",
  },
  projectProgress: {
    id: "projectsList.components.table.expand.projectProgress",
    defaultMessage: "Progress",
  },
});

export const columnName = defineMessages({
  companyName: {
    id: "projectsList.components.table.companyName",
    defaultMessage: "Company",
  },
  projectName: {
    id: "projectsList.components.table.projectName",
    defaultMessage: "Project name",
  },
  type: {
    id: "projectsList.components.table.type",
    defaultMessage: "Type",
  },
  projectOwner: {
    id: "projectsList.components.table.projectOwner",
    defaultMessage: "Project owner",
  },
  progress: {
    id: "projectsList.components.table.progress",
    defaultMessage: "Progress",
  },
  products: {
    id: "projectsList.components.table.products",
    defaultMessage: "Number of products",
  },
  startDate: {
    id: "projectsList.components.table.startDate",
    defaultMessage: "Start date",
  },
  dueDate: {
    id: "projectsList.components.table.endDate",
    defaultMessage: "End date",
  },
  status: {
    id: "projectsList.components.table.status",
    defaultMessage: "Status",
  },
  archiveProject: {
    id: "projectsList.components.table.archiveProject",
    defaultMessage: "Archive the project",
  },
  deleteProject: {
    id: "projectsList.components.table.deleteProject",
    defaultMessage: "Delete the project",
  },
});

export const noProjectAndShowing = defineMessages({
  noProject: {
    id: "projectsList.components.table.noProject",
    defaultMessage: "No projects",
  },
  showing: {
    id: "projectsList.components.table.showing",
    defaultMessage: "Showing {userProjectslength}",
  },
  ofThisTotal: {
    id: "projectsList.components.table.ofThisTotal",
    defaultMessage: "of {total} projects",
  },
  selected: {
    id: "projectsList.components.table.selected",
    defaultMessage: " - {selected} project{plural} selected",
  },
});

export const bulkActionsButtons = defineMessages({
  replaceUser: {
    id: "projectsList.components.table.bulkaction.buttons.replaceuser",
    defaultMessage: "Replace User",
  },
  showOnlyUnarchived: {
    id: "projectsList.components.table.bulkaction.buttons.showOnlyUnarchived",
    defaultMessage: "Show only unarchived",
  },
});

export const kpiName = defineMessages({
  myProjects: {
    id: "projectList.components.kpis.myProjects",
    defaultMessage: "my{br} projects ##########",
  },
  draft: {
    id: "projectList.components.kpis.draft",
    defaultMessage: "draft{br} projects",
  },
  inProgress: {
    id: "projectList.components.kpis.inProgress",
    defaultMessage: "in progress{br} projects",
  },
  late: {
    id: "projectList.components.kpis.late",
    defaultMessage: "Late{br} projects",
  },
  scheduled: {
    id: "projectList.components.kpis.scheduled",
    defaultMessage: "scheduled{br} projects",
  },
  my: {
    id: "projectList.components.kpis.my",
    defaultMessage: "my",
  },
});

export const filtersLabels = defineMessages({
  searchPlaceholder: {
    id: "projectList.components.filtersLabels.searchInput.searchPlaceholder",
    defaultMessage: "Enter a name, and/or description, type",
  },
  searchBtn: {
    id: "projectList.components.filtersLabels.searchInput.searchBtn",
    defaultMessage: "SEARCH",
  },
  addFilters: {
    id: "projectList.components.filtersLabels.addFilters",
    defaultMessage: "Add filters",
  },
  showFilters: {
    id: "projectList.components.filtersLabels.showFilters",
    defaultMessage: "Show filters",
  },
  hideFilters: {
    id: "projectList.components.filtersLabels.hidefilters",
    defaultMessage: "Hide filters",
  },
  removeAllFilters: {
    id: "projectList.components.filtersLabels.removeAllFilters",
    defaultMessage: "Clear filters",
  },
  projectType: {
    id: "projectList.components.filtersLabels.projectType",
    defaultMessage: "Project type",
  },
  projectTypeFilter: {
    id: "projectList.components.filtersLabels.projectTypeFilter",
    defaultMessage: "Project type: {type}",
  },
  company: {
    id: "projectList.components.filtersLabels.company",
    defaultMessage: "Company",
  },
  companyFilter: {
    id: "projectList.components.filtersLabels.companyFilter",
    defaultMessage: "Company: {companyName}",
  },
  companyPlaceholder: {
    id: "projectList.components.filtersLabels.companyPlaceholder",
    defaultMessage: "Select a company",
  },
  teamUser: {
    id: "projectList.components.filtersLabels.teamUser",
    defaultMessage: "Participant",
  },
  teamUserFilter: {
    id: "projectList.components.filtersLabels.teamUserFilter",
    defaultMessage: "Participant: {userName}",
  },
  teamUserPlaceholder: {
    id: "projectList.components.filtersLabels.teamUserPlaceholder",
    defaultMessage: "Select a participant",
  },
  projectOwner: {
    id: "projectList.components.filtersLabels.projectOwner",
    defaultMessage: "Project owner",
  },
  projectOwnerFilter: {
    id: "projectList.components.filtersLabels.projectOwnerFilter",
    defaultMessage: "Project owner: {userName}",
  },
  projectOwnerPlaceholder: {
    id: "projectList.components.filtersLabels.projectOwnerPlaceholder",
    defaultMessage: "Select a project owner",
  },
  projectTypePlaceholder: {
    id: "projectList.components.filtersLabels.projectTypePlaceholder",
    defaultMessage: "Select a workflow",
  },
  projectStatus: {
    id: "projectList.components.filtersLabels.projectStatus",
    defaultMessage: "Project status",
  },
  projectProgress: {
    id: "projectList.components.filtersLabels.projectProgress",
    defaultMessage: "Project progress",
  },
  tradeItemIds: {
    id: "projectList.components.filtersLabels.tradeItemIds",
    defaultMessage: "Project products",
  },
  tradeItemIdsPlaceholder: {
    id: "projectList.components.filtersLabels.tradeItemIdsPlaceholder",
    defaultMessage: "Enter GTIN or the product name",
  },
  tradeItemIdsFilter: {
    id: "projectList.components.filtersLabels.tradeItemIdsFilter",
    defaultMessage: "Project product: {product}",
  },
  tradeItemIdsErrorMessage: {
    id: "projectList.components.filtersLabels.tradeItemIdsErrorMessage",
    defaultMessage: "Please select a project product",
  },
  tradeItemIdButtonLabel: {
    id: "projectList.components.filtersLabels.tradeItemIdButtonLabel",
    defaultMessage: "Add",
  },

  // progress
  Ahead: {
    id: "projectList.components.filtersLabels.ahead",
    defaultMessage: "Ahead projects",
  },
  OnTime: {
    id: "projectList.components.filtersLabels.onTime",
    defaultMessage: "On time projects",
  },
  Behind: {
    id: "projectList.components.filtersLabels.behind",
    defaultMessage: "Behind projects",
  },
  tasksStatus: {
    id: "projectList.components.filtersLabels.tasksStatus",
    defaultMessage: "Task status",
  },
  startDate: {
    id: "projectList.components.filtersLabels.startDate",
    defaultMessage: "Start date between",
  },
  startDatePlaceholder: {
    id: "projectList.components.filtersLabels.startDatePlaceholder",
    defaultMessage: "Start Date",
  },
  endDatePlaceholder: {
    id: "projectList.components.filtersLabels.endDatePlaceholder",
    defaultMessage: "End Date",
  },
  startDateBoth: {
    id: "projectList.components.filtersLabels.startDate.both",
    defaultMessage: "Start date between {start} - {end}",
  },
  startDateEnd: {
    id: "projectList.components.filtersLabels.startDate.end",
    defaultMessage: "Start date before {end}",
  },
  startDateBegin: {
    id: "projectList.components.filtersLabels.startDate.begin",
    defaultMessage: "Start date after {start}",
  },
  endDate: {
    id: "projectList.components.filtersLabels.endDate",
    defaultMessage: "End date between",
  },
  endDateBoth: {
    id: "projectList.components.filtersLabels.endDate.both",
    defaultMessage: "End date between {start} - {end}",
  },
  endDateEnd: {
    id: "projectList.components.filtersLabels.endDate.end",
    defaultMessage: "End date before {end}",
  },
  endDateBegin: {
    id: "projectList.components.filtersLabels.endDate.begin",
    defaultMessage: "End date after {start}",
  },
  myProjects: {
    id: "projectList.components.filtersLabels.myProjects",
    defaultMessage: "My projects",
  },
  Draft: {
    id: "projectList.components.filtersLabels.draft",
    defaultMessage: "Draft projects",
  },
  InProgress: {
    id: "projectList.components.filtersLabels.inProgress",
    defaultMessage: "In progress projects",
  },
  late: {
    id: "projectList.components.filtersLabels.late",
    defaultMessage: "Late projects",
  },
  Scheduled: {
    id: "projectList.components.filtersLabels.scheduled",
    defaultMessage: "Scheduled projects",
  },
  OnHold: {
    id: "projectList.components.filtersLabels.onHold",
    defaultMessage: "On hold projects",
  },
  Completed: {
    id: "projectList.components.filtersLabels.completed",
    defaultMessage: "Completed projects",
  },
  Archived: {
    id: "projectList.components.filtersLabels.archived",
    defaultMessage: "Archived projects",
  },
  searchText: {
    id: "projectList.components.filtersLabels.searchText",
    defaultMessage: "Name include: {text}",
  },
});

export const replaceUsers = defineMessages({
  titleReplaceUser: {
    id: "projectList.replaceUsers.titleReplaceUser",
    defaultMessage: "replace user in {projectsCount} projects",
  },
  titleActionProgress: {
    id: "projectList.replaceUsers.titleActionProgress",
    defaultMessage: "action progress",
  },
});

export const setReplace = defineMessages({
  titleReplaceUser: {
    id: "projectList.replaceUsers.setReplace.titleReplaceUser",
    defaultMessage: "Select the user you want to replace",
  },
  selectCompany: {
    id: "projectList.replaceUsers.setReplace.selectCompany",
    defaultMessage: "Select a company",
  },
  selectUserReplace: {
    id: "projectList.replaceUsers.setReplace.selectUserReplace",
    defaultMessage: "Select a user to replace",
  },
  loadingResponsibiities: {
    id: "projectList.replaceUsers.setReplace.loadingResponsibiities",
    defaultMessage: "Fetching responsibilities...",
  },
  noUsers: {
    id: "projectList.replaceUsers.setReplace.noUsers",
    defaultMessage:
      "There is no user to replace on these projects, please select other projects or add a team directly to these projects",
  },
  alertNotInProject: {
    id: "projectList.replaceUsers.setReplace.alertNotInProject",
    defaultMessage:
      "{name} is not present in the following projects' team: {projectsList}. {br} The mentionned projects will not be modified.",
  },
  responsibilities: {
    id: "projectList.replaceUsers.setReplace.responsibilities",
    defaultMessage: "Responsibilities",
  },
  selectResponsibilities: {
    id: "projectList.replaceUsers.setReplace.selectResponsibilities",
    defaultMessage:
      "Select for each responsibilities the user who will replace",
  },
  waitingToKnow: {
    id: "projectList.replaceUsers.setReplace.waitingToKnow",
    defaultMessage: "Waiting to know which person you want to replace...",
  },
  selectEachResponsibilities: {
    id: "projectList.replaceUsers.setReplace.selectEachResponsibilities",
    defaultMessage:
      "Select for each responsibilities the user who will replace {name}",
  },
  cancel: {
    id: "projectList.replaceUsers.setReplace.cancel",
    defaultMessage: "CANCEL",
  },
  validate: {
    id: "projectList.replaceUsers.setReplace.validate",
    defaultMessage: "VALIDATE",
  },
  showAll: {
    id: "projectList.replaceUsers.setReplace.showAll",
    defaultMessage: "show all",
  },
  showLess: {
    id: "projectList.replaceUsers.setReplace.showLess",
    defaultMessage: "show less",
  },
});

export const userResponsibility = defineMessages({
  replaceWith: {
    id: "projectList.replaceUsers.userResponsibility.replaceWith",
    defaultMessage: "Replace with",
  },
  leaveUnchanged: {
    id: "projectList.replaceUsers.userResponsibility.leaveUnchanged",
    defaultMessage: "Leave unchanged",
  },
  errorMessage: {
    id: "projectList.replaceUsers.userResponsibility.errorMessage",
    defaultMessage: "Select an user or leave unchanged is required",
  },
  warningMessage: {
    id: "projectList.replaceUsers.userResponsibility.warningMessage",
    defaultMessage: "No other user has this responsibility in the company",
  },
});

export const replaceProcess = defineMessages({
  error: {
    id: "projectList.replaceUsers.replaceProcess.error",
    defaultMessage:
      "{successfullyProcessedCount} out of {totalCount} users replaced.{br}Error, the replacement of {userFirstName} could not be carried out.",
  },
  inProgress: {
    id: "projectList.replaceUsers.replaceProcess.inProgress",
    defaultMessage:
      "You can close this modal and we will notify you when the process is finished.{br}Replaced selected user on {successfullyProcessedCount} out of {totalCount} {totalCount, plural, one {project} other {projects}}",
  },
  completed: {
    id: "projectList.replaceUsers.replaceProcess.completed",
    defaultMessage:
      "Replaced selected user on {successfullyProcessedCount} out of {totalCount} {totalCount, plural, one {project} other {projects}}",
  },
  ok: {
    id: "projectList.replaceUsers.replaceProcess.ok",
    defaultMessage: "OK",
  },
});

export const progressStatus = defineMessages({
  archivedSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.archivedMessage",
    defaultMessage: "The selected project has been archived.",
  },
  deletedSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.deletedMessage",
    defaultMessage: "The selected project has been deleted.",
  },
  putOnHoldSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.putOnHoldMessage",
    defaultMessage: "The selected project has been put on hold.",
  },
  resumedSuccessfullyMessage: {
    id: "projects.projectFolder.projectHeader.resumedMessage",
    defaultMessage: "The selected project has been resumed.",
  },
});
