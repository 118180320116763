import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Header } from "@trace-one/business-components";
import Container from "components/Container";
import { useIntl } from "react-intl";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { navConfig, adminNavConfig } from "services/messages/utils";
import { useLegalMonster } from "../legalMonster";
import {
  selectPermissions,
  selectUserApplications,
  selectUserId,
} from "common/selectors/users.selectors";
import useErrorActions from "hooks/useErrorActions";
import useTrigerCreationPopUp from "utils/useTrigerCreationPopUp";
import { HELP_CENTER_URLS } from "constants/Constants";
import { selectAccessToken } from "common/selectors/oidc.selectors";

export const MainLayout = ({ children }) => {
  const intl = useIntl();
  const { logout } = useReactOidc();
  const userId = useSelector(selectUserId);
  const permissions = useSelector(selectPermissions);
  const accessToken = useSelector(selectAccessToken);
  const availableApps = useSelector(selectUserApplications);
  const { handleCreationPopUp } = useTrigerCreationPopUp();

  useErrorActions();
  useLegalMonster();

  const handleOnLogout = () => {
    if (handleCreationPopUp({ shuldLogout: true })) return;

    logout();
  };

  return (
    <>
      <Header
        homeUrl="/home"
        appName="Project"
        appLogo="project"
        routes={navConfig(intl, permissions)}
        adminSubRoutes={adminNavConfig(intl, permissions)}
        {...(permissions.isAdmin && {
          displayAdminUserAndAccessRoute: true,
          adminUserAndAccessUrl: window.env.UI_ADMIN_USERS_ACCESS,
        })}
        helpLinks={HELP_CENTER_URLS}
        onLogout={() => handleOnLogout()}
        userId={userId}
        isNameShown={false}
        appSwitcherProps={{
          accessToken,
          excludeApps: [50],
          apps: { availableApps },
        }}
      />
      <Container>
        {children}
        <div id="notificationsRoot" />
      </Container>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
};
