import { useEffect, useState } from "react";
import { initialProject, initialTask } from "components/FormSchema/constants";
import { getProject } from "components/Project/API";
import { projectObj, taskObj } from "types/project";
import { projectApi, packagingApi } from "apis";

const useFetchProject = ({
  taskId,
  projectId,
  languageCode,
  ownerCompanyId,
  manufacturedItemId,
}) => {
  const [project, setProject] = useState<projectObj>(initialProject);
  const [taskValue, setTaskValue] = useState<taskObj>(initialTask);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchProject = async () => {
    try {
      const data = await getProject(projectId, languageCode, ownerCompanyId);
      if (data) setProject(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const fetchTask = async () => {
    try {
      const { data } = await projectApi.getTaskByTaskId({
        taskId,
        languageCode,
      });

      const {
        packagingSystemId,
        manufacturedItemId: manufacturedItemIdValue,
        canUserEditPackagingSystem,
      } = data;
      if (packagingSystemId) {
        const { data: packagingSystem } = await packagingApi.getPackaging(
          packagingSystemId
        );
        data.packagingSystemToProductRelations = [
          {
            packagingSystemId: packagingSystemId,
            manufacturedItemId: manufacturedItemIdValue,
          },
        ];
        data.packagingSystems = [
          {
            id: packagingSystemId,
            canUserEditPackagingSystem: canUserEditPackagingSystem,
            ownerCompanyId: packagingSystem.ownerCompanyId,
          },
        ];
      }
      if (data) setTaskValue(data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const { id, packagingSystemToProductRelations } = project;

  const {
    packagingSystems,
    canUserAttachPackagingSystem,
    canUserEditPackagingSystem,
  } = taskValue;

  const packagingSystemRelation = packagingSystemToProductRelations?.find(
    relation => relation.manufacturedItemId === manufacturedItemId
  );

  useEffect(() => {
    fetchProject();
    fetchTask();
  }, [taskId, manufacturedItemId, languageCode, projectId]);

  return {
    id,
    project,
    taskValue,
    setTaskValue,
    isLoading,
    setProject,
    fetchProject,
    fetchTask,
    packagingSystems,
    packagingSystemRelation,
    canUserAttachPackagingSystem,
    canUserEditPackagingSystem,
  };
};

export default useFetchProject;
