import React from "react";
import PropTypes from "prop-types";
import Icons from "./Icons";
import styles from "./Icon.module.less";

const Icon = props => {
  const Component = Icons[props.name];
  return Component ? <Component className={styles.icon} {...props} /> : null;
};

Icon.propTypes = {
  name: PropTypes.string,
};

export default Icon;
