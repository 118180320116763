import { useState, FC } from "react";
import { Resizable } from "react-resizable";

interface IResizeableTitle {
  onResize: () => void;
  width: number;
  onClick: (e: any) => void;
}

const ResizeableTitle: FC<IResizeableTitle> = ({
  onResize,
  width,
  onClick,
  ...restProps
}) => {
  const [allowClick, setAllowClick] = useState(true);
  if (!width) return <th {...restProps} />;

  return (
    <Resizable
      axis="x"
      minConstraints={[30]}
      maxConstraints={[900]}
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false, grid: [3] }}
      onMouseDown={e => setAllowClick(true)}
      onResizeStart={e => setAllowClick(false)}
      onResizeStop={() => setAllowClick(false)}
      onClick={e => {
        allowClick && onClick && onClick(e);
      }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export default ResizeableTitle;
