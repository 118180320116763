/* istanbul ignore file */
import axios from "axios";
import { cacheAdapterEnhancer, Cache } from "axios-extensions";
import { setErrorEvents } from "redux/actions/ErrorEvents";

import { Store } from "../redux";

export class axiosInstance {
  constructor(baseURL) {
    const cacheConfig = new Cache({ maxAge: 1200000, max: 100 });
    const instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      useSilentError: false,
      adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
        enabledByDefault: false,
        cacheFlag: "useCache",
        defaultCache: cacheConfig,
      }),
    });

    instance.interceptors.request.use(config => {
      const { access_token } = Store.getState().oidc;
      config.headers.common["Authorization"] = `Bearer ${access_token}`;

      return config;
    });

    instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response)
          Store.dispatch(setErrorEvents({ hasError: true, error }));
        return Promise.reject(error);
      }
    );

    const minimalInstance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    });

    minimalInstance.interceptors.request.use(config => {
      const { access_token } = Store.getState().oidc;
      config.headers.common["Authorization"] = `Bearer ${access_token}`;

      return config;
    });

    this.instance = instance;

    this.minimalInstance = minimalInstance;

    this.method = {
      GET: "get",
      POST: "post",
      DELETE: "delete",
      PATCH: "patch",
    };
  }
}
