export const activeStepIndex = steps => {
  let index = steps.findIndex(step => step.active);
  if (index === -1) index = 0;

  return index;
};

export const hadleCurrentStep = steps => {
  const index = activeStepIndex(steps);
  const currentStep = steps[index];

  return currentStep;
};

export const handleNextStep = steps => {
  const index = activeStepIndex(steps);
  const nextStep = steps[index + 1];

  return nextStep;
};

export const isToday = date => {
  const dateParameter = !!date ? new Date(date) : new Date();
  const today = new Date();

  return (
    dateParameter.getDate() === today.getDate() &&
    dateParameter.getMonth() === today.getMonth() &&
    dateParameter.getFullYear() === today.getFullYear()
  );
};
