export const selectProjectCreationSavedData = ({
  projectCreation: { projectSavedData },
}) => projectSavedData;

export const selectProjectCreationCurrentData = ({
  projectCreation: { projectCurrentData },
}) => projectCurrentData;

export const selectProjectName = ({
  projectCreation: {
    projectCurrentData: { projectName },
  },
}) => projectName;

export const selectStartDate = ({
  projectCreation: {
    projectCurrentData: { startDate },
  },
}) => startDate;

export const selectTasks = ({
  projectCreation: {
    projectCurrentData: { tasks },
  },
}) => tasks;

export const selectProjectTypeId = ({
  projectCreation: {
    projectCurrentData: { projectTypeId },
  },
}) => projectTypeId;

export const selectActivationStatus = ({
  projectCreation: { isProjectActivated },
}) => isProjectActivated;

export const selectProjectId = ({
  projectCreation: {
    projectCurrentData: { id: projectId },
  },
}) => projectId;

export const selectProjectDescription = ({
  projectCreation: {
    projectCurrentData: { description },
  },
}) => description;

export const selectEndDate = ({
  projectCreation: {
    projectCurrentData: { endDate },
  },
}) => endDate;

export const selectDisplayToasterSaving = ({
  projectCreation: { displayToasterSaving },
}) => displayToasterSaving;

export const selectSelectedProducts = ({
  projectCreation: { selectedProducts },
}) => selectedProducts;

export const selectStepperData = ({ projectUI: { stepperData } }) =>
  stepperData;

export const selectTeamResponsibilities = ({
  projectCreation: {
    team: { responsibilities },
  },
}) => responsibilities;

export const selectSelectedTeam = ({
  projectCreation: {
    team: { selectedTeam },
  },
}) => selectedTeam;

export const selectSelectedSupplier = ({
  projectCreation: {
    team: { selectedSupplier },
  },
}) => selectedSupplier;

export const selectCompanyName = ({
  projectCreation: {
    team: { companyName },
  },
}) => companyName;

export const selectTypes = ({ projectCreation: { types } }) => types;

export const selectedType = ({ projectCreation: { selectedType } }) =>
  selectedType;

export const selectTeam = ({ projectCreation: { team } }) => team;

export const selectProjectTeamSelectedSearch = ({
  projectCreation: { teamSelectedSearch },
}) => teamSelectedSearch;

export const selectIsTeamsLoading = ({
  projectCreation: { isTeamsLoading: isLoading },
}) => isLoading;

export const selectedTypeName = ({ projectCreation: { selectedType } }) =>
  selectedType ? selectedType.name : "";

export const selectIsBryntumLoading = ({ bryntum: { isLoading } }) => isLoading;

export const selectBryntumModalData = ({ bryntum: { bryntumModalData } }) =>
  bryntumModalData;

export const selectUserResponsibilities = ({
  bryntum: { userResponsibilities },
}) => userResponsibilities;

export const selectData = ({ projectCreation: { projectCurrentData } }) =>
  projectCurrentData;

export const selectIsSaved = ({ projectCreation: { isSaved } }) => isSaved;

export const selectIsCreationPopUpOpen = ({
  projectCreation: { isCreationPopUpOpen },
}) => isCreationPopUpOpen;

export const selectIsSaveAsDraftLoading = ({
  projectCreation: { isSaveAsDraftLoading },
}) => isSaveAsDraftLoading;

// general selectors
export const selectLoadingState = ({ projectUI: { isLoading } }) => isLoading;
export const selectWarnFields = ({ projectUI: { warnFields } }) => warnFields;
