import { createContext, useContext, useEffect, useState } from "react";
import { documentApi } from "apis";
import { useLanguagePreference } from "@trace-one/react-components";

type ProjectAppMainType = {
  DMSfileTypes: any;
  DMSfileTypeNames: any;
};
const ProjectAppMainContext = createContext<ProjectAppMainType>(
  {} as ProjectAppMainType
);

export default ProjectAppMainContext;

export const useProjectAppMain = () => {
  const [DMSfileTypes, setDMSfileTypes] = useState([]);
  const [DMSfileTypeNames, setDMSfileTypeNames] = useState([]);
  const languageCode = useLanguagePreference();

  useEffect(() => {
    const getFileTypes = async () => {
      const { data } = await documentApi.getDMSfileTypes({ languageCode });
      const dataName = data.map(f => f.fileTypeName);
      setDMSfileTypes(data);
      setDMSfileTypeNames(dataName);
    };
    getFileTypes();
  }, []);

  return {
    DMSfileTypes,
    DMSfileTypeNames,
  };
};

export const ProjectAppMainContextProvider = ({ children }) => {
  const values = useProjectAppMain();
  return (
    <ProjectAppMainContext.Provider value={values}>
      {children}
    </ProjectAppMainContext.Provider>
  );
};

export const useProjectAppMainContext = () => useContext(ProjectAppMainContext);
