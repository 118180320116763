import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { isEqual, uniqBy } from "lodash";
import { selectedType } from "common/selectors/projects.selectors";
import { selectOwningCompanyId } from "common/selectors/users.selectors";
import {
  Button,
  Title,
  ModalProductSelect,
  ModalContext,
  ModalProductCreate,
  useLanguagePreference,
} from "@trace-one/react-components";
import TableProducts from "../components/Table";
import { selectProductsTrad } from "routes/Projects/defineMessages";
import { productApi, cumdApi, referenceListApi } from "apis";
import { MAX_PRODUCTS_CREATION, portalTypeId } from "constants/index";
import { registerData } from "redux/actions/ProjectCreation";
import { setValidatedProducts } from "redux/actions/FilteredProducts";
import { selectValidatedProducts } from "common/selectors/filteredProducts.selectors";
import { selectProjectCreationCurrentData } from "common/selectors/projects.selectors";
import styles from "./Products.module.less";
import { useHistory } from "react-router-dom";

export const Products = () => {
  const ModalContextProvider = ModalContext.ModalContextProvider;
  const companyId = useSelector(selectOwningCompanyId);
  const validatedProducts = useSelector(selectValidatedProducts);
  const dispatch = useDispatch();
  const languageCode = useLanguagePreference();
  const { isUsingManufacturedItems, isUsingTradeItems } =
    useSelector(selectedType);
  const projectData = useSelector(selectProjectCreationCurrentData);
  const { formatMessage } = useIntl();
  const [openSelectModal, setOpenSelectModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const history = useHistory();
  const isUsingManufacturedItemsOrTradeItems = useMemo(() => {
    return isUsingManufacturedItems || isUsingTradeItems;
  }, [isUsingTradeItems, isUsingManufacturedItems]);

  const selectModalUrl = `/projects/creation/products/products-selection`;
  const createModalUrl = `/projects/creation/products/products-creation`;

  useEffect(() => {
    if (!isEqual(validatedProducts, projectData.selectedProducts))
      dispatch(registerData({ selectedProducts: validatedProducts }));
  }, [validatedProducts]);

  useEffect(() => {
    const mustOpenSelectModal =
      history.location.pathname === selectModalUrl && !openSelectModal;
    setOpenSelectModal(mustOpenSelectModal);

    const mustOpenCreateModal =
      history.location.pathname === createModalUrl && !openCreateModal;
    setOpenCreateModal(mustOpenCreateModal);
  }, [history.location.pathname]);

  const getProductSelectionOpen = () => (
    <ModalContextProvider
      value={{
        onClose: results => {
          if (results.selectedProducts) {
            onSaveProducts(results.selectedProducts);
          }
          history.goBack();
        },
      }}
    >
      <div className={styles.selectProductWrapper}>
        <ModalProductSelect
          isPossibleToAddManufacturedItems={isUsingManufacturedItems}
          isPossibleToAddTradeItems={isUsingManufacturedItemsOrTradeItems}
          includePrivateCompany={true}
          takeCount={1000}
          productAPIRoutes={{
            getTradeItems: productApi.getTradeItems,
            getManufacturedItems: productApi.getManufacturedItems,
            getTradeItemsCollections: productApi.getTradeItemsCollections,
            getBrands: productApi.getBrands,
          }}
          referenceListAPIRoutes={{
            getItemsCollections: referenceListApi.getItemsCollections,
            getReferenceListByName: referenceListApi.getReferenceListByName,
            getCategorylists: referenceListApi.getCategorylists,
            getCategorylistsHierarchyById:
              referenceListApi.getCategorylistsHierarchyById,
            getCategoryitemsCollections:
              referenceListApi.getCategoryitemsCollections,
            fetchReferenceListItems: referenceListApi.fetchReferenceListItems,
          }}
          cumdAPIRoutes={{
            getCompaniesByFilters: cumdApi.getCompaniesByFilters,
          }}
          languageCode={languageCode}
          companyId={companyId}
          portalTypeId={portalTypeId}
          alreadySelectedProducts={validatedProducts.map(item => item.id)}
        />
      </div>
    </ModalContextProvider>
  );

  const getProductCreationOpen = () => (
    <div className={styles.createProjectWrapper}>
      <ModalContextProvider value={{ onClose: () => history.goBack() }}>
        <ModalProductCreate
          maxNumberOfProducts={MAX_PRODUCTS_CREATION}
          companyId={companyId}
          languageCode={languageCode}
          onSaveProducts={onSaveProducts}
          referenceListAPIRoutes={{
            fetchReferenceListItems: referenceListApi.fetchReferenceListItems,
            getCategorylists: referenceListApi.getCategorylists,
            getCategorylistsHierarchyById:
              referenceListApi.getCategorylistsHierarchyById,
            fetchReferenceListItemsByName:
              referenceListApi.getReferenceListByName,
          }}
          productAPIRoutes={{
            createTradeItem: productApi.createTradeItem,
            createManufacturedItem: productApi.createManufacturedItem,
            getBrands: productApi.getBrands,
          }}
          cumdAPIRoutes={{
            searchCompanies: ({ companyIds, applicationTypeIds, params }) =>
              cumdApi.getCompaniesByFilters({
                companyIds,
                applicationTypeIds,
                ...params,
              }),
          }}
          canAddManufItems={isUsingManufacturedItems}
          canAddTradeItems={isUsingManufacturedItemsOrTradeItems}
          shouldHaveErrorMessage={false}
        />
      </ModalContextProvider>
    </div>
  );

  const onSaveProducts = async newProducts => {
    const tradeItemProducts = newProducts.filter(
      product => !product.isManufacturedItem
    );
    const tradeItemsWithSupplier = tradeItemProducts.filter(
      product =>
        Array.isArray(product.manufacturedItemsIds) &&
        product.manufacturedItemsIds.length > 0
    );
    const tradeItemsWithoutSupplier = tradeItemProducts.filter(
      product =>
        !Array.isArray(product.manufacturedItemsIds) ||
        product.manufacturedItemsIds.length === 0
    );

    const manufItemProducts = newProducts.filter(
      product => !!product.isManufacturedItem
    );

    let tradeItemsCorrespondingToManufItems = [];

    if (manufItemProducts.length > 0 && isUsingManufacturedItemsOrTradeItems) {
      const tradeItemsIds = manufItemProducts.map(
        ({ tradeItemId }) => tradeItemId
      );
      const { data } = await productApi.getTradeItemsCollections({
        idCollection: tradeItemsIds,
      });
      tradeItemsCorrespondingToManufItems = [...data];
    }

    let manufItems = [];
    if (tradeItemsWithSupplier.length > 0) {
      const manufItemsIds = tradeItemsWithSupplier.reduce((acc, item) => {
        return [...acc, ...item.manufacturedItemsIds];
      }, []);
      const suppliersId = tradeItemsWithSupplier
        .map(supplier => supplier.supplier)
        .flat();

      const { data: suppliers } = await cumdApi.getCompaniesCollection({
        companyIds: suppliersId,
      });

      manufItems = (
        await productApi.getManufacturedItemsCollections({
          ownerCompanyId: companyId,
          idCollection: manufItemsIds,
        })
      ).data;

      manufItems.forEach(manufItem => {
        const correspondingTradeItem = newProducts.find(newProduct =>
          newProduct.manufacturedItemsIds.includes(manufItem.id)
        );

        if (correspondingTradeItem) {
          manufItem.brandName = correspondingTradeItem.brandName;
          manufItem.categoryName = correspondingTradeItem.categoryName;
          manufItem.category = correspondingTradeItem.category;
          manufItem.itemName = correspondingTradeItem.itemName;
          manufItem.gtin = correspondingTradeItem.gtin;
          manufItem.supplierCompanyName = suppliers.find(
            supplier => manufItem.supplierCompanyId === supplier.companyId
          ).companyDisplayName;
          manufItem.isManufacturedItem = true;
          if (isUsingManufacturedItemsOrTradeItems) {
            tradeItemsCorrespondingToManufItems.push(correspondingTradeItem);
          }
        }
      });
    }

    dispatch(
      setValidatedProducts(
        uniqBy(
          [
            ...tradeItemsWithoutSupplier,
            ...manufItems,
            ...manufItemProducts,
            ...tradeItemsCorrespondingToManufItems,
          ],
          "id"
        )
      )
    );
  };
  return (
    <>
      <Title
        level={2}
        value={formatMessage(selectProductsTrad.sectionTitleProducts)}
        subtitle={formatMessage(selectProductsTrad.sectionSubtitleProducts)}
      />
      <TableProducts />
      <div className={styles.centered}>
        <div className={styles.mr10}>
          <Button
            onClick={() => {
              history.push(createModalUrl, { from: history.location.pathname });
            }}
            type="filled"
            data-test-id="createProductsButton"
            disabled={!isUsingTradeItems && !isUsingManufacturedItems}
          >
            <FormattedMessage
              id="projects.creation.definition.btnCreateProducts"
              defaultMessage="Create new product(s)"
            />
          </Button>
        </div>
        <Button
          type="filled"
          data-test-id="selectProductsButton"
          disabled={!isUsingTradeItems && !isUsingManufacturedItems}
          onClick={() => {
            history.push(selectModalUrl);
          }}
        >
          <FormattedMessage
            id="projects.creation.definition.btnSelectProducts"
            defaultMessage="Select existing product(s)"
          />
        </Button>
        {openSelectModal && getProductSelectionOpen()}
        {openCreateModal && getProductCreationOpen()}
      </div>
    </>
  );
};
