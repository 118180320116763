import React, { FunctionComponent } from "react";
import moment from "moment-business-days";
import { DatePicker } from "@trace-one/design-system";
import classNames from "classnames";
import styles from "./TraceOneWeekPicker.module.less";
import { ISO_STRING_FORMAT } from "../../constants";

interface TraceOneWeekPickerProps {
  onChange?: (value: string) => void;
  value?: any;
  name?: string;
  error?: string;
  disabled?: boolean;
  dataTestId?: string;
  enableFrom?: moment.Moment;
}

const isDisabledDate =
  (enableFrom?: moment.Moment) => (date: moment.Moment) => {
    const type = enableFrom == null ? "startDate" : "endDate";
    let clonedDate;
    switch (type) {
      case "startDate":
        return (
          moment().subtract(1, "d").isAfter(date) ||
          !moment(date).isBusinessDay()
        );
      case "endDate":
      default:
        clonedDate = enableFrom.clone();
        return (
          clonedDate.prevBusinessDay().isAfter(date) ||
          ["6", "0"].includes(moment(date).format("d"))
        );
    }
  };

const TraceOneWeekPicker: FunctionComponent<TraceOneWeekPickerProps> = ({
  onChange,
  value,
  name,
  error,
  disabled,
  dataTestId,
  enableFrom,
}) => {
  return (
    <div className={styles.wrapper}>
      <DatePicker
        allowClear={false}
        data-test-id={dataTestId || `date-picker-${name || null}`}
        // picker="week"
        name={name}
        locale={moment.locale()}
        format={"DD/MM/YYYY"}
        placeholder="dd/mm/yyyy"
        onChange={(value, value2) => {
          onChange?.(moment.utc(value2, "DD/MM/YYYY").toISOString());
        }}
        disabled={disabled}
        disabledDate={isDisabledDate(enableFrom)}
        getPopupContainer={trigger => trigger.parentNode as HTMLElement}
        value={value ? moment.utc(value, ISO_STRING_FORMAT) : null}
        className={classNames({ [styles.error]: !!error })}
      />
      {!!error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

TraceOneWeekPicker.defaultProps = {
  disabled: false,
};

export default TraceOneWeekPicker;
