import { Column, ColumnStore } from "bryntum-gantt";
import moment from "moment";

export default class EndDateColumn extends Column {
  static get type() {
    return "endDateUtc";
  }

  static getTimeFormat(wasRun) {
    if (wasRun) {
      return "DD/MM/YYYY HH:mm";
    } else {
      return "DD/MM/YYYY";
    }
  }

  static get isGanttColumn() {
    return true;
  }
  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  renderer(rowData) {
    const {
      record: {
        originalData: { endDateUtc },
      },
    } = rowData;
    const {
      column: {
        data: { wasRun },
      },
    } = rowData;

    if (!endDateUtc) return null;
    return moment(endDateUtc).format(EndDateColumn.getTimeFormat(wasRun));
  }
}

// Extending EndDateColumn to create EndDateColumnCreation
export class EndDateColumnCreation extends EndDateColumn {
  static get type() {
    return "endDateUtcCreation";
  }

  renderer({
    record: {
      originalData: { endDateUtc },
    },
  }) {
    if (!endDateUtc) return null;
    return moment(endDateUtc).format("DD/MM/YYYY");
  }
}

// Register both column types with the ColumnStore
ColumnStore.registerColumnType(EndDateColumn);
ColumnStore.registerColumnType(EndDateColumnCreation);
