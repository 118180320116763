export const initialProject = {
  packagingSystems: [],
  id: "",
  packagingSystemToProductRelations: [],
  canUserAttachPackagingSystem: false,
};

const formBlocksValue = {
  dataAsJson: "",
  jsonSchema: "",
  uiSchema: "",
  templateBlockId: "",
  auxiliaryData: {
    packagingSystemId: "",
  },
};

export const initialTask = {
  manufacturedItemId: "",
  form: {
    formBlocks: [formBlocksValue],
  },
  id: "",
  packagingSystemId: "",
};

export const TASK_STATUS_COMPLETED = "Completed";
