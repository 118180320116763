import {
  SET_GANTT_VIEW_DISPLAY_TASK,
  SET_GANTT_VIEW_EXPAND,
  SET_GANTT_VIEW_DISPLAY_CRITICAL_PATH,
  SET_GANTT_VIEW_IS_LOCKED_COLLAPSED,
  SET_GANTT_VIEW_IS_NORMAL_COLLAPSED,
  SET_GANTT_VIEW_SLIDER_POSITION,
  SET_GANTT_VIEW_DISPLAY_FUTURE_TASKS,
  SET_GANTT_VIEW_DISPLAY_PROJECTIONS,
  SET_GANNT_VIEW_FILTER_POPOVER,
} from "../../constants";

export const initialState = {
  displayMyTasks: false,
  expanded: false,
  showCriticalPath: false,
  isLockedCollapsed: false,
  isNormalCollapsed: false,
  sliderPosition: "50%",
  isActivePopOver: false,
  showProjections: true,
};

export default function ganttViewReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_GANTT_VIEW_DISPLAY_TASK:
      return {
        ...state,
        displayMyTasks: payload,
      };
    case SET_GANTT_VIEW_EXPAND:
      return {
        ...state,
        expanded: payload,
      };
    case SET_GANTT_VIEW_DISPLAY_CRITICAL_PATH:
      return {
        ...state,
        showCriticalPath: payload,
      };
    case SET_GANTT_VIEW_DISPLAY_FUTURE_TASKS:
      return {
        ...state,
        showFutureTasks: payload,
      };
    case SET_GANTT_VIEW_DISPLAY_PROJECTIONS:
      return {
        ...state,
        showProjections: payload,
      };
    case SET_GANNT_VIEW_FILTER_POPOVER:
      return {
        ...state,
        isActivePopOver: payload,
      };
    case SET_GANTT_VIEW_IS_LOCKED_COLLAPSED:
      return {
        ...state,
        isLockedCollapsed: payload.isLockedCollapsed,
        isNormalCollapsed: false,
        sliderPosition: payload.sliderPosition,
      };
    case SET_GANTT_VIEW_IS_NORMAL_COLLAPSED:
      return {
        ...state,
        isLockedCollapsed: false,
        isNormalCollapsed: payload.isNormalCollapsed,
        sliderPosition: payload.sliderPosition,
      };
    case SET_GANTT_VIEW_SLIDER_POSITION:
      return {
        ...state,
        sliderPosition: payload,
      };
    default:
      return state;
  }
}
