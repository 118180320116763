import { cloneDeep } from "lodash";
import {
  REGISTER_DATA,
  SET_TEAM,
  SET_IS_TEAMS_LOADING,
  SET_IS_SAVE_AS_DRAFT_LOADING,
  SET_SELECTED_TYPE,
  RESET_PROJECT_CREATION_STATE,
  SET_PROJECT_CREATION_TEAM_SELECTED_SEARCH,
  RECEIVED_TYPES_FOR_SELECT,
  SET_DUPLICATE_PROJECT_CREATION,
  SET_DISPLAY_TOASTER_SAVING,
  SET_IS_DIRTY,
  SET_IS_SAVED,
  SET_TEAM_RETAILER,
  SET_TEAM_SUPPLIER,
  SET_SELECTED_TEAM,
  REMOVE_SELECTED_TEAM,
  SET_SELECTED_SUPPLIER,
  REMOVE_SELECTED_SUPPLIER,
  UPDATE_SELECTED_TEAM,
  UPDATE_SELECTED_SUPPLIER,
  SET_CREATION_POP_UP,
} from "../../constants";
import { companyActivityType } from "constants/projects";

const projectDatainitialState = {
  selectedProducts: [],
  tasks: [],
  projectName: "",
  projectTypeId: "",
  description: "",
  responsibilities: [],
  projectCode: "",
};

export const initialState = {
  projectSavedData: { ...projectDatainitialState },
  projectCurrentData: { ...projectDatainitialState },
  selectedProducts: [],
  teamSelectedSearch: [],
  team: {
    supplierResponsibilities: [],
    supplierTeams: [],
    responsibilities: [],
    companyName: "",
    selectedTeam: [],
    selectedSupplier: [],
  },
  isProjectActivated: true,
  isTeamsLoading: false,
  types: [],
  selectedType: {},
  isSaveAsDraftLoading: false,
  isSaved: true,
  displayToasterSaving: true,
  isCreationPopUpOpen: false,
};

export default function projectCreationReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_DATA:
      const data = {
        ...state.projectCurrentData,
        ...action.payload,
      };
      return {
        ...state,
        projectCurrentData: data,
        isSaved: false,
      };
    case SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.payload,
      };
    case SET_IS_TEAMS_LOADING:
      return {
        ...state,
        isTeamsLoading: action.payload,
      };
    case SET_DISPLAY_TOASTER_SAVING:
      return {
        ...state,
        displayToasterSaving: action.payload,
      };
    case SET_DUPLICATE_PROJECT_CREATION:
      return {
        ...state,
        projectSavedData: cloneDeep(state.projectCurrentData),
      };
    case SET_TEAM:
      return {
        ...state,
        team: {
          ...state.team,
          ...action.payload,
        },
        isSaved: false,
      };
    case SET_TEAM_RETAILER:
      return {
        ...state,
        team: {
          ...state.team,
          responsibilities: [
            ...state.team.responsibilities.filter(
              f => f.companyActivityCode !== companyActivityType.retailer
            ),
            ...action.payload,
          ],
        },
        isSaved: false,
      };
    case SET_TEAM_SUPPLIER:
      const supplierResponsibilities = action.payload.reduce(
        (acc, { responsibilities }) => [...acc, ...responsibilities],
        []
      );
      return {
        ...state,
        team: {
          ...state.team,
          supplierTeams: action.payload,
          responsibilities: [
            ...state.team.responsibilities.filter(
              ({ companyActivityCode }) =>
                companyActivityCode !== companyActivityType.supplier
            ),
            ...supplierResponsibilities,
          ],
        },
        isSaved: false,
      };
    case UPDATE_SELECTED_TEAM:
      return {
        ...state,
        team: {
          ...state.team,
          selectedTeam: action.payload,
        },
      };
    case SET_SELECTED_TEAM:
      const selectedTeam = [
        ...state.team.selectedTeam.filter(({ id }) => id !== action.payload.id),
        { id: action.payload.id, value: action.payload.value },
      ];

      return {
        ...state,
        team: {
          ...state.team,
          selectedTeam,
        },
      };
    case REMOVE_SELECTED_TEAM:
      return {
        ...state,
        team: {
          ...state.team,
          selectedTeam: state.team.selectedTeam.filter(
            ({ id }) => id !== action.payload
          ),
        },
      };
    case UPDATE_SELECTED_SUPPLIER:
      return {
        ...state,
        team: {
          ...state.team,
          selectedSupplier: action.payload,
        },
      };
    case SET_SELECTED_SUPPLIER:
      const selectedSupplier = [
        ...state.team.selectedSupplier.filter(
          ({ supplierCompanyId, responsibilityId }) =>
            supplierCompanyId === action.payload.supplierCompanyId &&
            responsibilityId !== action.payload.responsibilityId
        ),
        {
          responsibilityId: action.payload.responsibilityId,
          userIdSelected: action.payload.userIdSelected,
          supplierCompanyId: action.payload.supplierCompanyId,
        },
      ];

      return {
        ...state,
        team: {
          ...state.team,
          selectedSupplier,
        },
      };
    case REMOVE_SELECTED_SUPPLIER:
      return {
        ...state,
        team: {
          ...state.team,
          selectedSupplier: state.team.selectedSupplier.filter(
            ({ id }) => id !== action.payload
          ),
        },
      };
    case SET_IS_SAVE_AS_DRAFT_LOADING:
      return {
        ...state,
        isSaveAsDraftLoading: action.payload,
      };
    case SET_SELECTED_TYPE:
      return {
        ...state,
        selectedType: action.payload,
        team: initialState.team,
      };
    case SET_IS_SAVED:
      return {
        ...state,
        isSaved: action.payload,
      };
    case RECEIVED_TYPES_FOR_SELECT:
      return {
        ...state,
        isLoading: false,
        types: action.payload,
      };
    case RESET_PROJECT_CREATION_STATE:
      return {
        ...initialState,
      };
    case SET_PROJECT_CREATION_TEAM_SELECTED_SEARCH:
      return {
        ...state,
        teamSelectedSearch: action.payload,
      };
    case SET_CREATION_POP_UP:
      return {
        ...state,
        isCreationPopUpOpen: action.payload,
      };

    default:
      return state;
  }
}
