/* istanbul ignore file */
import { DurationColumn, ColumnStore } from "bryntum-gantt";

export default class EstimatedDurationColumn extends DurationColumn {
  static get type() {
    return "estimatedDuration";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  renderer({ record: { originalData, isProjectMilestone } }) {
    const { estimatedDuration } = originalData;

    if (
      isProjectMilestone ||
      Number.isNaN(Number.parseFloat(estimatedDuration))
    )
      return "";

    return estimatedDuration < 1 ? "<1" : Math.round(estimatedDuration);
  }
}

ColumnStore.registerColumnType(EstimatedDurationColumn);
