import {
  SET_MANAGEMENT_PROJECT_TYPES,
  SET_MANAGEMENT_LOADING,
  RESET_MANAGEMENT,
  SET_MANAGEMENT_ERROR,
} from "../../constants";

export const initialState = {
  projectTypes: [],
  isLoading: false,
  error: "",
  toggled: null,
};

export default function managementReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MANAGEMENT_PROJECT_TYPES:
      return {
        ...state,
        projectTypes: payload,
      };
    case SET_MANAGEMENT_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case RESET_MANAGEMENT:
      return {
        ...initialState,
      };
    case SET_MANAGEMENT_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}
