import {
  SET_PROJECT_TYPES,
  SET_LANGUAGES,
  SET_PROJECTTYPES_LOADING,
  SET_FILE_BASE64,
  SET_IMPORT_SUCCESS,
  RESET_TRANSLATION,
  SET_TRANSLATION_ERROR,
  SET_IMPORT_DETAILS,
  RESET_FILE,
  SET_IS_DEPLOYING_IMPORT,
} from "../../constants";

export const initialState = {
  languages: [],
  projectTypes: [],
  isLoading: false,
  isDeploying: false,
  base64File: "",
  isImportSuccess: null,
  projectTypeName: "",
  fileName: "",
  error: "",
};

export default function translationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PROJECT_TYPES:
      return {
        ...state,
        projectTypes: payload,
      };
    case SET_LANGUAGES:
      return {
        ...state,
        languages: payload,
      };
    case SET_PROJECTTYPES_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_FILE_BASE64:
      return {
        ...state,
        base64File: payload.fileBase64,
        projectTypeName: payload.projectTypeName,
      };
    case SET_IMPORT_SUCCESS:
      return {
        ...state,
        isImportSuccess: payload,
      };
    case SET_IMPORT_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case SET_IS_DEPLOYING_IMPORT:
      return {
        ...state,
        isDeploying: payload,
      };
    case RESET_TRANSLATION:
      return {
        ...initialState,
      };
    case RESET_FILE:
      return {
        ...state,
        base64File: "",
        projectTypeName: "",
        fileName: "",
        isImportSuccess: false,
      };
    case SET_TRANSLATION_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}
