import { useEffect, useState } from "react";
import { useDebounce } from "@trace-one/react-components";
import { cumdApi } from "apis";

const useGetUsersOnSearch = responsibility => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newUsers, setNewUsers] = useState<any[]>([]);

  const debouncedSearchValue = useDebounce(searchValue, 300);

  const getUsersOnSearch = async userName => {
    if (responsibility) {
      try {
        setIsLoading(true);
        const {
          data: { users },
        } = await cumdApi.getUsersByFilters({
          userName,
          responsibilityId: responsibility,
        } as any);

        const transformedUsers = users.map(user => {
          const { userId, userFirstName, userLastName, responsibilities } =
            user;

          return {
            text: `${userFirstName} ${userLastName}`,
            name: `${userFirstName} ${userLastName}`,
            value: userId,
            responsibilities,
          };
        });
        setNewUsers(transformedUsers);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else setNewUsers([]);
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      getUsersOnSearch(debouncedSearchValue);
    } else {
      getUsersOnSearch("");
    }
  }, [debouncedSearchValue]);

  return { isLoading, newUsers, setSearchValue };
};

export default useGetUsersOnSearch;
