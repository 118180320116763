import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectTeam } from "common/selectors/projects.selectors";
import { suppliersTrans } from "routes/Projects/defineMessages";
import styles from "./Suppliers.module.less";

export const Suppliers = () => {
  const { formatMessage } = useIntl();
  const { supplierTeams } = useSelector(selectTeam);
  const teamsCount = supplierTeams.length;
  const { numberOfSuppliers, noUser, productLinked } = suppliersTrans;

  if (!supplierTeams.length) return null;

  return (
    <div className={styles.wrapper} data-test-id="suppliers-summary">
      <div className={styles.subtitle}>
        <p>
          <span className={styles.bluetext}>{`${teamsCount} `}</span>
          {formatMessage(numberOfSuppliers, {
            s: teamsCount > 1 && "S",
          })}
        </p>
      </div>
      <div className={styles.content}>
        {supplierTeams.map(
          ({
            supplierCompanyName,
            count,
            supplierCompanyId,
            responsibilities,
          }) => (
            <div key={supplierCompanyId}>
              <ul className={styles.bluetext}>
                <li>{supplierCompanyName}</li>
              </ul>
              <p>
                {`${formatMessage(productLinked)}: `}
                <span className={styles.bluetext}>{count}</span>
              </p>

              {responsibilities.map(({ id, name, userIdSelected, users }) => (
                <p key={id}>
                  {`${name}: `}
                  <span className={styles.bluetext}>
                    {userIdSelected
                      ? users.find(u => u.value === userIdSelected).text
                      : formatMessage(noUser)}
                  </span>
                </p>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};
