import { Prompt } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectData,
  selectIsCreationPopUpOpen,
} from "common/selectors/projects.selectors";
import { setCreationPopUp } from "redux/actions/ProjectCreation";
import Popup from "../Popup";

export const PromptPopup = ({
  saveAsDraft,
  nextLocationUrl,
  setNextLocationUrl,
  handleRedirect,
}) => {
  const isCreationPopUpOpen = useSelector(selectIsCreationPopUpOpen);
  const projectData = useSelector(selectData);
  const dispatch = useDispatch();

  const {
    projectName,
    projectTypeId,
    description,
    id: projectId,
  } = projectData;

  const isPermittedRoute = ({ pathname }) => {
    const permittedRoutes = [
      "/projects/creation/definition",
      "/projects/creation/products",
      "/projects/creation/products/products-selection",
      "/projects/creation/products/products-creation",
      "/projects/creation/planning",
      "/projects/creation/team",
      "/projects/creation/summary",
      "projects/projects-list",
      "/projects/creation/team/select-team",
      `/projects/projectfolder/${projectId}`,
      `/projects/projectfolder/${projectId}/tasks`,
    ];

    return permittedRoutes.includes(pathname);
  };

  return (
    <>
      <Prompt
        when={true}
        message={nextLocation => {
          if (!isPermittedRoute(nextLocation)) {
            if (
              !isCreationPopUpOpen &&
              (!!projectName || !!projectTypeId || !!description)
            ) {
              setNextLocationUrl(nextLocation);
              dispatch(setCreationPopUp(true));
              return false;
            }
          }
          dispatch(setCreationPopUp(false));
          return true;
        }}
      />
      <Popup
        projectId={projectId}
        showPop={isCreationPopUpOpen}
        onCloseNoSave={() => handleRedirect(nextLocationUrl)}
        onClose={() => {
          dispatch(setCreationPopUp(false));
          setNextLocationUrl("");
        }}
        onCloseSave={() => saveAsDraft()}
      />
    </>
  );
};
