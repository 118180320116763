export const selectDisplayMyTasks = ({ ganttView: { displayMyTasks } }) =>
  displayMyTasks;

export const selectExpanded = ({ ganttView: { expanded } }) => expanded;

export const selectShowCriticalPath = ({ ganttView: { showCriticalPath } }) =>
  showCriticalPath;

export const selectShowFutureTasks = ({ ganttView: { showFutureTasks } }) =>
  showFutureTasks;

export const selectShowProjections = ({ ganttView: { showProjections } }) =>
  showProjections;

export const selectIsLockedCollpased = ({ ganttView: { isLockedCollapsed } }) =>
  isLockedCollapsed;

export const selectIsNormalCollpased = ({ ganttView: { isNormalCollapsed } }) =>
  isNormalCollapsed;

export const selectSliderPosition = ({ ganttView: { sliderPosition } }) =>
  sliderPosition;

export const selectGanttPopOver = ({ ganttView: { isActivePopOver } }) =>
  isActivePopOver;
