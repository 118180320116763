import { useLayoutEffect } from "react";
import { useAssociatePackaging } from "hooks";
import { useHistory } from "react-router";

const useCreateAssociatePackaging = ({
  id,
  taskId,
  setIsSaving,
  packagingSystems,
  fetchProject,
  fetchTask,
  triggerProductRefresh,
  manufacturedItemId,
  formBlocks,
  packagingSystemToProductRelations,
  setLoading = e => {},
}) => {
  const history = useHistory();
  const { templateBlockId, dataAsJson } = formBlocks[0];
  const { associatePackaging, temporaryAssociatePackaging } =
    useAssociatePackaging({
      selectedProducts: [manufacturedItemId as string],
      taskId,
      projectData: {
        packagingSystems,
        id,
        packagingSystemToProductRelations,
        templateBlockId,
        dataAsJson,
      },
    });

  const handleOnAssociate = async packagingSystemIds => {
    try {
      await associatePackaging(packagingSystemIds);
      Promise.resolve(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnTemporaryAssociate = async packagingSystemIds => {
    try {
      await temporaryAssociatePackaging(packagingSystemIds);
      fetchTask();
      Promise.resolve(true);
    } catch (error) {
      console.error(error);
    }
  };

  const refreshProject = async (params = { unmounted: true }) => {
    const { unmounted } = params;
    try {
      if (unmounted) {
        setLoading(true);
        setIsSaving(true);
        triggerProductRefresh();
        await Promise.all([fetchProject(), fetchTask()]).then(() => {
          history.push(`/projects/projectfolder/${id}/tasks/${taskId}`);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  useLayoutEffect(() => {
    return () => triggerProductRefresh();
  }, []);

  return {
    handleOnAssociate,
    handleOnTemporaryAssociate,
    refreshProject,
  };
};

export default useCreateAssociatePackaging;
