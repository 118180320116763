import {
  SET_USER,
  SET_USER_LOADING,
  SET_USER_APPLICATIONS,
  SET_USER_LANGUAGE_PREFERENCE,
  SET_USER_PERMISSIONS,
  SET_COMPANY_LOGO,
  SET_COMPANY_ACTIVITY_ID,
} from "../../constants";

export const initialState = {
  isLoading: false,
  userLanguagePreference: "",
  applications: [],
  userId: "",
  userFirstName: "",
  userLastName: "",
  userPhotoUrl: "",
  userJobTitle: "",
  userCompanyName: "",
  owningCompanyId: "",
  companyLogoUrl: "",
  permissions: "",
  userLogin: "",
  userCompanyActivityName: "",
};

export default function userReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_USER_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_USER_LANGUAGE_PREFERENCE:
      return {
        ...state,
        userLanguagePreference: payload,
      };
    case SET_USER:
      return {
        ...state,
        ...payload,
      };
    case SET_USER_APPLICATIONS:
      return {
        ...state,
        applications: payload,
      };
    case SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: payload,
      };
    case SET_COMPANY_LOGO:
      return {
        ...state,
        companyLogoUrl: payload,
      };
    case SET_COMPANY_ACTIVITY_ID:
      return {
        ...state,
        companyActivityId: payload,
      };
    default:
      return state;
  }
}
