import { getParsedData } from "utils/general";

export const removePropertiesKeyFromSchema = ({ jsonSchema, newUiSchema }) => {
  const schema = getParsedData(jsonSchema);

  Object.keys(newUiSchema).forEach(key => {
    if (
      newUiSchema[key].hasOwnProperty("document") &&
      newUiSchema[key].document === null
    )
      delete schema.properties[key];
  });
  return schema;
};

export const addDiscussionIdToLockedFiles = files =>
  files.reduce((acc, { status, links, ...rest }) => {
    if (status === "Locked") {
      const discussionIDObject = links.find(
        link => link.objectTypeName === "Discussion"
      );
      acc.push({
        discussionId: discussionIDObject?.objectId,
        status: status,
        ...rest,
      });
    }
    return acc;
  }, []);
