import React from "react";
import classNames from "classnames";
import { Tooltip } from "@trace-one/react-components";
import { TeamMembers } from "components/TeamMembers/TeamMembers";
import { teamsList } from "routes/Projects/defineMessages";
import styles from "./TeamsList.module.less";

export const TeamsList = ({
  team: {
    id,
    name: teamName,
    brandName,
    responsibilities,
    isShared,
    productCategoryName,
  },
  intl: { formatMessage },
  onSelect,
  isSelected,
}) => {
  const lenghtLabelToTruncate = 25;
  const total = responsibilities.length;
  const totalMembers = `${total} ${formatMessage(teamsList.members, {
    s: total > 1 ? "s" : "",
  })}`;

  const formatLabel = label => {
    if (!label) return "-";
    if (label.length > lenghtLabelToTruncate) {
      const tooltip = (
        <>
          <Tooltip title={label}>{`${label.substring(
            0,
            lenghtLabelToTruncate - 1
          )}...`}</Tooltip>
        </>
      );
      return tooltip;
    }
    return label;
  };

  return (
    <div
      className={classNames(styles.listItem, {
        [styles.selected]: isSelected,
      })}
      onClick={() => onSelect(id)}
    >
      <div>
        <span className={styles.teamName}>{formatLabel(teamName)}</span>
        <span>
          {totalMembers}
          {isShared && (
            <span className={styles.shared}>
              {formatMessage(teamsList.shared)}
            </span>
          )}
        </span>
      </div>
      <div>
        <span className={styles.title}>
          {formatMessage(teamsList.brandLabel)}
        </span>
        <span>{formatLabel(brandName)}</span>
      </div>
      <div>
        <span className={styles.title}>
          {formatMessage(teamsList.categoryLabel)}
        </span>
        <span>{formatLabel(productCategoryName)}</span>
      </div>
      <div>
        <TeamMembers team={responsibilities} />
      </div>
    </div>
  );
};
