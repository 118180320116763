import { useEffect } from "react";

let IS_INTERCOM_INITIATED = false;

export default function useIntercom(legalMonsterRef) {
  useEffect(() => {
    const handleEnableIntercom = () => {
      if (window.Intercom) {
        window.intercomSettings = {
          app_id: window.env.INTERCOM_APP_ID,
          user_id: legalMonsterRef.current.userId,
          language_override: legalMonsterRef.current.userLanguage,
        };
        window.Intercom("boot", window.intercomSettings);
      }
    };
    const handleDisableIntercom = () => {
      if (window.Intercom) {
        window.Intercom("shutdown");
        delete window.intercomSettings;
      }
    };

    if (!IS_INTERCOM_INITIATED) {
      window.addEventListener(
        "legalmonster.cookie.marketing.accepted",
        handleEnableIntercom
      );
      window.addEventListener(
        "legalmonster.cookie.marketing.rejected",
        handleDisableIntercom
      );
      IS_INTERCOM_INITIATED = true;
    }
  }, []);
}
