import React, { useEffect, useState, useMemo, useContext } from "react";
import Fuse from "fuse.js";

import {
  Title,
  Search,
  Spinner,
  Button,
  ModalContext,
} from "@trace-one/react-components";
import { TeamsList } from "./TeamsList/TeamsList";
import { selectTeam } from "routes/Projects/defineMessages";
import styles from "./SelectTeam.module.less";
import { fetchTeamsModal } from "components/Project/API";

const ModalCtxObject = ModalContext.ModalContext;

export const SelectTeam = ({
  intl,
  companyId,
  userId,
  languageCode,
  owningCompanyId,
}) => {
  const [isTeamsLoading, setIsTeamsLoading] = useState(true);
  const [teams, setTeams] = useState([]);
  const modalCtx = useContext(ModalCtxObject);
  const { formatMessage } = intl;
  const [teamSelected, setTeamSelected] = useState(null);
  const [teamFiltered, setTeamFiltered] = useState([]);
  const [searchText, setSearchText] = useState("");
  const setSelectTeam = teamId => {
    const responsibilities = teams.find(
      ({ id }) => id === teamId
    )?.responsibilities;
    modalCtx.onClose({ responsibilities });
  };
  const options = {
    keys: [
      "name",
      "brandName",
      "productCategoryName",
      "responsibilities.userFirstName",
      "responsibilities.userLastName",
    ],
  };
  const fuse = useMemo(() => new Fuse(teams, options), [teams]);

  const onSearch = () => {
    if (searchText) {
      const result = fuse.search(searchText);
      const resulMapped = result.map(r => r.item);
      setTeamFiltered(resulMapped);
    } else {
      setTeamFiltered(teams);
    }
  };
  useEffect(() => {
    if (companyId) {
      setIsTeamsLoading(true);
      (async () => {
        const result = await fetchTeamsModal({
          userId,
          languageCode,
          companyId,
          owningCompanyId,
        });
        setTeams(result.teams);
        setIsTeamsLoading(false);
      })();
    }
  }, [companyId, languageCode, userId, owningCompanyId]);

  useEffect(() => {
    if (teams.length > 0) setTeamFiltered(teams);
  }, [teams]);

  if (isTeamsLoading) return <Spinner />;
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Title
          level={1}
          value={formatMessage(selectTeam.title)}
          subtitle={formatMessage(selectTeam.subtitle)}
        />
        <Search
          placeholder={formatMessage(selectTeam.searchPlaceholder)}
          dataTestId="selectTeamSearch"
          buttonText={formatMessage(selectTeam.search)}
          value={searchText}
          onChange={e => setSearchText(e.currentTarget.value)}
          onSearch={onSearch}
          onClearSearch={() => {
            setSearchText("");
            setTeamFiltered(teams);
          }}
        />
      </div>
      <div className={styles.teamslist}>
        {teamFiltered.map(team => (
          <TeamsList
            intl={intl}
            onSelect={setTeamSelected}
            key={team.id}
            team={team}
            isSelected={team.id === teamSelected}
          />
        ))}
      </div>
      <div className={styles.footer}>
        <Button
          type="secondary"
          onClick={modalCtx.onClose}
          data-test-id="cancelTeamSearch"
        >
          {formatMessage(selectTeam.cancelBtn)}
        </Button>
        <Button
          disabled={!teamSelected}
          onClick={() => setSelectTeam(teamSelected)}
          data-test-id="selectTeamSearch"
        >
          {formatMessage(selectTeam.selectBtn)}
        </Button>
      </div>
    </div>
  );
};
