import {
  SET_TREE_PRODUCT_CATEGORIES,
  SET_TRADE_ITEMS,
  SET_IS_TRADE_ITEMS_LOADING,
  REMOVE_FILTER,
  HANDLE_CHANGE_FILTER,
  SET_PRODUCT_BRANDS,
  SET_IS_PRODUCT_BRANDS_LOADING,
  SET_PRODUCT_NET_CONTENT_UNITS,
  SET_IS_PRODUCT_NET_CONTENT_UNITS_LOADING,
  SET_IS_TREE_CATEGORIES_LOADING,
  SET_IS_SELECTED_PRODUCTS_LOADING,
  SET_SELECTED_PRODUCTS,
  SET_VALIDATED_PRODUCTS,
  REMOVE_VALIDATED_PRODUCT,
  SET_CATEGORIES_FOR_VALIDATED_PRODCTS,
  SET_SELECTED_FILTERS,
  SET_SELECTED_PRODUCTS_CURRENT_AND_TOTAL,
  SET_FILTERED_PRODUCTS_PAGE_SIZE,
  RESET_PROJECT_CREATION_STATE,
  SET_SUPPLIERS,
} from "../../constants";
import { uniqBy } from "lodash";

export const initialState = {
  pageSize: 10,
  currentAndTotal: {
    current: 10,
    totalCount: 0,
  },
  isSelectedProductsLoading: false,
  isSetTradeItemsLoading: false,
  selectedProducts: [],
  validatedProducts: [],
  isLoading: false,
  tradeItems: [],
  categoriesForValidatedProducts: [],
  filters: {
    productNetContentUnitId: "",
    productNetContent: "",
    productEan: "",
    productBrandId: "",
    productCategoryId: "",
    productName: "",
    productWithSuppliers: "yes",
  },
  productBrands: [],
  isProductsBrandsLoading: false,
  productCategories: [],
  isProductCategoriesLoading: false,
  productNetContentUnits: [],
  isProductNetContentUnitsLoading: false,
  treeProductCategories: [],
  isTreeProductCategoriesLoading: false,
  selectedFilters: [],
  suppliers: [],
};

export default function filteredProductsReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_SUPPLIERS:
      return {
        ...state,
        suppliers: payload.map(({ companyId, companyDisplayName }) => ({
          value: companyId,
          text: companyDisplayName,
        })),
      };
    case SET_TRADE_ITEMS:
      return {
        ...state,
        tradeItems: payload,
      };
    case SET_IS_TRADE_ITEMS_LOADING:
      return {
        ...state,
        isSetTradeItemsLoading: payload,
      };
    case SET_IS_PRODUCT_BRANDS_LOADING:
      return {
        ...state,
        isProductsBrandsLoading: payload,
      };
    case SET_IS_TREE_CATEGORIES_LOADING:
      return {
        ...state,
        isTreeProductCategoriesLoading: payload,
      };
    case SET_IS_PRODUCT_NET_CONTENT_UNITS_LOADING:
      return {
        ...state,
        isProductNetContentUnitsLoading: payload,
      };
    case SET_PRODUCT_BRANDS:
      return {
        ...state,
        productBrands: payload,
      };
    case SET_PRODUCT_NET_CONTENT_UNITS:
      return {
        ...state,
        productNetContentUnits: payload,
      };
    case REMOVE_FILTER:
      return {
        ...state,
        filters: payload,
      };
    case SET_TREE_PRODUCT_CATEGORIES:
      return {
        ...state,
        treeProductCategories: uniqBy(
          [...state.treeProductCategories, ...payload],
          "id"
        ),
      };
    case HANDLE_CHANGE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    case SET_IS_SELECTED_PRODUCTS_LOADING:
      return {
        ...state,
        isSelectedProductsLoading: payload,
      };
    case SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: payload,
      };
    case SET_VALIDATED_PRODUCTS:
      return {
        ...state,
        validatedProducts: uniqBy(
          [...state.validatedProducts, ...payload],
          "id"
        ),
      };
    case REMOVE_VALIDATED_PRODUCT:
      return {
        ...state,
        validatedProducts: state.validatedProducts.filter(
          vp => vp.id !== payload
        ),
      };
    case SET_CATEGORIES_FOR_VALIDATED_PRODCTS:
      return {
        ...state,
        categoriesForValidatedProducts: payload,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: payload,
      };
    case SET_SELECTED_PRODUCTS_CURRENT_AND_TOTAL:
      return {
        ...state,
        currentAndTotal: payload,
      };
    case SET_FILTERED_PRODUCTS_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload,
      };
    case RESET_PROJECT_CREATION_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
