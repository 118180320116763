import { useIntl } from "react-intl";
import { associatePackagingModal } from "./messages";
import { modalAssociateLabels } from "./types";

const useModalAssociateLabels = (props?: modalAssociateLabels) => {
  const { formatMessage } = useIntl();
  const definedProps = props || {};
  const { selectedProducts = [] } = definedProps;

  const getLabels = () => {
    return {
      title: formatMessage(associatePackagingModal.title),
      titleElementCount: formatMessage(
        associatePackagingModal.titleElementCount,
        {
          selected: selectedProducts.length,
        }
      ),
      subTitle: formatMessage(associatePackagingModal.subTitle),
      emptyTitle: formatMessage(associatePackagingModal.emptyTitle),
      noResults: formatMessage(associatePackagingModal.noResults),
      search: formatMessage(associatePackagingModal.search),
      searchPlaceholder: formatMessage(
        associatePackagingModal.searchPlaceholder
      ),
      secondaryButton: formatMessage(associatePackagingModal.secondaryButton),
      primaryButton: formatMessage(associatePackagingModal.primaryButton),
    };
  };
  return { getLabels };
};

export default useModalAssociateLabels;
