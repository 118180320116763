import "bryntum-gantt/gantt.material.min.css";
import { Gantt, LocaleManager } from "bryntum-gantt";
import React, { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import "./BryntumComponent.less";
import "./locale/gantt.locale.Fr.js";
import "./EstimatedDurationColumn";
import "./StatusColumn";
import "./TaskPriorityColumn";
import "./InitialEndDateColumn";
import "./EstimatedEndDateColumn";
import "./EndDateColumn";
import "./TimeTrackedColumn";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDisplayMyTasks,
  selectShowFutureTasks,
  selectExpanded,
  selectIsLockedCollpased,
  selectIsNormalCollpased,
  selectShowCriticalPath,
  selectSliderPosition,
} from "common/selectors/ganttView.selector";
import { createBryntumFilter } from "./Utils";
import {
  setExpanded,
  setIsLockedCollapsed,
  setIsNormalCollapsed,
  setSliderPosition,
} from "redux/actions/GanttView";
import { debounce } from "lodash";
import { useMutationObserver } from "./useMutationObserver";

export const BryntumProject = ({
  config,
  onGanttReady,
  ganttRef,
  isGanttViewFiltered,
}) => {
  const elementRef = useRef();
  const displayMyTasks = useSelector(selectDisplayMyTasks);
  const displayFutureTasks = useSelector(selectShowFutureTasks);
  const expanded = useSelector(selectExpanded);
  const showCriticalPath = useSelector(selectShowCriticalPath);
  const isLockedCollapsed = useSelector(selectIsLockedCollpased);
  const isNormalCollapsed = useSelector(selectIsNormalCollpased);
  const sliderPosition = useSelector(selectSliderPosition);

  const dispatch = useDispatch();

  const applyLocale = code => {
    switch (code) {
      case "fr-FR":
      case "fr":
        LocaleManager.applyLocale("Fr");
        break;
      default:
        LocaleManager.applyLocale("En");
        break;
    }
  };

  const intl = useIntl();
  const observerConfig = { attributes: true };

  const debouncedMutationObserverCallback = debounce(async () => {
    const parentElement = document.querySelector(
      ".b-grid-body-container"
    )?.clientWidth;

    const targetElement = document.querySelector(
      "[id ^= b-gantt-] [id $= -lockedSubgrid-header]"
    )?.clientWidth;

    const slider = document.querySelector(".b-grid-splitter").clientWidth;

    dispatch(
      setSliderPosition(
        `${Math.floor((targetElement / (parentElement - slider)) * 100)}%`
      )
    );
  }, 250);

  const observer = useMutationObserver(debouncedMutationObserverCallback);

  const showExpandView = () => {
    if (!!expanded) ganttRef.current.expandAll();
    else {
      const childrens = config.project.children[0]?.children;
      if (childrens && childrens.length > 0) {
        childrens.forEach(({ id }) => ganttRef.current.collapse(id));
      } else ganttRef.current.collapseAll();
    }
  };
  const showMyTasksView = () => {
    const taskStore = ganttRef.current.taskStore;
    taskStore.clearFilters();
    taskStore.filterBy(
      createBryntumFilter(displayMyTasks, displayFutureTasks, config)
    );
    if (displayMyTasks || displayFutureTasks) {
      ganttRef.current.expandAll();
      dispatch(setExpanded(true));
    } else {
      if (!!expanded) {
        // This will keep expanded the tasks as they were already
        ganttRef.current.expandAll();
      }
    }
  };
  const showCriticalPathView = () => {
    ganttRef.current.features.criticalPaths.disabled = !showCriticalPath;
  };

  const toggleCollapse = e => {
    if (e === "locked") {
      dispatch(
        setIsLockedCollapsed({
          isLockedCollapsed: true,
          sliderPosition: "0",
        })
      );
      return;
    }
    dispatch(
      setIsNormalCollapsed({ isNormalCollapsed: true, sliderPosition: "100%" })
    );
  };

  const toggleExpand = e => {
    if (e === "locked") {
      dispatch(
        setIsLockedCollapsed({
          isLockedCollapsed: false,
          sliderPosition: "50%",
        })
      );
      return;
    }
    dispatch(
      setIsNormalCollapsed({ isNormalCollapsed: false, sliderPosition: "50%" })
    );
  };

  const setGantViewPossition = () => {
    ganttRef.current.subGrids.locked.collapsed = isLockedCollapsed;
    ganttRef.current.subGrids.normal.collapsed = isNormalCollapsed;
    if (sliderPosition) {
      ganttRef.current.subGrids.locked.width = sliderPosition;
    }
  };

  useEffect(() => {
    applyLocale(intl.locale);
    if (config) {
      setData();
    }
    return () => {
      if (ganttRef.current) {
        ganttRef.current.destroy();
      }
    };
  }, [config]);

  const setData = () => {
    if (ganttRef.current) {
      ganttRef.current.destroy();
    }
    ganttRef.current = new Gantt({
      ...config,
      listeners: {
        ...config.listeners,
        subgridcollapse: e => {
          toggleCollapse(e.region);
        },
        subgridexpand: e => {
          toggleExpand(e.region);
        },
      },
      appendTo: elementRef.current,
    });
    ganttRef.current.project.on({
      load: t => {
        onGanttReady();
        if (isGanttViewFiltered) {
          showExpandView();
          showMyTasksView();
          showCriticalPathView();
          setGantViewPossition();

          const targetNode = document.querySelector(
            "[id ^= b-gantt-] [id $= -lockedSubgrid-header]"
          );

          if (targetNode) {
            observer.observe(targetNode, observerConfig);
          }
        }
      },
    });
  };

  return <div className="project-gantt" ref={elementRef} />;
};

BryntumProject.defaultProps = { isGanttViewFiltered: false };
