import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLocalDate } from "utils/DateUtils";
import { isCurrentLocation } from "../../helpers";
import styles from "./SubtitleContent.module.less";
import {
  selectedTypeName,
  selectData,
} from "common/selectors/projects.selectors";
import { Tooltip } from "@trace-one/design-system";
import { FormattedMessage } from "react-intl";

const SubtitleContent = () => {
  const data = useSelector(selectData);
  const selectedType = useSelector(selectedTypeName);
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <FormattedMessage
        id="projects.creation.components.subtitleContent.name"
        defaultMessage="Name: "
      />
      <Tooltip text={data && data.projectName} placement="top" size="large">
        <span data-test-id="project-name-header" className={styles.projectName}>
          {data && data.projectName}
        </span>
      </Tooltip>
      <FormattedMessage
        id="projects.creation.components.subtitleContent.type"
        defaultMessage=" / Type: "
      />
      <Tooltip text={selectedType} placement="bottom" size="large">
        <span data-test-id="type-header" className={styles.selectedType}>
          {selectedType}
        </span>
      </Tooltip>
      {!isCurrentLocation(history, "projects/creation/definition") &&
        !isCurrentLocation(history, "projects/creation/planning") && (
          <>
            <FormattedMessage
              id="projects.creation.components.subtitleContent.startDate"
              defaultMessage=" / Start date: "
            />
            <span data-test-id="startdate-header" className={styles.startDate}>
              {data && data.startDate && getLocalDate(data.startDate)}
            </span>
          </>
        )}
    </div>
  );
};

export default SubtitleContent;
