import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "@trace-one/react-components";
import {
  Illustration,
  Icon,
  toaster as toasterService,
} from "@trace-one/design-system";
import styles from "./Popup.module.less";
import { projectApi } from "apis";
import { projectHeader } from "routes/Projects/defineMessages";

const Popup = ({ onClose, onCloseNoSave, onCloseSave, showPop, projectId }) => {
  const { formatMessage } = useIntl();
  if (!showPop) return null;
  return (
    <div className={styles.wrapper}>
      <div className={styles.popup}>
        <span
          data-test-id="popup-close"
          className={styles.closePop}
          onClick={onClose}
        >
          <Icon name="close" />
        </span>
        <span className={styles.folder}>
          <Illustration name="non-conformity" color="grey" />
        </span>
        <h1 className={styles.title}>
          <FormattedMessage
            id="projects.creation.components.popup.title"
            defaultMessage="Before you leave"
          />
        </h1>
        <div className={styles.text}>
          <div>
            <FormattedMessage
              id="projects.creation.components.popup.text1"
              defaultMessage="The project has been automatically saved as draft."
            />
          </div>
          <div>
            <FormattedMessage
              id="projects.creation.components.popup.text2"
              defaultMessage="By leaving now, you can either keep it as draft or cancel the creation and delete it."
            />
          </div>
          <div>
            <FormattedMessage
              id="projects.creation.components.popup.text3"
              defaultMessage="All the products created are saved."
            />
          </div>
        </div>
        <div className={styles.btnWrapper}>
          <Button
            type="secondary"
            data-test-id="keepAsDraft"
            className={styles.btnBottom}
            onClick={() => onCloseSave()}
          >
            <FormattedMessage
              id="projects.creation.components.popup.keepAsDraft"
              defaultMessage="Keep as draft"
            />
          </Button>
          <Button
            data-test-id="deleteProject"
            className={styles.btnBottom}
            onClick={async () => {
              if (projectId) await projectApi.deleteProjectById({ projectId });
              toasterService.open({
                type: "confirmation",
                size: "small",
                duration: 5,
                description: formatMessage(
                  projectHeader.deletedSuccessfullyMessage
                ),
                placement: "topRight",
              });
              onCloseNoSave();
            }}
          >
            <FormattedMessage
              id="projects.creation.components.popup.deleteProjectButton"
              defaultMessage="Delete project"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  onClose: PropTypes.func,
  onCloseNoSave: PropTypes.func,
  onCloseSave: PropTypes.func,
  showPop: PropTypes.bool,
  isSaving: PropTypes.bool,
};

export default Popup;
