import moment from "moment";
import {
  portalTypeId,
  REGISTER_DATA,
  RESET_PROJECT,
  SET_IS_TEAMS_LOADING,
  SET_IS_SAVE_AS_DRAFT_LOADING,
  SET_TEAM,
  SET_IS_SAVED,
  RESET_PROJECT_CREATION_STATE,
  SET_DUPLICATE_PROJECT_CREATION,
  SET_DISPLAY_TOASTER_SAVING,
  SET_PROJECT_CREATION_TEAM_SELECTED_SEARCH,
  SET_TEAM_RETAILER,
  SET_TEAM_SUPPLIER,
  SET_SELECTED_TEAM,
  REMOVE_SELECTED_TEAM,
  SET_SELECTED_SUPPLIER,
  REMOVE_SELECTED_SUPPLIER,
  UPDATE_SELECTED_TEAM,
  UPDATE_SELECTED_SUPPLIER,
  SET_CREATION_POP_UP,
} from "../../../constants";
import { companyActivityType } from "constants/projects";
import { getProductIds } from "services/messages/utils";
import { CREATION_STEP } from "utils/ProjectUtils";
import { setWarningFields } from "../ProjectUI";
import { cumdApi, projectApi, referenceListApi } from "apis";
import { isEmpty } from "lodash";

export const registerData = data => {
  return {
    type: REGISTER_DATA,
    payload: data,
  };
};

export const setIsSaved = payload => {
  return {
    type: SET_IS_SAVED,
    payload,
  };
};

export const setDuplicateProjectCreation = payload => {
  return {
    type: SET_DUPLICATE_PROJECT_CREATION,
    payload,
  };
};

export const setIsTeamLoading = payload => {
  return {
    type: SET_IS_TEAMS_LOADING,
    payload,
  };
};

export const setDisplayToasterSaving = payload => {
  return {
    type: SET_DISPLAY_TOASTER_SAVING,
    payload,
  };
};

export const setProjectCreationTeamSelectedSearch = data => {
  return {
    type: SET_PROJECT_CREATION_TEAM_SELECTED_SEARCH,
    payload: data,
  };
};

export const setCreationPopUp = payload => ({
  type: SET_CREATION_POP_UP,
  payload: payload,
});

export const fetchTeams = ({
  projectTypeId,
  languageCode,
  companyId,
  selectedTeam = [],
}) => {
  return async dispatch => {
    dispatch(setIsTeamLoading(true));
    try {
      const { data: companies } = await cumdApi.getCompaniesCollection({
        companyIds: [companyId],
      });

      const { data: dataResponsibilities } =
        await projectApi.getResponsibilitiesByProjectTypeId({
          projectTypeId,
        });

      const { data: refResponsibilities } =
        await referenceListApi.getReferenceListByName({
          referenceListName: "Team_Member_Responsibility",
          languageCode,
          portalTypeId,
          companyId,
        });

      const { companyDisplayName: companyName } = companies[0];

      const retailerResponsibilities =
        dataResponsibilities.responsibilities.find(
          ({ companyActivityCode }) =>
            companyActivityCode === companyActivityType.retailer
        )?.responsibilityIds || [];

      const supplierResponsibilities =
        dataResponsibilities.responsibilities
          .find(
            ({ companyActivityCode }) =>
              companyActivityCode === companyActivityType.supplier
          )
          ?.responsibilityIds.map(responsibilityId => ({
            id: responsibilityId,
            companyActivityCode: companyActivityType.supplier,
            name: refResponsibilities.find(
              refResponsibility => refResponsibility.id === responsibilityId
            ).text,
          })) || [];

      const getUsersByResponbility = async (responsibilityId, companyId) => {
        const {
          data: { users },
        } = await cumdApi.getUsersByResponsibilityId({
          responsibilityId,
          companyId,
        });

        return users.map(({ userId, userFirstName, userLastName }) => ({
          value: userId,
          text: `${userFirstName} ${userLastName}`,
        }));
      };

      const buildReponsibilities = retailerResponsibilities.map(
        async responsibilityId => {
          const result = {
            id: responsibilityId,
            companyActivityCode: companyActivityType.retailer,
            companyId,
            name: refResponsibilities.find(
              refResponsibility => refResponsibility.id === responsibilityId
            ).text,
            users: await getUsersByResponbility(responsibilityId, companyId),
          };
          return {
            ...result,
          };
        }
      );

      const responsibilitiesArr = await Promise.all(buildReponsibilities);

      // Check if a user has been already added to the redux store
      const responsibilities = responsibilitiesArr.reduce((acc, curr) => {
        const { id } = curr;
        const userIdSelected = selectedTeam.find(
          ({ id: selectedId }) => id === selectedId
        )?.value;

        // If a user is already selected, add the userId to the responsibilities array
        acc.push({ ...curr, ...(userIdSelected && { userIdSelected }) });
        return acc;
      }, []);

      dispatch(
        setTeam({
          companyName,
          responsibilities,
          supplierResponsibilities,
        })
      );
      dispatch(setIsTeamLoading(false));
    } catch (error) {
      console.error(error);
      setIsTeamLoading(false);
    }
  };
};

export const fetchTeamSuppliers = manufacturedItems => {
  return async (dispatch, getState) => {
    dispatch(setIsTeamLoading(true));
    try {
      const { supplierResponsibilities, responsibilities, selectedSupplier } =
        getState().projectCreation.team;

      if (!supplierResponsibilities.length) {
        dispatch(setTeamSupplier([]));
        dispatch(setIsTeamLoading(false));
        return null;
      }

      const getUsersByResponbility = async (responsibilityId, companyId) => {
        const {
          data: { users },
        } = await cumdApi.getUsersByResponsibilityId({
          responsibilityId,
          companyId,
        });

        return users.map(({ userId, userFirstName, userLastName }) => ({
          value: userId,
          text: `${userFirstName} ${userLastName}`,
        }));
      };

      const buildReponsibilities = manufacturedItems.reduce(
        async (accp, current, index, array) => {
          let acc = await Promise.resolve(accp);
          if (
            !acc.some(s => s.supplierCompanyId === current.supplierCompanyId)
          ) {
            acc = [
              ...acc,
              {
                supplierCompanyId: current.supplierCompanyId,
                supplierCompanyName: current.supplierCompanyName,
                count: array.filter(
                  f => f.supplierCompanyId === current.supplierCompanyId
                ).length,
                responsibilities: responsibilities.some(
                  s => s.companyId === current.supplierCompanyId
                )
                  ? responsibilities.filter(
                      f => f.companyId === current.supplierCompanyId
                    )
                  : await Promise.all(
                      supplierResponsibilities.map(async r => {
                        const buildresponse = {
                          ...r,
                          companyId: current.supplierCompanyId,
                          users: await getUsersByResponbility(
                            r.id,
                            current.supplierCompanyId
                          ),
                        };
                        if (buildresponse.users.length === 1) {
                          return {
                            ...buildresponse,
                            userIdSelected: buildresponse.users[0].value,
                          };
                        }
                        return buildresponse;
                      })
                    ),
              },
            ];
          }
          return acc;
        },
        Promise.resolve([])
      );

      const supplierTeamsArr = await Promise.resolve(buildReponsibilities);

      // Check if a user has been already added to the redux store
      const supplierTeams = supplierTeamsArr.reduce((acc, curr) => {
        const { responsibilities, supplierCompanyId } = curr;
        const newResponsibilities = responsibilities.map(responsibility => {
          const { id: responsibilityId } = responsibility;
          const userIdSelected = selectedSupplier.find(
            supplier =>
              supplier.supplierCompanyId === supplierCompanyId &&
              supplier.responsibilityId === responsibilityId
          )?.userIdSelected;

          return {
            ...responsibility,
            ...(userIdSelected && { userIdSelected }),
          };
        });
        acc.push({
          ...curr,
          responsibilities: newResponsibilities,
        });

        return acc;
      }, []);

      dispatch(setTeamSupplier(supplierTeams));
      dispatch(setIsTeamLoading(false));
    } catch (error) {
      console.error(error);
      setIsTeamLoading(false);
    }
  };
};

export const updateSelectedTeam = data => {
  const payload = data.reduce((acc, curr) => {
    const { userIdSelected, id } = curr;
    if (userIdSelected) acc.push({ id, value: userIdSelected });
    return acc;
  }, []);

  return {
    type: UPDATE_SELECTED_TEAM,
    payload,
  };
};

export const setSelectedTeam = payload => {
  return {
    type: SET_SELECTED_TEAM,
    payload,
  };
};

export const removeSelectedTeam = payload => {
  return {
    type: REMOVE_SELECTED_TEAM,
    payload,
  };
};

export const updateSelectedSupplier = ({ supplierTeams }) => {
  let payload = [];

  if (!isEmpty(supplierTeams)) {
    const selectedUsers = supplierTeams.map(
      ({ supplierCompanyId, responsibilities }) => {
        return responsibilities.reduce(
          (accum, { id: responsibilityId, userIdSelected }) => {
            if (userIdSelected)
              accum.push({
                supplierCompanyId,
                responsibilityId,
                ...(userIdSelected && { userIdSelected }),
              });

            return accum;
          },
          []
        );
      }
    );

    payload = [].concat(...selectedUsers);
  }

  return {
    type: UPDATE_SELECTED_SUPPLIER,
    payload,
  };
};

export const setSelectedSupplier = payload => {
  return {
    type: SET_SELECTED_SUPPLIER,
    payload,
  };
};

export const removeSelectedSupplier = payload => {
  return {
    type: REMOVE_SELECTED_SUPPLIER,
    payload,
  };
};

export const setTeam = payload => {
  return {
    type: SET_TEAM,
    payload,
  };
};

export const setTeamRetailer = payload => {
  return {
    type: SET_TEAM_RETAILER,
    payload,
  };
};

export const setTeamSupplier = payload => {
  return {
    type: SET_TEAM_SUPPLIER,
    payload,
  };
};

export const resetProject = () => {
  return {
    type: RESET_PROJECT,
  };
};

export const setIsSaveAsDraftLoading = payload => {
  return {
    type: SET_IS_SAVE_AS_DRAFT_LOADING,
    payload,
  };
};

export const saveDraft = ({
  projectData: {
    projectName,
    projectTypeId,
    description,
    id,
    startDate,
    responsibilities,
    selectedProducts,
    tasks,
    projectCode,
  },
  ownerUserId,
  launch,
  companyId,
  step,
}) => {
  const [manufacturedItemIds, tradeItemIds] = getProductIds(selectedProducts);
  const teamResponsibilities = responsibilities ?? [];

  return async (dispatch, getState) => {
    try {
      dispatch(setIsSaveAsDraftLoading(true));
      dispatch(setIsSaved(false));

      let projectId = getState().projectCreation.projectSavedData.id;

      if (step === CREATION_STEP.definition) {
        const { data: project } = id
          ? await projectApi.patchProjectById({
              projectId: id,
              name: projectName,
              projectTypeId,
              description,
              projectCode,
            })
          : await projectApi.postDraft({
              ownerCompanyId: companyId,
              name: projectName,
              projectTypeId,
              description,
              ownerUserId,
              projectCode,
            });
        projectId = id || project.id;
      }

      if (tradeItemIds && step === CREATION_STEP.products) {
        await projectApi.patchProductsByTradeItemId({
          projectId,
          tradeItemIds,
        });
      }

      if (manufacturedItemIds && step === CREATION_STEP.products) {
        await projectApi.patchProductsByManufacturedItemId({
          projectId,
          manufacturedItemIds,
        });
      }

      if (startDate && step === CREATION_STEP.planning) {
        await projectApi.patchDatesByProjectId({
          projectId,
          startDateUtc: moment.utc(startDate).startOf("day"),
          tasks,
        });
      }

      if (
        teamResponsibilities.some(
          responsibility => responsibility.userIdSelected
        ) &&
        step === CREATION_STEP.team
      ) {
        setTimeout(async () => {
          await projectApi.patchTeamByProjectId({
            projectId,
            team: teamResponsibilities
              .filter(responsibility => responsibility.userIdSelected)
              .map(responsibility => ({
                companyId: responsibility.companyId,
                companyActivityCode: responsibility.companyActivityCode,
                userId: responsibility.userIdSelected,
                responsibilityId: responsibility.id,
              })),
            thirdPartyCompanies: [],
          });
        }, 100);
      }

      if (launch) {
        await projectApi.postActivateProject({ projectId });
      }

      dispatch(registerData({ id: projectId }));
      dispatch(setIsSaved(true));
      dispatch(setWarningFields([]));
      dispatch(setIsSaveAsDraftLoading(false));

      return projectId;
    } catch (error) {
      dispatch(setIsSaveAsDraftLoading(false));
      console.error(error);
    }
  };
};

export const resetProjectCreation = () => ({
  type: RESET_PROJECT_CREATION_STATE,
});
