import {
  SET_RETAILERS,
  SET_RETAILER_ID,
  SET_VALID_WORKFLOW,
  SET_VERIFYING_WORKFLOW,
  SET_FILENAME,
  SET_DEPLOYING_WORKFLOW,
  SET_DEPLOYED_WORKFLOW,
  SET_BASE64_FILE,
  SET_HAS_ERROR,
  RESET,
  SET_RETAILER,
} from "../../constants";

export const initialState = {
  retailers: [],
  isValidWorkFlow: null,
  isVerifyingWorkFlow: false,
  fileName: "",
  isDeployingWorkFlow: false,
  isDeployedWorkFlow: false,
  errors: {
    hasError: false,
    errorMessages: [],
  },
  base64File: "",
};

export default function deploymentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_RETAILERS:
      return {
        ...state,
        retailers: payload.companies,
      };
    case SET_RETAILER_ID:
      return {
        ...state,
        retailerId: payload,
      };
    case SET_VALID_WORKFLOW:
      return {
        ...state,
        isValidWorkFlow: payload,
      };
    case SET_VERIFYING_WORKFLOW:
      return {
        ...state,
        isVerifyingWorkFlow: payload,
      };
    case SET_FILENAME:
      return {
        ...state,
        fileName: payload,
      };
    case SET_DEPLOYED_WORKFLOW:
      return {
        ...state,
        isDeployedWorkFlow: payload,
      };
    case SET_DEPLOYING_WORKFLOW:
      return {
        ...state,
        isDeployingWorkFlow: payload,
      };
    case SET_BASE64_FILE:
      return {
        ...state,
        base64File: payload,
      };
    case SET_HAS_ERROR:
      return {
        ...state,
        errors: {
          hasError: payload.hasError,
          errorMessages: payload.errorMessages,
        },
      };
    case SET_RETAILER:
      return {
        ...state,
        retailer: payload,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
