import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toaster as toasterService } from "@trace-one/design-system";
import { selectErrorEvents } from "common/selectors/errorEvents.selectors";
import { selectUserLanguagePreference } from "common/selectors/users.selectors";
import { resetErrorsEvents } from "redux/actions/ErrorEvents";
import {
  ERROR_CODE_DUPICATE_NAME,
  ERROR_CODE_EXTENSION,
  ERROR_CODE_EXTENSION_VALIDATED,
  ERROR_CODE_TASK_UNACCESABLE,
} from "../constants";
import { useIntl } from "react-intl";
import {
  errorMessages,
  messages,
} from "../components/ComponentDesign/defineMessages";

const skipNotificationFor = [];

const useErrorActions = () => {
  const intl = useIntl();
  const history = useHistory();
  const errors = useSelector(selectErrorEvents);
  const languageCode = useSelector(selectUserLanguagePreference);
  const dispatch = useDispatch();

  const redirectTo = () => {
    if (languageCode === "fr-FR") {
      window.open(
        "http://help.traceone.com/fr/articles/6975829-formats-de-fichiers-supportes-pour-chargement-et-telechargement-a-travers-l-application"
      );
    } else {
      window.open(
        "http://help.traceone.com/en/articles/6975829-supported-file-formats-for-uploading-and-downloading-through-the-app"
      );
    }
  };

  const getNotification = ({ error }) => {
    const url = error?.config?.url;
    const errorCode = error?.response?.data?.errorCode;
    const status = error?.response?.status;
    const errorMessageRaw = error?.response?.data?.error || "";
    const errorMessage =
      errorMessageRaw.length < 200
        ? errorMessageRaw
        : intl.formatMessage(errorMessages.somethingWentWrong);

    let description;
    let actions;
    const message = `Error ${status}`;
    switch (errorCode) {
      case ERROR_CODE_EXTENSION: {
        description = intl.formatMessage(messages.incorrectExtension);
        actions = [
          {
            text: intl.formatMessage(messages.withSupportedFIleFormat),
            onClick: () => redirectTo(),
          },
        ];
        break;
      }
      case ERROR_CODE_EXTENSION_VALIDATED: {
        description = intl.formatMessage(messages.alreadyValidated);
        break;
      }
      case ERROR_CODE_TASK_UNACCESABLE: {
        description = intl.formatMessage(messages.errorTaskUnaccesable);
        break;
      }
      default: {
        description =
          errorMessage.length !== 0
            ? errorMessage
            : intl.formatMessage(errorMessages.errorOccured);
        break;
      }
    }

    const messageObject = {
      message,
      description,
      actions,
    };

    if (status === 404 || !window.navigator.onLine) {
      messageObject.message = intl.formatMessage(
        errorMessages.errorWithConnection
      );
      messageObject.description = `${intl.formatMessage(
        errorMessages.connectionError
      )} ${intl.formatMessage(errorMessages.refreshPage)}`;
    }

    if (skipNotificationFor.includes(url)) return;

    toasterService.open({
      type: "alert",
      duration: 5,
      ...messageObject,
    });
  };

  const getErrorActions = ({ error }) => {
    const status = error?.response?.status;
    const noErrorStatus = error?.response?.config.showNoErrorWhen?.status;
    const refreshPageWhen = error?.response?.config?.refreshPageWhen?.status;

    if (!!refreshPageWhen && refreshPageWhen === status) {
      window.location.reload();
    }

    if (!!noErrorStatus && noErrorStatus === status) return true;
    if (
      status !== 409 &&
      status < 500 &&
      !error.response.config.useSilentError &&
      status !== 403 &&
      error.response.data.errorCode !== ERROR_CODE_DUPICATE_NAME
    ) {
      history.push(`/error/${status}`);
    } else {
      if (error.response.data.errorCode === ERROR_CODE_DUPICATE_NAME) return;
      getNotification({ error });
    }
  };

  useEffect(() => {
    if (errors.hasError) {
      getErrorActions({ error: errors.error });
      dispatch(resetErrorsEvents());
    }
  }, [errors]);
};

export default useErrorActions;
