import { cloneDeep } from "lodash";
import {
  SET_DOCUMENT_TASKDETAIL,
  SET_DOCUMENT_LABELS,
  SET_TASKS_CALENDAR,
} from "../../constants";

export const initialState = {
  calendar: {},
  documentLabels: [],
  document: undefined,
};

export default function tasksReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_TASKS_CALENDAR:
      return {
        ...state,
        calendar: payload,
      };
    case SET_DOCUMENT_LABELS:
      const labelIndex = state.documentLabels.findIndex(labelElement => {
        return labelElement.itemId === payload.itemId;
      });
      if (labelIndex === -1) {
        return {
          ...state,
          documentLabels: [...state.documentLabels, payload],
        };
      } else {
        let documentLabelsClone = cloneDeep(state.documentLabels);
        documentLabelsClone[labelIndex].label = payload.label;
        return {
          ...state,
          documentLabels: documentLabelsClone,
        };
      }
    case SET_DOCUMENT_TASKDETAIL:
      return {
        ...state,
        document: payload,
      };
    default:
      return state;
  }
}
