import {
  SET_REGISTER_SAVE,
  INC_SUCCESS_TOTAL,
  SET_TOTAL_CREATED,
  UPDATE_TOTAL_CREATED,
  RESET_CREATE_PRODUCTS,
  STORE_GTIN,
} from "../../constants";

export const initialState = {
  hasSaved: false,
  totalCreated: undefined,
  successTotal: 0,
  successIds: [],
  gtins: [],
};

export default function createProductsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_REGISTER_SAVE:
      return {
        ...state,
        hasSaved: payload.hasSaved,
        totalCreated: payload.totalCreated
          ? payload.totalCreated
          : state.totalCreated,
      };
    case INC_SUCCESS_TOTAL:
      return {
        ...state,
        successTotal: (state.successTotal += 1),
        successIds: [...state.successIds, ...[payload]],
      };
    case SET_TOTAL_CREATED:
      return {
        ...state,
        totalCreated: payload,
      };
    case STORE_GTIN:
      return {
        ...state,
        gtins: {
          ...state.gtins,
          ...payload,
        },
      };
    case UPDATE_TOTAL_CREATED:
      const newTotal = payload
        ? (state.totalCreated += 1)
        : (state.totalCreated -= 1);

      return {
        ...state,
        totalCreated: newTotal,
      };
    case RESET_CREATE_PRODUCTS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
