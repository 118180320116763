/* istanbul ignore file */
import axios from "axios";
import { axiosInstance } from "./axiosInstance";
import { config } from "../services/messages/config";
import { isEmpty } from "lodash";
export const instanceCUMD = axios.create({ baseUrl: config.CUMD_ENDPOINT });

export default class CUMD extends axiosInstance {
  getUserById = ({ userId }) => this.instance.get(`/api/users/${userId}`);

  getUserApplications = () => this.instance.get("/api/users/applications/v2");

  getUsersByFilters = ({ userIds, ...filters }) =>
    this.instance.post(
      "/api/users/filters",
      {
        userIds,
      },
      {
        params: { ...filters },
      }
    );

  getUsersByPublicFilters = ({ userIds, ...filters }) =>
    this.instance.post(
      "/api/users/public/filters",
      {
        userIds,
      },
      {
        params: { ...filters },
      }
    );

  getUsersCollection = ({ userIds }) => {
    if (userIds?.length === 0) {
      //check if userIds is empty, because api not allow that
      return Promise.resolve({ data: [] });
    }
    return this.instance.post("/api/users/collections", { userIds });
  };

  getCompanyById = ({ companyId }) =>
    this.instance.get(`/api/companies/${companyId}`);

  getRolesByCompanyId = ({ companyId, applicationTypeId, languageCode }) =>
    this.instance.get(`/api/companies/${companyId}/roles`, {
      params: {
        applicationTypeId,
        languageCode,
      },
    });

  getUsersWithProjectManagerRole = ({ companyId }) =>
    this.instance.get(
      `api/roles/b8ad04d0-e62b-414c-bc1a-d0a29986b866/companies/${companyId}/users`
    );

  patchUserLanguage = ({
    userId,
    userIdType = "global_user_id",
    newLanguagePreference,
  }) =>
    this.instance.patch(`/api/users/${userId}/language`, {
      userIdType,
      newLanguagePreference,
    });

  getCompaniesByFilters = ({ companyIds, applicationTypeIds, ...filters }) =>
    this.instance.post(
      "/api/companies/filters",
      {
        companyIds,
        applicationTypeIds,
      },
      {
        params: {
          ...filters,
          Take: 1000,
          IncludePrivate: true,
        },
      }
    );

  getCompanies = ({ companyActivityId }) =>
    this.instance.post(
      "/api/companies/filters",
      {},
      {
        params: {
          CompanyActivityId: companyActivityId,
          IncludePrivate: false,
        },
      }
    );

  getCompaniesCollection = ({ companyIds }) =>
    this.instance.post("/api/companies/collections", {
      companyIds,
    });

  getPublicCompanies = ({ companyIds }) =>
    this.instance.post("/api/companies/public/filters", {
      companyIds,
    });

  getPublicCompany = async ({ companyId }) => {
    const { data } = this.instance.post("/api/companies/public/filters", {
      companyIds: [companyId],
    });

    return !isEmpty(data) ? data[0] : {};
  };

  getUsersByResponsibilityId = ({ responsibilityId, companyId }) =>
    this.instance.get(`/api/Responsibilities/${responsibilityId}/users`, {
      params: { companyId },
    });

  getPublicUsers = ({ userIds }) => {
    if (userIds?.length === 0) {
      //check if userIds is empty, because api not allow that

      return Promise.resolve({ data: [] });
    }
    return this.instance.post(`/api/users/public/filters`, {
      userIds,
    });
  };

  getPublicUser = async ({ userId }) => {
    const { data } = await this.instance.post(`/api/users/public/filters`, {
      userIds: [userId],
    });

    return !isEmpty(data) ? data[0] : {};
  };

  getResponsibilitiesByUserId = ({ id }) =>
    this.instance.get(`/api/users/${id}/responsibilities`);
}
