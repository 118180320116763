import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import {
  setTeamRetailer,
  setProjectCreationTeamSelectedSearch,
  updateSelectedTeam,
} from "redux/actions/ProjectCreation";
import {
  selectTeam,
  selectProjectTeamSelectedSearch,
} from "common/selectors/projects.selectors";
import {
  Title,
  Button,
  ModalContext,
  Modal,
  useLanguagePreference,
} from "@trace-one/react-components";
import { SelectTeam } from "routes/Projects/components/SelectTeam/SelectTeam";
import { TeamsRetail } from "routes/Projects/components/TeamsRetail";
import { companyActivityType } from "constants/projects";
import {
  retailSubtitle,
  intParticipants,
} from "routes/Projects/defineMessages";
import styles from "./RetailParticipants.module.less";
import {
  selectOwningCompanyId,
  selectUserId,
} from "common/selectors/users.selectors";
import { useHistory } from "react-router-dom";

export const RetailParticipants = () => {
  const ModalContextProvider = ModalContext.ModalContextProvider;
  const languageCode = useLanguagePreference();
  const userId = useSelector(selectUserId);
  const owningCompanyId = useSelector(selectOwningCompanyId);
  const { companyName, responsibilities } = useSelector(selectTeam);
  const teamSelectedSearch = useSelector(selectProjectTeamSelectedSearch);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [openSelecTeamtModal, setOpenSelectTeamModal] = useState(false);
  const selectTeamModalUrl = `/projects/creation/team/select-team`;
  const { formatMessage } = intl;
  const retailerResponsibilities = responsibilities?.filter(
    f => f.companyActivityCode === companyActivityType.retailer
  );
  const history = useHistory();

  useEffect(() => {
    if (teamSelectedSearch?.length > 0) {
      const teamFromSearch = retailerResponsibilities.map(teamRetail => {
        const userSelected = teamSelectedSearch.find(
          ({ responsibilityId }) => responsibilityId === teamRetail.id
        )?.userId;
        return userSelected
          ? { ...teamRetail, userIdSelected: userSelected }
          : teamRetail;
      });

      dispatch(setTeamRetailer(teamFromSearch));
      dispatch(setProjectCreationTeamSelectedSearch([]));
    }
  }, [teamSelectedSearch]);
  useEffect(() => {
    const mustOpenSelectTeamModal =
      history.location.pathname === selectTeamModalUrl && !openSelecTeamtModal;
    setOpenSelectTeamModal(mustOpenSelectTeamModal);
  }, [history.location.pathname]);

  useEffect(() => {
    (async () => {
      dispatch(updateSelectedTeam(responsibilities));
    })();
  }, [responsibilities]);

  const getTeamSelectOpen = () => (
    <ModalContextProvider
      value={{
        onClose: results => {
          if (results) {
            dispatch(
              setProjectCreationTeamSelectedSearch(results.responsibilities)
            );
          }
          history.goBack();
        },
      }}
    >
      <Modal>
        <SelectTeam
          userId={userId}
          companyId={owningCompanyId}
          owningCompanyId={owningCompanyId}
          languageCode={languageCode}
          intl={intl}
        />
      </Modal>
    </ModalContextProvider>
  );

  return (
    <>
      <div className={styles.wrapperTitle}>
        <Title
          level={2}
          value={companyName}
          subtitle={formatMessage(retailSubtitle)}
        />
        <Button
          data-test-id="selectTeam"
          size="small"
          onClick={() => history.push("/projects/creation/team/select-team")}
        >
          {formatMessage(intParticipants.selectTeamBtn)}
        </Button>
      </div>
      <TeamsRetail
        responsibilities={retailerResponsibilities}
        onChange={data => {
          dispatch(setTeamRetailer(data));
        }}
      />
      {openSelecTeamtModal && getTeamSelectOpen()}
    </>
  );
};
