import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  selectStartDate,
  selectProjectName,
  selectedTypeName,
  selectProjectCreationCurrentData,
} from "common/selectors/projects.selectors";
import { selectValidatedProducts } from "common/selectors/filteredProducts.selectors";
import { Title } from "@trace-one/react-components";
import Timeline from "./Timeline";
import Participants from "./Participants";
import { Suppliers } from "./Suppliers";
import { getLocalDate } from "utils/DateUtils";
import { summaryTrans } from "routes/Projects/defineMessages";
import styles from "./Summary.module.less";
import { Tooltip } from "@trace-one/design-system";
import { additionalInformations } from "routes/ProjectsList/defineMessages";
export const Summary = () => {
  const startDate = useSelector(selectStartDate);
  const projectName = useSelector(selectProjectName);
  const selectedProducts = useSelector(selectValidatedProducts);
  const currentTypeName = useSelector(selectedTypeName);
  const projectCreationCurrentData = useSelector(
    selectProjectCreationCurrentData
  );
  const {
    sectionTitleDef,
    sectionSubDef,
    sectionSubStatus,
    sectionTitleTimeline,
    sectionSubTimeline,
    sectionTitleMembersTimeline,
    sectionSubMembersTimeline,
    sectionName,
    sectionType,
    sectionStartDate,
    sectionNumProducts,
  } = summaryTrans;

  const { formatMessage } = useIntl();
  return (
    <>
      <Title
        level={2}
        value={formatMessage(sectionTitleDef)}
        subtitle={formatMessage(sectionSubDef)}
      />
      <div className={styles.sectionContentWrapper}>
        <div>
          <p>
            {formatMessage(sectionName)}
            <Tooltip text={projectName} placement="top" size="large">
              <span
                data-test-id="project-name-summary"
                className={styles.bluetext}
              >
                {projectName && projectName}
              </span>
            </Tooltip>
          </p>
          <p>
            {formatMessage(sectionType)}
            <span
              data-test-id="project-type-summary"
              className={styles.bluetext}
            >
              {currentTypeName}
            </span>
          </p>
          <p>
            {formatMessage(sectionStartDate)}
            <span
              data-test-id="project-start-date-summary"
              className={styles.bluetext}
            >
              {startDate && getLocalDate(startDate)}{" "}
            </span>
          </p>
        </div>
        <div>
          <p>
            {`${formatMessage(additionalInformations.projectCode)}: `}
            {projectCreationCurrentData?.projectCode.length > 0 ? (
              <span data-test-id="project-code" className={styles.bluetext}>
                {projectCreationCurrentData?.projectCode}
              </span>
            ) : (
              <span>-</span>
            )}
          </p>
          <p>
            {formatMessage(sectionSubStatus)}
            <span data-test-id="summary-status" className={styles.bluetext}>
              Draft
            </span>
          </p>
          <p>
            {formatMessage(sectionNumProducts)}
            <span
              data-test-id="number-of-products-summary"
              className={styles.bluetext}
            >
              {selectedProducts.length}
            </span>
          </p>
        </div>
      </div>
      <Title
        level={2}
        value={formatMessage(sectionTitleTimeline)}
        subtitle={formatMessage(sectionSubTimeline)}
      />
      <div>
        <Timeline />
      </div>
      <Title
        level={2}
        value={formatMessage(sectionTitleMembersTimeline)}
        subtitle={formatMessage(sectionSubMembersTimeline)}
      />
      <Participants />
      <Suppliers />
    </>
  );
};
