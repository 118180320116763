/* istanbul ignore file */
import moment from "moment-business-days";
import { ISO_STRING_FORMAT } from "../../constants";
import { statusNames } from "constants/tasks";
import { statusNames as statusNamesProject } from "constants/projects";

const displayFutureTaskConditions = originalData => {
  return (
    originalData?.stepStatus !== "Future" &&
    originalData?.taskStatus !== "Future"
  );
};
const displayMyTaskConditions = (originalData, config) => {
  return (
    originalData.userId &&
    originalData.userId === config?.userId &&
    !originalData.notRelevantForUser
  );
};
export function createBryntumFilter(displayMyTasks, showFutureTasks, config) {
  return function bryntumFilter(task) {
    const { originalData } = task;
    switch (true) {
      case showFutureTasks && !displayMyTasks:
        return !originalData.notRelevantForUser;
      case showFutureTasks && displayMyTasks:
        return displayMyTaskConditions(originalData, config);
      case !showFutureTasks && displayMyTasks:
        return (
          displayMyTaskConditions(originalData, config) &&
          displayFutureTaskConditions(originalData)
        );
      default:
        return (
          displayFutureTaskConditions(originalData) &&
          !originalData.notRelevantForUser
        );
    }
  };
}

export const setStartDate = async ({ ganttRef, startDate, onChange }) => {
  if (startDate && ganttRef.current?.project) {
    let startDateMoment;
    startDateMoment = moment(startDate, ISO_STRING_FORMAT);
    await ganttRef.current.project.setStartDate(startDateMoment.toDate());
    if (onChange) {
      const duration = ganttRef.current.project.duration;
      let newEndDate = startDateMoment.clone();
      for (let i = 0; i < duration; i++) {
        newEndDate = newEndDate.nextBusinessDay();
      }
      if (newEndDate == null) {
        return;
      }
      onChange({
        endDate: moment(newEndDate).startOf("day").format(ISO_STRING_FORMAT),
        startDate: startDateMoment.startOf("day").format(ISO_STRING_FORMAT),
      });
    }
  }
};

export const setEndDate = async ({ endDate, ganttRef, onChange }) => {
  if (endDate && ganttRef.current?.project) {
    const endDateMoment = moment(endDate, ISO_STRING_FORMAT).startOf("day");
    const duration = ganttRef.current.project.duration;
    let newStartDate = endDateMoment.clone();
    for (let i = 0; i < duration; i++) {
      newStartDate = newStartDate.prevBusinessDay();
    }
    await ganttRef.current.project.setEndDate(endDateMoment.toDate());
    return setStartDate({
      ganttRef,
      startDate: newStartDate.format(ISO_STRING_FORMAT),
      onChange: null,
    });
  }
};

export const getAPIReadyInfoFromTasks = (
  tasks,
  current = [],
  options = { isProjectOwner: false, projectStatus: statusNamesProject.Draft }
) => {
  const { isProjectOwner, projectStatus } = options;
  let newCurrent = [...current];
  for (const task of tasks) {
    if (
      canChangeDuration(
        {
          isUserTask: task.originalData.isUserTask,
          taskStatus: task.originalData.taskStatus,
        },
        projectStatus,
        isProjectOwner
      )
    ) {
      newCurrent = [
        ...newCurrent,
        {
          taskId: task.originalData.id,
          durationInDays: Math.round(task.duration),
        },
      ];
    }
    if (task.children) {
      newCurrent = [
        ...getAPIReadyInfoFromTasks(task.children, newCurrent, options),
      ];
    }
  }
  return newCurrent.reduce((acc, item) => {
    if (!acc.find(i => i.taskId === item.taskId)) {
      return [...acc, item];
    }
    return acc;
  }, []);
};

const canChangeDuration = (
  { isUserTask, taskStatus },
  projectStatus = statusNamesProject.Draft,
  isProjectOwner = false
) => {
  if (
    taskStatus !== statusNames.InProgressOneWord &&
    taskStatus !== statusNames.Future &&
    taskStatus !== "Undefined"
  ) {
    return false;
  }
  if (!isProjectOwner) {
    return false;
  }

  return !(
    projectStatus !== statusNamesProject.InProgress &&
    projectStatus !== statusNamesProject.Draft &&
    projectStatus !== statusNamesProject.Scheduled
  );
};

export const canResizeTask =
  (projectStatus, isProjectOwner) =>
  ({
    taskRecord: {
      originalData: { isUserTask, taskStatus },
    },
  }) => {
    return canChangeDuration(
      { isUserTask, taskStatus },
      projectStatus,
      isProjectOwner
    );
  };

export const canUpdateCell =
  (projectStatus, isProjectOwner) =>
  ({
    editorContext: {
      record: {
        originalData: { taskStatus, isUserTask },
      },
      column: {
        originalData: { uuid },
      },
    },
  }) => {
    if (uuid === "duration") {
      return canChangeDuration(
        {
          isUserTask,
          taskStatus,
        },
        projectStatus,
        isProjectOwner
      );
    }
    return true;
  };

export const zoomToFit = ({ ganttRef }) => {
  if (ganttRef?.current?.zoomToFit) {
    ganttRef.current.zoomToFit({
      leftMargin: 30,
      rightMargin: -20,
    });
  }
};
