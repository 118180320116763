/* istanbul ignore file */
import { combineReducers } from "redux";
import projectCreationReducer from "./projectCreation.reducer";
import projectUIReducer from "./projectUI.reducer";
import tasksReducer from "./tasks.reducer";
import bryntumReducer from "./bryntum.reducer";
import userReducer from "./user.reducer";
import filteredProductsReducer from "./filteredProducts.reducer";
import deploymentReducer from "./deployment.reducer";
import translationReducer from "./translation.reducer";
import managementReducer from "./management.reducer";
import createProductsReducer from "./createProducts.reducer";
import teamsReducer from "./teams.reducer";
import oidcReducer from "./oidc.reducer";
import filtersReducer from "./filters.reducer";
import ganttViewReducer from "./ganttView.reducer";
import errorEventsReducer from "./errorEvents.reducer";
import documentsReducer from "./documents.reducer";
import collapseKeysReducer from "./collapse.reducer";

const Reducers = combineReducers({
  projectCreation: projectCreationReducer,
  projectUI: projectUIReducer,
  tasks: tasksReducer,
  bryntum: bryntumReducer,
  filteredProducts: filteredProductsReducer,
  user: userReducer,
  deployment: deploymentReducer,
  translation: translationReducer,
  management: managementReducer,
  createProducts: createProductsReducer,
  teams: teamsReducer,
  documents: documentsReducer,
  oidc: oidcReducer,
  filters: filtersReducer,
  ganttView: ganttViewReducer,
  errorEvents: errorEventsReducer,
  collapseKeys: collapseKeysReducer,
});

export default Reducers;
