import { useEffect, useState } from "react";

export const useMutationObserver = callback => {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const observer = new MutationObserver(callback);
    setObserver(observer);

    return () => observer.disconnect();
  }, []);
  return observer;
};
