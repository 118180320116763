import { SET_OIDC_ACCESS_TOKEN } from "../../constants";

export const initialState = {
  access_token: null,
};

export default function oidcReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_OIDC_ACCESS_TOKEN:
      const { access_token } = payload;
      return {
        ...state,
        access_token,
      };
    default:
      return state;
  }
}
