export const config = {
  IDSV_URL: window.env.IDSV_URL,
  ENVIRONMENT: window.env.ENVIRONMENT,
  BASE_URL: window.env.BASE_URL,
  CUMD_ENDPOINT: window.env.CUMD_ENDPOINT,
  PRODUCTMD_ENDPOINT: window.env.PRODUCTMD_ENDPOINT,
  PROJECT_ENDPOINT: window.env.PROJECT_ENDPOINT,
  REFLISTMD_ENDPOINT: window.env.REFLISTMD_ENDPOINT,
  NOTIFICATION_ENDPOINT: window.env.NOTIFICATION_ENDPOINT,
  PACKAGING_ENDPOINT: window.env.PACKAGING_ENDPOINT,
  DOCUMENT_ENDPOINT: window.env.DOCUMENT_ENDPOINT,
  COLLABORATION_ENDPOINT: window.env.COLLABORATION_ENDPOINT,
  DISCUSSION_ENDPOINT: window.env.DISCUSSION_ENDPOINT,
  DISCUSSION_WS_ENDPOINT: window.env.DISCUSSION_WS_ENDPOINT,
  HEAP_APP_ID: window.env.HEAP_APP_ID,
  INTERCOM_APP_ID: window.env.INTERCOM_APP_ID,
  LEGAL_MONSTER_COOKIE_WIDGET_EN_ID:
    window.env.LEGAL_MONSTER_COOKIE_WIDGET_EN_ID,
  LEGAL_MONSTER_COOKIE_WIDGET_FR_ID:
    window.env.LEGAL_MONSTER_COOKIE_WIDGET_FR_ID,
  UI_ADMIN_USERS_ACCESS: window.env.UI_ADMIN_USERS_ACCESS,
  UI_INSIGHT_URL: window.env.UI_INSIGHT_URL,
};
