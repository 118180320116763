import {
  GET_DOCUMENTS,
  GET_DOCUMENT,
  SET_DOCUMENTS_LOADING,
  SET_DOCUMENTS_LOADED,
  SET_DOCUMENTS_MODAL_LOADING,
  SET_DOCUMENTS_PROJECT_DETAILS,
  SET_DOCUMENTS_URL,
  SET_DOCUMENTS_MODAL_STATUS,
  SHOULD_REFETCH_DOCUMENTS,
  SET_DOCUMENTS_ALERT_MESSAGE,
  SET_DOCUMENTS_ALERT_MESSAGES,
} from "../../constants";

export const initialState = {
  documents: [],
  document: {},
  isDocumentsLoading: false,
  isDocumentsLoaded: false,
  shouldRefetchDocuments: true,
  showBanner: false,
  projectUrl: "",
  projectOwnerId: "",
  ownerCompanyId: "",
  projectName: "",
  productName: "",
  serviceType: "",
  isDocumentsModalLoading: false,
  alertMessages: {
    alertMessage: null,
  },
  modalStatus: {
    isError: false,
    errorMessage: "",
    uploadSuccess: false,
  },
};

export default function documentsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DOCUMENTS: {
      return {
        ...state,
        documents: payload?.documents,
        showBanner: payload?.showBanner,
        serviceType: payload?.serviceType,
      };
    }
    case GET_DOCUMENT: {
      return {
        ...state,
        document: payload,
      };
    }
    case SET_DOCUMENTS_LOADED: {
      return {
        ...state,
        isDocumentsLoaded: payload,
      };
    }
    case SHOULD_REFETCH_DOCUMENTS: {
      return {
        ...state,
        shouldRefetchDocuments: payload,
      };
    }
    case SET_DOCUMENTS_PROJECT_DETAILS: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_DOCUMENTS_URL: {
      return {
        ...state,
        projectUrl: payload,
      };
    }
    case SET_DOCUMENTS_LOADING: {
      return {
        ...state,
        isDocumentsLoading: payload,
      };
    }
    case SET_DOCUMENTS_MODAL_LOADING: {
      return {
        ...state,
        isDocumentsModalLoading: payload,
      };
    }
    case SET_DOCUMENTS_MODAL_STATUS: {
      return {
        ...state,
        modalStatus: { ...state.modalStatus, ...payload },
      };
    }
    case SET_DOCUMENTS_ALERT_MESSAGES: {
      return {
        ...state,
        alertMessages: payload,
      };
    }
    case SET_DOCUMENTS_ALERT_MESSAGE: {
      return {
        ...state,
        alertMessages: {
          ...state.alertMessages,
          alertMessage: payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
