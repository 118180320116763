/* istanbul ignore file */
import { Column, ColumnStore } from "bryntum-gantt";
import { customTimeTrackedCeilMethod } from "utils/DateUtils";

export default class TimeTrackedColumn extends Column {
  static get type() {
    return "timeTracked";
  }

  static get isGanttColumn() {
    return true;
  }
  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  renderer({
    record: {
      originalData: { timeTracked },
    },
  }) {
    if (
      timeTracked !== null &&
      timeTracked !== undefined &&
      timeTracked !== 0
    ) {
      return customTimeTrackedCeilMethod(timeTracked);
    } else if (timeTracked === 0) {
      return "<1";
    } else {
      return "-";
    }
  }
}

ColumnStore.registerColumnType(TimeTrackedColumn);
