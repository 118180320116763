import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "common/selectors/users.selectors";
import { useLanguagePreference } from "@trace-one/react-components";
import { PackagingSystem } from "@trace-one/business-components";
import styles from "../EditPackagingSystem/packagingSystem.module.less";
import useGetPackagingSystemDataForm from "../EditPackagingSystem/useGetPackagingSystemDataForm";

const CreatePackagingSystemForm = () => {
  const history = useHistory();
  const languageCode = useLanguagePreference();
  const { id, taskId, manufItemId } = useParams<any>();
  const ownerCompanyId = useSelector(selectOwningCompanyId);
  const { handleOnTemporaryAssociate } = useGetPackagingSystemDataForm({
    projectId: id,
    taskId: taskId,
    ownerCompanyId: ownerCompanyId,
    manufacturedItemId: manufItemId,
    languageCode,
    setLoading: () => {},
  });

  return (
    <div className={styles.packagingSystem}>
      <PackagingSystem
        languageCode={languageCode}
        onAssociate={async packagingSystemIds => {
          await handleOnTemporaryAssociate(packagingSystemIds);
          history.push(`/projects/projectfolder/${id}/tasks/${taskId}`, {
            packagingSystemID: packagingSystemIds,
          });
        }}
        ownerCompanyId={ownerCompanyId}
        closePackagingSystemCreation={() => {
          history.push(`/projects/projectfolder/${id}/tasks/${taskId}`);
        }}
      />
    </div>
  );
};

export default CreatePackagingSystemForm;
