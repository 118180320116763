import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// This is polyfill for browsers without Plural Rules
if (!window.Intl.PluralRules) {
  require("@formatjs/intl-pluralrules/polyfill");
  require("@formatjs/intl-pluralrules/dist/locale-data/fr");
  require("@formatjs/intl-pluralrules/dist/locale-data/en");
}
// This is polyfill for browsers without Realtive Time Format
if (!window.Intl.RelativeTimeFormat) {
  require("@formatjs/intl-relativetimeformat/polyfill");
  require("@formatjs/intl-relativetimeformat/dist/locale-data/fr");
}
