import { useState } from "react";
import { useIntl } from "react-intl";
import {
  ERROR_CODE_EXTENSION,
  ERROR_CODE_OVERSIZE_FILE,
} from "../../constants";
import { uploadFile } from "./API";
import { messages } from "./messages";
import { documentApi } from "apis";
import { useSelector } from "react-redux";
import { selectOwningCompanyId } from "common/selectors/users.selectors";

const useUploadFileToPackagingComponent = ({
  setIsLoading,
  packagingDocumentId = null,
  callback = null,
  manufItemId,
  files,
  referenceListId,
  packagingDocumentTypeId,
  projectId,
  setIsProcessing,
  itemCode,
  isCustomType,
  typeId,
}) => {
  // filtreaze dupa fisierele care sunt Lock
  const intl = useIntl();
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const [uploadedFile, setUploadedFile] = useState(null);
  const userCompanyId = useSelector(selectOwningCompanyId);

  // get the files for  task and if the files exist there update

  const createCopy = async ({ file }) => {
    const { fileId, versionId } = file;
    let { data } = await documentApi.downloadFile({ fileId, versionId });
    return new File([data], file.fileName, { type: data.type });
  };

  const customRequest = async params => {
    const {
      file,
      packagingComponentId,
      packagingDocumentTypeId,
      packagingSystemId,
    } = params;
    try {
      setIsLoading(true);
      setIsProcessing?.(true);
      const isFile = file[0] instanceof File;
      let finalFile = file[0];
      if (!isFile) finalFile = await createCopy({ file: file[0] });
      const uploadFileResult = await uploadFile({
        file: finalFile,
        packagingDocumentId,
        manufItemId,
        referenceListId,
        packagingDocumentTypeId,
        packagingComponentId,
        projectId,
        files,
        itemCode,
        isCustomType,
        typeId,
        userCompanyId,
      });

      setError({ isError: false, errorMessage: "" });
      setUploadedFile({
        ...uploadFileResult,
        ...(packagingComponentId && { packagingComponentId }),
        ...(packagingDocumentTypeId && { packagingDocumentTypeId }),
        ...(packagingSystemId && { packagingSystemId }),
      });
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;
      const errorStatus = error?.response?.status;

      let errorMessage = "";

      switch (errorCode) {
        case ERROR_CODE_EXTENSION: {
          errorMessage = intl
            ? intl.formatMessage(messages.errorUpload)
            : `wrong file type: ${ERROR_CODE_EXTENSION}`;
          break;
        }
        case ERROR_CODE_OVERSIZE_FILE: {
          errorMessage = intl
            ? intl.formatMessage(messages.errorOversizeFile)
            : error?.response?.data?.error;

          break;
        }
        default: {
          errorMessage = error?.response?.data?.errors[""][0]
            ? error?.response?.data?.errors[""][0]
            : `${errorStatus ? errorStatus + ": " : ""} Something went wrong`;
          break;
        }
      }

      setError({
        isError: true,
        errorMessage,
      });
    } finally {
      if (typeof callback === "function") await callback();
      setIsLoading(false);
      setIsProcessing?.(false);
    }
  };

  const removeDocument = async params => {
    const { packagingComponentId, packagingDocumentTypeId, file } = params;
    try {
      setIsLoading(true);
      setIsProcessing?.(true);
      setError({ isError: false, errorMessage: "" });
      await documentApi.deleteFileVersionByVersionId(file);

      setUploadedFile({
        fileId: undefined,
        fileName: undefined,
        fileLength: 0,
        ...(packagingComponentId && { packagingComponentId }),
        ...(packagingDocumentTypeId && { packagingDocumentTypeId }),
      });
    } catch (error) {
      let errorMessage = "";
      setError({
        isError: true,
        errorMessage,
      });
    }

    if (typeof callback === "function") await callback();
    setIsLoading(false);
    setIsProcessing?.(false);
  };

  return {
    uploadedFile,
    error,
    customRequest,
    removeDocument,
  };
};

export default useUploadFileToPackagingComponent;
