import React from "react";
import { Select } from "antd";
import PropTypes from "prop-types";
import styles from "./SelectSearch.module.less";

const SelectSearch = ({
  optionData,
  placeHolder,
  onChange,
  value,
  selectid,
  ...props
}) => {
  const { Option } = Select;
  return (
    <div className={styles.wrapper}>
      <Select
        {...props}
        allowClear
        showSearch
        placeholder={placeHolder}
        optionFilterProp="children"
        onChange={value => onChange(value, selectid)}
        value={value}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {optionData.map(o => (
          <Option
            className={`suppliername-${o.text.replace(" ", "-")}`}
            selectid={selectid}
            key={o.value}
          >
            {o.text}
          </Option>
        ))}
      </Select>
    </div>
  );
};
SelectSearch.propTypes = {
  optionData: PropTypes.array,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  selectid: PropTypes.any,
};

export default SelectSearch;
