import { config } from "../services/messages/config";
import CUMD from "./CUMD";
import PROJECT from "./PROJECT";
import RLMD from "./RLMD";
import PRODUCTMD from "./PRODUCTMD";
import NOTIFICATIONS from "./NOTIFICATIONS";
import PACKAGING from "./PACKAGING";
import DOCUMENT from "./DOCUMENT";
import COLLABORATION from "./COLLABORATION";
import DISCUSSION from "./DISCUSSION";

export const cumdApi = new CUMD(config.CUMD_ENDPOINT);
export const projectApi = new PROJECT(config.PROJECT_ENDPOINT);
export const referenceListApi = new RLMD(config.REFLISTMD_ENDPOINT);
export const productApi = new PRODUCTMD(config.PRODUCTMD_ENDPOINT);
export const notificationsApi = new NOTIFICATIONS(config.NOTIFICATION_ENDPOINT);
export const packagingApi = new PACKAGING(config.PACKAGING_ENDPOINT);
export const documentApi = new DOCUMENT(config.DOCUMENT_ENDPOINT);
export const discussionApi = new DISCUSSION(config.DISCUSSION_ENDPOINT);
export const collaborationApi = new COLLABORATION(
  config.COLLABORATION_ENDPOINT
);
