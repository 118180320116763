import { Column, ColumnStore } from "bryntum-gantt";
import { renderToString } from "react-dom/server";
import { Tag } from "@trace-one/design-system";
import styles from "routes/Tasks/TasksList/components/TableTasks.module.less";
import moment from "moment";

export default class InitialEndDateColumn extends Column {
  static get type() {
    return "initialDueDateUtc";
  }

  static getTimeFormat(wasRun) {
    if (wasRun) {
      return "DD/MM/YYYY HH:mm";
    } else {
      return "DD/MM/YYYY";
    }
  }

  static get isGanttColumn() {
    return true;
  }
  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  renderer(rowData) {
    const {
      record: {
        originalData: { initialDueDateUtc },
      },
    } = rowData;

    const {
      column: {
        data: { wasRun },
      },
    } = rowData;

    if (!initialDueDateUtc) return "-";
    return renderToString(
      <Tag
        className={styles.setTagInitialEndDate}
        label={moment(initialDueDateUtc).format(
          InitialEndDateColumn.getTimeFormat(wasRun)
        )}
        size="medium"
        mode="light"
      />
    );
  }
}

export class InitialEndDateColumnCreation extends InitialEndDateColumn {
  static get type() {
    return "initialDueDateUtcCreation";
  }
  renderer({
    record: {
      originalData: { initialDueDateUtc },
    },
  }) {
    if (!initialDueDateUtc) return "-";
    return renderToString(
      <Tag
        className={styles.setTagInitialEndDate}
        label={moment(initialDueDateUtc).format("DD/MM/YYYY")}
        size="medium"
        mode="light"
      />
    );
  }
}

// Register both column types with the ColumnStore
ColumnStore.registerColumnType(InitialEndDateColumn);
ColumnStore.registerColumnType(InitialEndDateColumnCreation);
