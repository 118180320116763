/* istanbul ignore file */
import { Column, ColumnStore } from "bryntum-gantt";

export default class TaskPriorityColumn extends Column {
  static get type() {
    return "userResponsibility";
  }

  static get isGanttColumn() {
    return false;
  }

  static get defaults() {
    return {
      text: "Responsibility",
      htmlEncode: false,
      editor: false,
    };
  }

  renderer({
    record: {
      originalData: { userName, userResponsibility },
    },
  }) {
    let responsibility = "";
    if (userName) {
      responsibility = userName;
    } else if (userResponsibility) {
      responsibility = userResponsibility;
    }
    return `<div>${responsibility}<div>`;
  }
}

ColumnStore.registerColumnType(TaskPriorityColumn);
