import { documentApi, projectApi } from "apis";
import { getCorrectDocument } from "components/TaskDetailModal/TaskDetailForm/utils/documentHelper";

const customUpload = async ({
  file,
  referenceListId,
  packagingDocumentTypeId,
  projectId,
  manufItemId,
  packagingComponentId,
  files,
  itemCode,
  isCustomType,
  typeId,
  userCompanyId,
}) => {
  const setCustomMetaDataToDMS = async fileId => {
    if (isCustomType) {
      await documentApi.metaDataAddTofile({
        fileId: fileId,
        metaData: {
          document_type_id: typeId,
        },
      });
    }
  };

  const lockFile = async fileId => {
    try {
      await documentApi.lockFile({ fileId });
    } catch (error) {
      console.error(`Error locking file ${fileId}:`, error);
      throw new Error(`Cannot lock the file ${fileId}.`);
    }
  };

  if (files.length !== 0) {
    try {
      await lockFile(files[0].fileId);
      const res = await documentApi.uploadNewVersion({
        file,
        fileId: files[0].fileId,
        docType: isCustomType ? "CUSTOM_TYPE" : itemCode,
      });
      const { data } = res;
      await setCustomMetaDataToDMS(data?.fileId);
      return res;
    } catch (error) {
      console.error(
        `Error uploading new version for file ${files[0].fileId}:`,
        error
      );
      throw error;
    }
  } else {
    const data = await getCorrectDocument({
      refListId: referenceListId,
      refListItemId: packagingDocumentTypeId,
      projectId,
      task: { manufacturedItemId: manufItemId, packagingComponentId },
    });

    if (
      data === null ||
      (data && data.length === 0) ||
      data.files.length === 0
    ) {
      const res = await documentApi.uploadFile({
        file,
        docType: isCustomType ? "CUSTOM_TYPE" : itemCode,
        ownerCompanyId: userCompanyId,
      });
      let { data } = res;
      await setCustomMetaDataToDMS(data?.fileId);

      return res;
    } else {
      try {
        await lockFile(data.files[0].fileId);
        const res = await documentApi.uploadNewVersion({
          file,
          fileId: data.files[0].fileId,
          docType: isCustomType ? "CUSTOM_TYPE" : itemCode,
        });
        const { data: ds } = res;
        await setCustomMetaDataToDMS(ds?.fileId);
        return res;
      } catch (error) {
        console.error(
          `Error uploading new version for file ${data.files[0].fileId}:`,
          error
        );
        throw error;
      }
    }
  }
};

export const uploadFile = async ({
  file,
  packagingDocumentId = null,
  manufItemId,
  referenceListId,
  packagingDocumentTypeId,
  packagingComponentId,
  projectId,
  files,
  itemCode,
  isCustomType,
  typeId,
  userCompanyId,
}) => {
  try {
    const fileFormData = new FormData();
    fileFormData.append("file", file);

    const {
      data: { fileId, fileLength, fileName, uploadedBy, versionId },
    } = await customUpload({
      file: fileFormData,
      referenceListId,
      packagingDocumentTypeId,
      projectId,
      packagingComponentId,
      manufItemId,
      files,
      itemCode,
      isCustomType,
      typeId,
      userCompanyId,
    });

    await projectApi.addFileAccessRule({
      fileId: fileId,
      ManufacturedItemId: manufItemId,
      ProjectId: projectId,
      UploadByUserId: uploadedBy,
    });

    if (packagingDocumentId) {
      await projectApi.attachFileToPackageDocument({
        fileId,
        packagingDocumentId,
      });
    }
    return Promise.resolve({
      fileId,
      fileLength,
      fileName,
      versionId,
    });
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
