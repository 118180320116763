import { cumdApi } from "apis";
import {
  SET_USER,
  SET_USER_LOADING,
  SET_USER_LANGUAGE_PREFERENCE,
  SET_USER_APPLICATIONS,
  SET_USER_PERMISSIONS,
  SET_COMPANY_LOGO,
  SET_COMPANY_ACTIVITY_ID,
} from "../../../constants";
import { companyActivityType } from "constants/projects";

export const fetchUser = ({ userId, companyTraceOne }) => {
  return async dispatch => {
    if (!userId) return null;
    try {
      const { getUserById, getUserApplications, getCompanyById } = cumdApi;
      dispatch(setUserLoading(true));
      const {
        data: {
          userFirstName,
          userLastName,
          userPhotoUrl,
          owningCompanyId,
          userLanguagePreference,
          userJobTitle,
          userLogin,
        },
      } = await getUserById({ userId });

      const {
        data: { userApplications },
      } = await getUserApplications();

      const {
        data: { companyLogoUrl, companyActivityId, companyDisplayName },
      } = await getCompanyById({ companyId: owningCompanyId });

      const userCompanyActivityName =
        Object.keys(companyActivityType).find(
          key => companyActivityType[key] === companyActivityId
        ) ?? "other";

      dispatch(setCompanyLogo(companyLogoUrl));
      dispatch(setCompanyActivityId(companyActivityId));

      const userApplicationsEnriched = userApplications.map(app => ({
        ...app,
        ...(app.applicationTypeId === 40 && {
          applicationName: "TraceOne Network",
        }),
      }));

      dispatch(
        setUser({
          userFirstName,
          userLastName,
          userId,
          userPhotoUrl,
          owningCompanyId,
          userLanguagePreference,
          userJobTitle,
          userCompanyName: companyDisplayName,
          isCompanyTraceOne: companyTraceOne === "True",
          userLogin,
          userCompanyActivityName,
        })
      );
      dispatch(setUserApplications(userApplicationsEnriched));
      dispatch(setUserLoading(false));
    } catch (error) {
      console.error(error);
      dispatch(setUserLoading(false));
    }
  };
};

export const patchUserLanguagePreference = ({ newLanguagePreference }) => {
  return async (dispatch, getState) => {
    const { userId } = getState().user;
    try {
      await cumdApi.patchUserLanguage({
        newLanguagePreference,
        userId,
      });
      dispatch(setUserLanguagePreference(newLanguagePreference));
    } catch (error) {
      console.error(error);
    }
  };
};

export const setUserLoading = payload => {
  return {
    type: SET_USER_LOADING,
    payload,
  };
};

export const setUser = payload => {
  return {
    type: SET_USER,
    payload,
  };
};

export const setUserPermissions = payload => {
  return {
    type: SET_USER_PERMISSIONS,
    payload,
  };
};

export const setUserApplications = payload => {
  return {
    type: SET_USER_APPLICATIONS,
    payload,
  };
};

export const setCompanyLogo = payload => {
  return {
    type: SET_COMPANY_LOGO,
    payload,
  };
};

export const setUserLanguagePreference = payload => {
  return {
    type: SET_USER_LANGUAGE_PREFERENCE,
    payload,
  };
};

export const setCompanyActivityId = payload => {
  return {
    type: SET_COMPANY_ACTIVITY_ID,
    payload,
  };
};
