import {
  selectIsSaveAsDraftLoading,
  selectProjectCreationCurrentData,
  selectProjectName,
  selectStartDate,
  selectStepperData,
} from "common/selectors/projects.selectors";
import { useState, useRef, useEffect, SetStateAction, Dispatch } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useLanguagePreference } from "@trace-one/react-components";
import {
  selectOwningCompanyId,
  selectUserId,
} from "common/selectors/users.selectors";
import { useHistory } from "react-router-dom";
import {
  canAccess,
  canSkip,
  getWarnFields,
} from "routes/Projects/Creation/helpers";
import { setWarningFields } from "redux/actions/ProjectUI";
import { saveDraft } from "redux/actions/ProjectCreation";
import { hadleCurrentStep, handleNextStep } from "../helpers";

const useButtons = ({
  saveAsDraft,
  isModified,
  setRefresh,
}: {
  isModified: boolean;
  saveAsDraft: () => Promise<void>;
  setRefresh: Dispatch<SetStateAction<boolean>>;
}) => {
  const intl = useIntl();
  const stepperData = useSelector(selectStepperData);
  const [showModal, setShowModal] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const languageCode = useLanguagePreference();
  const ownerUserId = useSelector(selectUserId);
  const isSaveAsDraftLoading = useSelector(selectIsSaveAsDraftLoading);
  const companyId = useSelector(selectOwningCompanyId);
  const projectData = useSelector(selectProjectCreationCurrentData);
  const isActivating = useSelector(selectIsSaveAsDraftLoading);
  const projectname = useSelector(selectProjectName);
  const startDate = useSelector(selectStartDate);
  const dispatch = useDispatch();
  const history = useHistory();
  const taskClone = useRef([]);
  const teamClone = useRef([]);

  useEffect(() => {
    if (taskClone.current.length > 0) return;
    projectData?.tasks.forEach(task => taskClone.current.push(task));
  }, [projectData.tasks.length]);

  useEffect(() => {
    if (teamClone.current.length > 0) return;
    projectData?.responsibilities.forEach(responsability =>
      teamClone.current.push(responsability)
    );
  }, [projectData?.responsibilities.length]);

  const redirectTo = (pathname, ...restOf) =>
    history.push({ pathname, ...restOf });

  const handleSkip = steps => {
    const currentStep = hadleCurrentStep(steps);

    return !canSkip({
      step: currentStep?.key,
      projectData,
      taskClone,
      isModified,
      teamClone,
    });
  };

  const handleNext = async steps => {
    const nextStep = handleNextStep(steps);
    const currentStep = hadleCurrentStep(steps);

    if (canAccess({ step: nextStep.key, projectData })) {
      if (!handleSkip(steps)) {
        await saveAsDraft();
        setRefresh(false);
      }

      redirectTo(nextStep.link);
      dispatch(setWarningFields([]));
    } else {
      const warnFields = getWarnFields(currentStep.key, projectData, intl);
      // For the moment we don't need this
      // const firstField = document.getElementsByName(warnFields[0].name)[0];
      // const heightHeader =
      //   document.getElementsByTagName("header")[0]?.offsetHeight + 5;
      // window.scrollTo(0, firstField?.offsetTop - heightHeader || 0);
      dispatch(setWarningFields(warnFields));
    }
  };

  const handleGoToProject = stepperData => {
    if (!handleSkip(stepperData)) {
      setPopoverVisible(true);
    } else {
      redirectTo(`/projects/projectfolder/${projectData.id}/tasks`);
    }
  };

  const handleSaveAndGo = async () => {
    await saveAsDraft();
    redirectTo(`/projects/projectfolder/${projectData.id}/tasks`);
  };

  const handleGoWithoutSaving = async () => {
    setPopoverVisible(false);
    redirectTo(`/projects/projectfolder/${projectData.id}/tasks`);
  };

  const closeModal = () => setShowModal(false);

  const launchProject = async () => {
    const projectId = await dispatch(
      saveDraft({
        projectData,
        ownerUserId,
        launch: true,
        companyId,
        languageCode,
      } as any)
    );
    history.push({
      pathname: `/projects/projectfolder/${projectId}/tasks`,
      search: `activated=true`,
    });
    closeModal();
  };

  return {
    intl,
    history,
    projectData,
    stepperData,
    showModal,
    popoverVisible,
    isSaveAsDraftLoading,
    isActivating,
    projectname,
    startDate,
    redirectTo,
    handleNext,
    handleGoToProject,
    handleSaveAndGo,
    handleGoWithoutSaving,
    launchProject,
    setShowModal,
    handleSkip,
    setPopoverVisible,
    closeModal,
  };
};

export default useButtons;
