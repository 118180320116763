import React from "react";
import CKEditor from "ckeditor4-react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./styles.less";

const RichTextEditor = ({
  error,
  name,
  onChange,
  onBlur,
  onFocus,
  data,
  readOnly = false,
}) => (
  <div className={classNames("richtext-editor", { error: error })} name={name}>
    <CKEditor
      onChange={event => {
        const pattern =
          '\n<div id="gtx-trans" style="position: absolute; left: 44px; top: -4.8px;">\n<div class="gtx-trans-icon">&nbsp;</div>\n</div>\n';
        const regex = new RegExp(pattern);
        const cleanText = event.editor.getData().replace(regex, "");
        onChange(cleanText);
      }}
      onFocus={event => {
        onFocus(event.editor.getData());
      }}
      onBlur={event => {
        onBlur(event.editor.getData());
      }}
      onBeforeLoad={
        /* istanbul ignore next */ CKEDITOR => {
          CKEDITOR.disableAutoInline = true;
        }
      }
      config={{
        toolbar: [["Format", "Bold", "Italic", "BulletedList", "NumberedList"]],
        magicline_color: "#FF9655",
        language: "en",
        basicEntities: false,
        htmlEncodeOutput: false,
        entities: false,
        versionCheck: false,
      }}
      data={data}
      readOnly={readOnly}
      allowedContent
    />
    {error && <span className="error-message">{error}</span>}
  </div>
);

RichTextEditor.propTypes = {
  data: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

RichTextEditor.defaultProps = {
  onBlur: () => {},
  onFocus: () => {},
};

export default RichTextEditor;
