import {
  SET_BRYNTUM_DATA,
  SET_BRYNTUM_USER_RESPONSIBILITIES,
  SET_BRYNTUM_IS_LOADING,
  SET_SELECTED_TYPE,
  RESET_PROJECT_CREATION_STATE,
} from "../../constants";

export const initialState = {
  isLoading: false,
  bryntumModalData: [],
  userResponsibilities: [],
};

export default function bryntumReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_BRYNTUM_DATA:
      return {
        ...state,
        bryntumModalData: payload,
      };
    case SET_BRYNTUM_USER_RESPONSIBILITIES:
      return {
        ...state,
        userResponsibilities: payload,
      };
    case SET_BRYNTUM_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_SELECTED_TYPE:
      return {
        ...initialState,
      };
    case RESET_PROJECT_CREATION_STATE:
      return { ...initialState };
    default:
      return state;
  }
}
