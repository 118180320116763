import { useReactOidc } from "@axa-fr/react-oidc-context";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCreationPopUp } from "redux/actions/ProjectCreation";
import { postToUrl } from "@trace-one/react-components";

interface IHistory {
  location: {
    state: {
      [key: string]: string;
    };
  };
  push: (param: string) => void;
}

const useRedirectOnCreationActions = () => {
  const [nextLocationUrl, setNextLocationUrl] = useState("");
  const { logout } = useReactOidc();
  const history: IHistory = useHistory();
  const dispatch = useDispatch();

  const extractPathName = str => {
    const expression = /(http[s]?:\/\/)?([^/\s]+)(.*)/;
    const result = str?.match(expression);
    const pathName = result[3];

    return pathName;
  };

  const handleRedirect = () => {
    const helpPageHref = history.location?.state?.hrefLocation;
    const shuldLogout = history.location?.state?.shuldLogout;
    const getEndpointUrl = history.location?.state?.getEndpointUrl;
    const redirectURL = history.location?.state?.redirectURL;
    const stsEndpointUrl = history.location?.state?.stsEndpointUrl;
    const params = history.location?.state?.params;

    if (nextLocationUrl) {
      history.push(nextLocationUrl);
      return;
    }

    if (helpPageHref) {
      dispatch(setCreationPopUp(false));
      window.location.href = helpPageHref;
      return;
    }

    if (shuldLogout) {
      dispatch(setCreationPopUp(false));
      logout();
      return;
    }

    if (redirectURL) {
      const pathName = extractPathName(redirectURL);
      history.push(pathName);
      return;
    }

    if (getEndpointUrl) {
      dispatch(setCreationPopUp(false));
      window.location.assign(getEndpointUrl);
    } else if (stsEndpointUrl) {
      dispatch(setCreationPopUp(false));
      postToUrl(stsEndpointUrl, params);
    }
  };

  return { nextLocationUrl, handleRedirect, setNextLocationUrl };
};

export default useRedirectOnCreationActions;
