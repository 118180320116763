/* istanbul ignore file */
import { axiosInstance } from "./axiosInstance";

export default class DISCUSSION extends axiosInstance {
  getCommentsChangesByDiscussionId = ({ discussionId }) =>
    this.instance.get(`/api/discussions/${discussionId}/commentsChanges`);

  getRootCommentsCount = ({ discussionIds }) => {
    if (discussionIds?.length === 0) {
      //check if discussionIds is empty, because api not allow that
      return Promise.resolve({ data: [] });
    }

    return this.instance.post("/api/discussions/RootCommentsCounts", {
      discussionIds,
    });
  };

  createComment = ({
    discussionId,
    contentText,
    xfdfAnnotation,
    authorUserId,
    externalCode,
    replyToExternalCode,
    overwriteExisting,
    useSilentError = false,
    showNoErrorWhen = {},
  }) =>
    this.instance.post(
      `/api/comments`,
      {
        discussionId,
        contentText,
        xfdfAnnotation,
        authorUserId,
        externalCode,
        ...(replyToExternalCode && { replyToExternalCode }),
        ...(overwriteExisting && { overwriteExisting }),
      },
      {
        useSilentError,
        showNoErrorWhen,
      }
    );

  updateComment = ({
    commentId,
    contentText,
    xfdfAnnotation,
    useSilentError = false,
  }) =>
    this.instance.patch(
      `/api/comments/${commentId}/`,
      {
        contentText,
        xfdfAnnotation,
      },
      {
        useSilentError,
      }
    );

  updateDiscusstionComment = ({
    discussionId,
    externalCode,
    contentText,
    xfdfAnnotation,
    useSilentError = false,
    showNoErrorWhen = {},
  }) =>
    this.instance.patch(
      `/api/comments/discussionId/${discussionId}/externalCode/${externalCode}`,
      {
        contentText,
        xfdfAnnotation,
      },
      {
        useSilentError,
        showNoErrorWhen,
      }
    );

  deleteComment = ({
    commentId,
    useSilentError = false,
    showNoErrorWhen = {},
  }) =>
    this.instance.delete(`/api/comments/${commentId}/`, {
      useSilentError,
      showNoErrorWhen,
    });

  deleteDiscussionComment = ({
    discussionId,
    externalCode,
    useSilentError = false,
    showNoErrorWhen = {},
  }) =>
    this.instance.delete(
      `/api/comments/discussionId/${discussionId}/externalCode/${externalCode}`,
      {
        useSilentError,
        showNoErrorWhen,
      }
    );

  userConnections = ({ discussionId, useSilentError = false }) =>
    this.instance.get(`/api/discussions/${discussionId}/userConnections`, {
      useSilentError,
    });
}
