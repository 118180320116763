import {
  SET_STEPPER_DATA,
  SET_WARNING_FIELDS,
  RESET_PROJECT_CREATION_STATE,
} from "../../constants";

export const initialState = {
  warnFields: [],
  stepperData: [],
};

export default function projectUIReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STEPPER_DATA:
      return {
        ...state,
        stepperData: action.payload,
      };
    case SET_WARNING_FIELDS:
      return {
        ...state,
        warnFields: action.payload,
      };
    case RESET_PROJECT_CREATION_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
