/* istanbul ignore file */
import { Column, ColumnStore } from "bryntum-gantt";
import { renderToString } from "react-dom/server";

import { Tag } from "@trace-one/design-system";
import { getStatusName, getStatusStyle } from "constants/tasks";

export default class StatusColumn extends Column {
  static get type() {
    return "status";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      text: "Status",
      htmlEncode: false,
      editor: false,
    };
  }

  renderer({
    record: {
      originalData: { stepStatus, taskStatus },
    },
    grid: { intl },
  }) {
    if (!taskStatus && !stepStatus) return "";

    let status = "Undefined";
    if (taskStatus) {
      status = taskStatus;
    } else if (stepStatus) {
      status = stepStatus;
    }
    if (getStatusName(status, intl) && getStatusName(status, intl) !== "-") {
      return renderToString(
        <Tag
          color={getStatusStyle[status]}
          label={getStatusName(status, intl)}
          size="small"
          mode="light"
        />
      );
    } else {
      return renderToString(<span>-</span>);
    }
  }
}

ColumnStore.registerColumnType(StatusColumn);
