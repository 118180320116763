/* istanbul ignore file */
import { applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
const middlewares = compose(
  applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : compose
);

export default middlewares;
