import React from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { selectTeamResponsibilities } from "common/selectors/projects.selectors";
import { participantsTrans } from "routes/Projects/defineMessages";
import { companyActivityType } from "constants/projects";
import styles from "./Participants.module.less";

const Participants = () => {
  const { formatMessage } = useIntl();
  const { numberOfParticipants, noUser } = participantsTrans;
  const responsibilities = useSelector(selectTeamResponsibilities).filter(
    ({ companyActivityCode: type }) => type === companyActivityType.retailer
  );
  const numberParticipants = responsibilities.filter(
    responsibility => !!responsibility.userIdSelected
  ).length;

  return (
    <div className={styles.wrapper} data-test-id="participants-summary">
      <div className={styles.subtitle}>
        <p>
          <span className={styles.bluetext}>{`${numberParticipants} `}</span>
          {formatMessage(numberOfParticipants, {
            s: numberParticipants > 1 && "S",
          })}
        </p>
      </div>
      <div className={styles.content}>
        {responsibilities.map(responsibility => (
          <p key={responsibility.id}>
            {`${responsibility.name}: `}
            <span className={styles.bluetext}>
              {responsibility?.userIdSelected
                ? responsibility?.users.find(
                    u => u.value === responsibility.userIdSelected
                  )?.text ?? ""
                : formatMessage(noUser)}
            </span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default Participants;
