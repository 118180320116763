import { defineMessages } from "react-intl";

export const associatePackagingModal = defineMessages({
  title: {
    id: "projects.modalPackaging.title",
    defaultMessage: "Select Packaging System(s)",
  },
  titleElementCount: {
    id: "projects.modalPackaging.titleElementCount",
    defaultMessage:
      "{selected, plural, =0 {(no product selected)} one {(# product selected)} other {(# products selected)}}",
  },
  subTitle: {
    id: "projects.modalPackaging.subtitle",
    defaultMessage:
      "Select the packaging system to associate to the selected products",
  },
  emptyTitle: {
    id: "projects.modalPackaging.empty.title",
    defaultMessage:
      "You can start to select a packaging system with the search bar above",
  },
  noResults: {
    id: "projects.modalPackaging.empty.noResults",
    defaultMessage: "There is no datas corresponding to your search",
  },
  search: {
    id: "projects.modalPackaging.search",
    defaultMessage: "Search",
  },
  searchPlaceholder: {
    id: "projects.modalPackaging.search.placeholder",
    defaultMessage: "Type a name, type, tags, etc ...",
  },
  secondaryButton: {
    id: "projects.modalPackaging.createButton",
    defaultMessage: "Create new packaging system",
  },
  primaryButton: {
    id: "projects.modalPackaging.associateButton",
    defaultMessage: "Associate",
  },
});
