import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  selectUserId,
  selectUserLanguagePreference,
} from "common/selectors/users.selectors";

import useHeapAnalytic from "./useHeapAnalytic";
import useIntercom from "./useIntercom";

function getCookieSettingsWidgetPublicKey(legalMonsterLanguage) {
  switch (legalMonsterLanguage) {
    case "fr-fr":
      return window.env.LEGAL_MONSTER_COOKIE_WIDGET_FR_ID;

    default:
      return window.env.LEGAL_MONSTER_COOKIE_WIDGET_EN_ID;
  }
}

function useLegalMonsterRef() {
  const userId = useSelector(selectUserId).toUpperCase();
  const userLanguage = useSelector(selectUserLanguagePreference).toLowerCase();

  const legalMonsterRef = useRef({
    userId,
    userLanguage,
  });

  useEffect(() => {
    legalMonsterRef.current.userId = userId;
    legalMonsterRef.current.userLanguage = userLanguage;
  }, [userId, userLanguage]);

  useEffect(() => {
    if (userId) {
      const widgetPublicKey = getCookieSettingsWidgetPublicKey(userLanguage);
      window.legal.widget({
        type: "cookie",
        widgetPublicKey,
        locale: userLanguage,
        identifier: userId,
      });
    }

    return () => {
      document.getElementById("legalmonster-closeButtonPrivacy")?.click();
    };
  }, [userLanguage, userId]);

  return legalMonsterRef;
}

export function useLegalMonster() {
  const legalMonsterRef = useLegalMonsterRef();

  useIntercom(legalMonsterRef);
  useHeapAnalytic(legalMonsterRef);
}
