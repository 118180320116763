import { ReactComponent as Applications } from "./svg/applications.svg";
import { ReactComponent as Arrow } from "./svg/arrow.svg";
import { ReactComponent as Bin } from "./svg/bin.svg";
import { ReactComponent as CloseCross } from "./svg/close-cross.svg";
import { ReactComponent as ActivateProject } from "./svg/activate-project.svg";
import { ReactComponent as Cross } from "./svg/cross.svg";
import { ReactComponent as Duplicate } from "./svg/duplicate.svg";
import { ReactComponent as Messages } from "./svg/messages.svg";
import { ReactComponent as Notifications } from "./svg/notifications.svg";
import { ReactComponent as Profile } from "./svg/profile.svg";
import { ReactComponent as CrossBlue } from "./svg/iconCrossBlue.svg";
import { ReactComponent as Cog } from "./svg/cog.svg";
import { ReactComponent as Gantt } from "./svg/gantt.svg";
import { ReactComponent as List } from "./svg/list.svg";
import { ReactComponent as Search } from "./svg/search.svg";
import { ReactComponent as Burger } from "./svg/burger.svg";
import { ReactComponent as Logo } from "./svg/logo.svg";
import { ReactComponent as AttachImg } from "./svg/attachments_icon.svg";
import { ReactComponent as AttachHeroImg } from "./svg/attachHeroImg.svg";
import { ReactComponent as Deployment } from "./svg/deployment.svg";
import { ReactComponent as Management } from "./svg/management.svg";
import { ReactComponent as Translation } from "./svg/translation.svg";
import { ReactComponent as BgDeployment } from "./svg/bg-deployment.svg";
import { ReactComponent as Calendar } from "./svg/calendar.svg";

const Icons = {
  Applications,
  Arrow,
  Bin,
  CloseCross,
  Cross,
  Duplicate,
  Messages,
  Notifications,
  Profile,
  CrossBlue,
  Cog,
  Gantt,
  List,
  ActivateProject,
  Search,
  Burger,
  Logo,
  AttachImg,
  AttachHeroImg,
  Deployment,
  Management,
  Translation,
  BgDeployment,
  Calendar,
};

export default Icons;
