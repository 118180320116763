import { useState } from "react";
import { cloneDeep } from "lodash";
import { packagingComponentDocumentsObj } from "components/PackagingSystemCollapse/types";

const useFormatFormData = ({ formData }) => {
  const [packagingComponentDocuments, setPackagingComponentDocuments] =
    useState(formData?.packagingComponentDocuments || []);

  const formatFormData = uploadedFile => {
    const { packagingComponentId, packagingDocumentTypeId, fileId, versionId } =
      uploadedFile;
    if (!fileId) {
      let documentIndex = packagingComponentDocuments.findIndex(
        packagingComponentDocument => {
          const isComponentIdMatched =
            packagingComponentDocument.packagingComponentId ===
            packagingComponentId;
          const isDocTypeIdMatched =
            packagingComponentDocument.packagingDocumentFiles[0]
              ?.packagingDocumentTypeId === packagingDocumentTypeId;
          return isComponentIdMatched && isDocTypeIdMatched;
        }
      );
      if (documentIndex > -1) {
        const packagingComponentDocumentsCopy = cloneDeep(
          packagingComponentDocuments
        );
        packagingComponentDocumentsCopy.splice(documentIndex, 1);
        setPackagingComponentDocuments(packagingComponentDocumentsCopy);
        return packagingComponentDocumentsCopy;
      }
    }
    const newPackagingComponentDocument: packagingComponentDocumentsObj = {
      packagingComponentId,
      packagingDocumentFiles: [
        {
          packagingDocumentTypeId,
          uploadedDocuments: [
            {
              fileId,
              versionId,
            },
          ],
        },
      ],
    };

    setPackagingComponentDocuments(prevState => [
      ...prevState,
      newPackagingComponentDocument,
    ]);

    return [...packagingComponentDocuments, newPackagingComponentDocument];
  };

  const detachPackagingSystemFromData = () => {
    if (packagingComponentDocuments?.length !== 0) {
      setPackagingComponentDocuments([]);
    }
    return [];
  };

  return {
    formatFormData,
    detachPackagingSystemFromData,
    packagingComponentDocuments,
    setPackagingComponentDocuments,
  };
};

export default useFormatFormData;
