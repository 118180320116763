import { defineMessages } from "react-intl";

export const messages = defineMessages({
  alertMessage: {
    id: "projects.projectFolder.componentDesign.alertMessage",
    defaultMessage:
      "documents are not available yet for this component. You can upload files when the project will start",
  },
  processStatus: {
    id: "projects.projectFolder.componentDesign.processStatus",
    defaultMessage: "Process Status",
  },
  packagingSystem: {
    id: "projects.projectFolder.componentDesign.packagingSystem",
    defaultMessage: "Packaging system",
  },
  supplier: {
    id: "projects.projectFolder.componentDesign.supplier",
    defaultMessage: "Supplier",
  },
  estimatedEndDate: {
    id: "projects.projectFolder.componentDesign.estimatedEndDate",
    defaultMessage: "Estimated end date",
  },
  noFile: {
    id: "projects.projectFolder.componentDesign.noFile",
    defaultMessage: "No file",
  },
  validate: {
    id: "projects.projectFolder.componentDesign.button.validate",
    defaultMessage: "Validate",
  },
  toCorrect: {
    id: "projects.projectFolder.componentDesign.button.toCorrect",
    defaultMessage: "To correct",
  },
  toReview: {
    id: "projects.projectFolder.componentDesign.button.toReview",
    defaultMessage: "To review",
  },
  open: {
    id: "projects.projectFolder.componentDesign.button.open",
    defaultMessage: "OPEN",
  },
  versionHistory: {
    id: "projects.projectFolder.componentDesign.versionHistory",
    defaultMessage: "Version history",
  },
  attachement: {
    id: "projects.projectFolder.componentDesign.attachement",
    defaultMessage: "Attachement",
  },
  uploadNewVersion: {
    id: "projects.projectFolder.componentDesign.uploadNewVersion",
    defaultMessage: "Upload new version",
  },
  status: {
    id: "projects.projectFolder.componentDesign.status",
    defaultMessage: "Status",
  },
  fileOngoing: {
    id: "projects.projectFolder.componentDesign.fileOngoing",
    defaultMessage: "File ongoing",
  },
  incorrectExtension: {
    id: "projects.projectFolder.componentDesign.incorrectExtension",
    defaultMessage:
      "Sorry, you have provided an unsupported file type, please try again with a supported file format.",
  },
  withSupportedFIleFormat: {
    id: "projects.projectFolder.componentDesign.supportedFileFormat",
    defaultMessage: "supported file format.",
  },
  alreadyValidated: {
    id: "projects.projectFolder.componentDesign.alreadyValidated",
    defaultMessage:
      "Sorry, the task cannot be completed because it is impossible to upload a new version on a validated or in review document.",
  },
  errorTaskUnaccesable: {
    id: "projects.projectFolder.componentDesign.errorTaskUnaccesable",
    defaultMessage:
      "You do not have the sufficient permissions to access this task",
  },
  errorUpload: {
    id: "projects.projectFolder.componentDesign.errorUpload",
    defaultMessage: "Please provide a supported file type",
  },
  errorOversizeFile: {
    id: "projects.projectFolder.documents.errorOversizeFile",
    defaultMessage: "The file's size exceeds the limit of 128 MB",
  },
  compareFile: {
    id: "projects.projectFolder.componentDesign.compareFile",
    defaultMessage: "Compare file",
  },
  usePreviousVersion: {
    id: "projects.projectFolder.componentDesign.usePreviousVersion",
    defaultMessage: "Compare with existing version",
  },
  acceptOnlyPdf: {
    id: "projects.projectFolder.componentDesign.acceptOnlyPdf",
    defaultMessage: "Please upload a file of type: pdf",
  },
  uploadSuccessTitle: {
    id: "projects.projectFolder.componentDesign.uploadSuccessTitle",
    defaultMessage: "New Version Saved",
  },
  uploadSuccessMessage: {
    id: "projects.projectFolder.componentDesign.uploadSuccessMessage",
    defaultMessage:
      "The new version of {documentType} has been correctly saved",
  },
  useVersionAsMaster: {
    id: "projects.projectFolder.componentDesign.useVersionAsMaster",
    defaultMessage: "Use this version as master",
  },
  editing: {
    id: "projects.projectFolder.componentDesign.editing",
    defaultMessage: "Editing",
  },
});

export const statuses = defineMessages({
  notUploaded: {
    id: "projects.projectFolder.componentDesign.status.notUploaded",
    defaultMessage: "Not started",
  },
  inReview: {
    id: "projects.projectFolder.componentDesign.status.inReview",
    defaultMessage: "In review",
  },
  toCorrect: {
    id: "projects.projectFolder.componentDesign.status.toCorrect",
    defaultMessage: "To correct",
  },
  validated: {
    id: "projects.projectFolder.componentDesign.status.validated",
    defaultMessage: "Validated",
  },
});

export const errorMessages = defineMessages({
  somethingWentWrong: {
    id: "components.somethingWentWrong.error",
    defaultMessage: "Something went wrong",
  },
  errorWithConnection: {
    id: "projects.projectFolder.documents.errorWithConnection.error",
    defineMessages: "Something went wrong",
  },
  connectionError: {
    id: "components.connection.error",
    defaultMessage: "There was a connection error.",
  },
  refreshPage: {
    id: "components.refreshPage.error",
    defaultMessage: "Please try refreshing the page.",
  },
  errorOccured: {
    id: "components.errorHandler.error",
    defaultMessage: "An error has occurred!",
  },
});
