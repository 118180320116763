import React, { FunctionComponent } from "react";
import Modal from "react-modal";

import styles from "../Modal/Modal.module.less";
import launcherStyles from "./Launcher.module.less";
import Icon from "components/Icon";
import { Button } from "@trace-one/react-components";
import { getLocalDate } from "utils/DateUtils";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "@trace-one/design-system";

interface ProjectLaunchModalProps {
  showModal: boolean;
  closeModal: () => void;
  launchProject: () => void;
  isActivating: boolean;
  projectName: string;
  startDate: string;
  isDraft?: boolean;
}

const ProjectLaunchModal: FunctionComponent<ProjectLaunchModalProps> = ({
  closeModal,
  showModal,
  launchProject,
  isActivating,
  projectName,
  startDate,
  isDraft = false,
}) => {
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={launcherStyles.activationContent}>
        <span className={styles.closeButton} onClick={closeModal}>
          <Icon name="CloseCross" data-test-id="close-cross" />
        </span>
        <h1 className={launcherStyles.header}>
          <Icon name="ActivateProject" />
          {isDraft ? (
            <FormattedMessage
              id="projects.projectFolder.buttonProjectFolder.launcherProjectFolder.title"
              defaultMessage="Project Activation"
            />
          ) : (
            <FormattedMessage
              id="components.launcher.activationIitle"
              defaultMessage="Project Launch"
            />
          )}
        </h1>
        <p className={launcherStyles.projectNameStyle}>
          <FormattedMessage
            id="components.launcher.byActivating"
            defaultMessage="By activating "
          />
          <Tooltip text={projectName} placement="bottom" size="large">
            <span
              data-test-id="projectName"
              className={launcherStyles.launchModalprojectName}
            >
              {" "}
              {projectName}
            </span>
          </Tooltip>
        </p>
        <ul className={launcherStyles.activationList}>
          <li>
            <FormattedMessage
              id="components.launcher.text1"
              defaultMessage="All internal team members will be notified and tasks assigned to them"
            />
          </li>
          <li>
            <FormattedMessage
              id="components.launcher.text2"
              defaultMessage="All external team members will be notified and tasks assigned to them at the project start date"
            />
          </li>
          <li>
            <FormattedMessage
              id="components.launcher.text3"
              defaultMessage="This project will be launched on "
            />
            <span
              data-test-id="projectLaunchDate"
              className={launcherStyles.highlight}
            >
              {getLocalDate(startDate)}
            </span>
          </li>
        </ul>
        <div className={launcherStyles.buttonsAndHeaderContainer}>
          <p className={launcherStyles.confirm}>
            <FormattedMessage
              id="components.launcher.question"
              defaultMessage="Are you sure you want to continue?"
            />
          </p>
          <div className={launcherStyles.buttonsContainer}>
            <Button
              data-test-id="activateProjectYes"
              onClick={launchProject}
              loading={isActivating}
            >
              <FormattedMessage
                id="components.launcher.btnYes"
                defaultMessage="Yes"
              />
            </Button>
            <Button
              data-test-id="activateProjectNo"
              onClick={closeModal}
              disabled={isActivating}
            >
              <FormattedMessage
                id="components.launcher.btnNo"
                defaultMessage="No"
              />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProjectLaunchModal;
