import { useState, useEffect } from "react";

const useTableLocalPagination = ({ data }) => {
  const [paginationData, setPaginationData] = useState<{
    current: number;
    showSizeChanger: boolean;
    pageSize: number;
    total: number;
    dataSource?: Array<{}>;
  }>({
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    total: 0,
    dataSource: [],
  });

  useEffect(() => {
    setPaginationData(previousState => ({
      ...previousState,
      total: data.length,
      dataSource: data.slice(
        (previousState.current - 1) * previousState.pageSize,
        previousState.current * previousState.pageSize
      ),
    }));
  }, [data]);

  const handleTableChange = pagination => {
    setPaginationData({
      showSizeChanger: true,
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: data.length,
      dataSource: data.slice(
        (pagination.current - 1) * pagination.pageSize,
        pagination.current * pagination.pageSize
      ),
    });
  };

  return { paginationData, setPaginationData, handleTableChange };
};

export default useTableLocalPagination;
