export const selectIsLoading = ({ filteredProducts: { isLoading } }) =>
  isLoading;
export const selectFilters = ({ filteredProducts: { filters } }) => filters;
export const selectProductBrands = ({ filteredProducts: { productBrands } }) =>
  productBrands;
export const selectProductNetContentUnits = ({
  filteredProducts: { productNetContentUnits },
}) => productNetContentUnits;
export const selectTreeProductCategories = ({
  filteredProducts: { treeProductCategories },
}) => treeProductCategories;
export const selectSelectedFilters = ({
  filteredProducts: { selectedFilters },
}) => selectedFilters;
export const selectAreFiltersLoading = ({
  filteredProducts: {
    isProductsBrandsLoading,
    isProductCategoriesLoading,
    isProductNetContentUnitsLoading,
    isTreeProductCategoriesLoading,
  },
}) =>
  isProductsBrandsLoading ||
  isProductCategoriesLoading ||
  isProductNetContentUnitsLoading ||
  isTreeProductCategoriesLoading;
export const selectProducts = ({ filteredProducts: { selectedProducts } }) =>
  selectedProducts;
export const selectTradeItems = ({ filteredProducts: { tradeItems } }) =>
  tradeItems;
export const selectValidatedProducts = ({
  filteredProducts: { validatedProducts },
}) => validatedProducts;
export const selectCategories = ({
  filteredProducts: { categoriesForValidatedProducts },
}) => categoriesForValidatedProducts;
export const selectIsSelectedItemsLoading = ({
  filteredProducts: { isSelectedProductsLoading },
}) => isSelectedProductsLoading;
export const selectIsTradeItemsLoading = ({
  filteredProducts: { isSetTradeItemsLoading },
}) => isSetTradeItemsLoading;
export const selectedProductTotal = ({
  filteredProducts: {
    currentAndTotal: { totalCount },
  },
}) => totalCount;
export const selectFilteredProductsPageSize = ({
  filteredProducts: { pageSize },
}) => pageSize;
export const selectSuppliers = ({ filteredProducts: { suppliers } }) =>
  suppliers;
