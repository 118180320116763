import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { setSelectedType, getProjectTypes } from "redux/actions/ProjectUI";
import { registerData } from "redux/actions/ProjectCreation";
import {
  selectWarnFields,
  selectData,
  selectTypes,
} from "common/selectors/projects.selectors";
import { selectOwningCompanyId } from "common/selectors/users.selectors";
import {
  Title,
  Spinner,
  Label,
  useLanguagePreference,
} from "@trace-one/react-components";
import { Input, Select } from "@trace-one/design-system";
import RichTextEditor from "components/Forms/RichTextEditor";
import { titleAndLabel } from "routes/Projects/defineMessages";
import styles from "./Definition.module.less";
import { selectValidatedProducts } from "common/selectors/filteredProducts.selectors";

const Definition = () => {
  const companyId = useSelector(selectOwningCompanyId);
  const validatedProducts = useSelector(selectValidatedProducts);
  const dispatch = useDispatch();
  const warnFields = useSelector(selectWarnFields);
  const data = useSelector(selectData);
  const types = useSelector(selectTypes);
  const languageCode = useLanguagePreference();

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (companyId != null) {
      dispatch(getProjectTypes({ companyId, languageCode }));
    }
  }, [companyId, dispatch, languageCode]);

  useEffect(() => {
    dispatch(registerData({ selectedProducts: validatedProducts }));
  }, [validatedProducts]);

  const onChange = e => {
    dispatch(registerData(e));
  };

  if (!companyId) return <Spinner />;
  return (
    <>
      <Title
        level={2}
        value={formatMessage(titleAndLabel.sectionTitleDef)}
        subtitle={formatMessage(titleAndLabel.sectionSubtitleDef)}
      />
      <form action="">
        <div className={styles.textinputs}>
          <Label title={formatMessage(titleAndLabel.labelName)} required>
            <Input
              name={"projectName"}
              placeholder={formatMessage(titleAndLabel.projectNamePlaceholder)}
              error={!!warnFields.find(f => f.name === "projectName")}
              errorMessage={
                warnFields.find(f => f.name === "projectName")?.message
              }
              value={data.projectName}
              data-test-id="projectNameInput"
              onChange={e => onChange({ projectName: e.currentTarget.value })}
            />
          </Label>

          <Label title={formatMessage(titleAndLabel.labelCodeName)}>
            <Input
              name={"projectCode"}
              placeholder={formatMessage(titleAndLabel.projectCodePlaceholder)}
              value={data.projectCode}
              data-test-id="projectCodeNameInput"
              onChange={e => onChange({ projectCode: e.currentTarget.value })}
            />
          </Label>
          <Label title={formatMessage(titleAndLabel.labelType)} required>
            <Select
              placeholder={formatMessage(titleAndLabel.selectTypePlaceholder)}
              error={!!warnFields.find(f => f.name === "projectTypeId")}
              errorMessage={
                warnFields.find(f => f.name === "projectTypeId")?.message
              }
              value={data.projectTypeId === "" ? undefined : data.projectTypeId}
              options={types.map(({ name, id }) => ({ name, value: id }))}
              onChange={value => {
                onChange({ projectTypeId: value, tasks: [] });
                dispatch(
                  setSelectedType(types.find(type => type.id === value))
                );
              }}
              data-test-id="projectTypeSelect"
              name="projectTypeId"
            />
          </Label>
        </div>
        <Label title="Description">
          <RichTextEditor
            name={"description"}
            data={data.description}
            // The text editor creates an iframe. It's not possible to test
            onChange={
              /* istanbul ignore next */ val => onChange({ description: val })
            }
          />
        </Label>
      </form>
    </>
  );
};
export default Definition;
