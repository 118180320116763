import { useState, useEffect, FC } from "react";
import { defineMessages, useIntl } from "react-intl";
import { statusNames } from "constants/projects";
import { ITraceOneTable } from "./ITraceOneTable";
import { Tooltip, Datatable, Checkbox } from "@trace-one/design-system";
import { shouldPaginationBeDisplay } from "utils/general";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import ResizeableTitle from "./ResizableTitle";

const tooltipMessage = defineMessages({
  projectList: {
    id: "projectList.table.checkboxTooltip",
    defaultMessage:
      "You don't have sufficient permissions to edit this project, please contact the project owner",
  },
  taskList: {
    id: "taskList.table.checkboxTooltip",
    defaultMessage:
      "You don't have sufficient permissions to edit this task, please contact the project owner",
  },
  default: {
    id: "components.table.checkBoxTolltip",
    defaultMessage:
      "You don't have sufficient permissions to edit this, please contact the project owner",
  },
});

const TraceOneTable: FC<ITraceOneTable> = ({
  columns,
  data,
  loading,
  pagination,
  noDataText,
  languageCode,
  selectedItems,
  updateSelection,
  selectAll,
  allChecked,
  enableSelection = false,
  canNotSelect = record => true,
  hasArchived = false,
  tableContext = "default",
  taskListSortOrder,
  scroll = { x: "max-content" },
  ...props
}) => {
  const [resizedcolumns, setColumns] = useState([]);
  const [isAllChecked, setAllChecked] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    setColumns(resizedcolumns);
  }, [resizedcolumns]);

  useEffect(() => {
    setAllChecked(allChecked);
  }, [allChecked]);

  useEffect(() => {
    setColumns(columns);
  }, [languageCode, columns]);

  const resizableColumns = resizedcolumns.map((col, index) => ({
    ...col,
    onHeaderCell: column => {
      return {
        width: column.width,
        onResize: handleResizeFunc(index),
        onClick: () => {
          for (let i in taskListSortOrder?.current) {
            taskListSortOrder.current[i] =
              i === column.key
                ? taskListSortOrder?.current[i] === "ascend"
                  ? "descend"
                  : taskListSortOrder?.current[column.key] === "descend"
                  ? null
                  : "ascend"
                : null;
          }
        },
      };
    },
  }));

  const handleResizeFunc =
    index =>
    (e, { size }) => {
      setColumns(prevState => {
        const nextColumns = [...prevState];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };
        return nextColumns;
      });
    };

  const components = {
    header: {
      cell: ResizeableTitle,
    },
  };

  const removeItemFromSelection = idToRemove =>
    updateSelection(selectedItems.filter(item => item !== idToRemove));

  const addItemToSelection = idToAdd =>
    updateSelection([...selectedItems, idToAdd]);

  const CustomSelectAll = () => (
    <Checkbox
      data-test-id="selectAll-checkbox"
      checked={isAllChecked}
      disabled={hasArchived}
      onChange={() => {
        selectAll();
        setAllChecked(!isAllChecked);
      }}
    />
  );

  const renderCellCheckBox = (checked, record) => {
    const handleCheck = e => {
      const { id } = record;
      const { checked } = e.target;
      if (checked) {
        addItemToSelection(id);
      } else {
        removeItemFromSelection(id);
      }
    };

    const renderCheckBox = () => (
      <Checkbox
        disabled={canNotSelect(record)}
        checked={checked}
        onChange={handleCheck}
        name={record.name}
      />
    );

    return (
      (canNotSelect(record) &&
        record.status !== statusNames.Completed &&
        record.status !== statusNames.Archived && (
          <Tooltip
            title={intl.formatMessage(tooltipMessage[tableContext])}
            placement="topLeft"
            align={{ offset: [-8, 0] }}
          >
            {renderCheckBox()}
          </Tooltip>
        )) ||
      renderCheckBox()
    );
  };

  const rowSelection = {
    selectedRowKeys: selectedItems,
    selections: true,
    columnTitle: CustomSelectAll,
    renderCell: renderCellCheckBox,
    columnWidth: 20,
  };

  const PAGE_SIZE_OPTIONS = ["10", "20", "30", "40", "50"];

  return (
    <Datatable
      expandIcon={({ expanded, onExpand, record }) =>
        record.status !== statusNames.Archived ? (
          expanded ? (
            <DownOutlined
              onClick={e => onExpand(record, e)}
              style={{ color: "#00a499" }}
            />
          ) : (
            <RightOutlined onClick={e => onExpand(record, e)} />
          )
        ) : (
          <div />
        )
      }
      rowKey={data => data.id}
      loading={loading}
      columns={resizableColumns}
      dataSource={data}
      emptyText={noDataText}
      pagination={{
        ...pagination,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
        hideOnSinglePage: !shouldPaginationBeDisplay(pagination?.total),
      }}
      rowSelection={
        enableSelection && {
          type: "checkbox",
          ...rowSelection,
        }
      }
      components={components}
      scroll={scroll}
      {...props}
    />
  );
};

TraceOneTable.defaultProps = {
  loading: false,
  columns: [],
  data: [],
  enableSelection: false,
  canNotSelect: record => true,
  tableContext: "default",
};

export default TraceOneTable;
