import {
  SET_PROJECTS_LIST_FILTERS,
  SET_TASKS_LIST_FILTERS,
} from "redux/actions/Filters/types";

export const initialState = {
  projectsList: {
    // myProjects: false,
    // draft: false,
    // inProgress: false,
    // late: false,
    // scheduled: false,
    searchText: "",
    showOnlyUnarchived: true,
  },
  tasksList: {},
};

export default function filtersReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_PROJECTS_LIST_FILTERS:
      return {
        ...state,
        projectsList: {
          ...payload,
        },
      };
    case SET_TASKS_LIST_FILTERS:
      return {
        ...state,
        tasksList: {
          ...payload,
        },
      };
    default:
      return state;
  }
}
