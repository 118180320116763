import { Column, ColumnStore } from "bryntum-gantt";
import { renderToString } from "react-dom/server";
import { Tag } from "@trace-one/design-system";
import styles from "routes/Tasks/TasksList/components/TableTasks.module.less";
import moment from "moment";

export default class EstimatedEndDateColumn extends Column {
  static get type() {
    return "dueDateUtc";
  }

  static get isGanttColumn() {
    return true;
  }

  static get defaults() {
    return {
      text: "Duration",
      htmlEncode: false,
    };
  }

  static getTimeFormat(wasRun) {
    if (wasRun) {
      return "DD/MM/YYYY HH:mm";
    } else {
      return "DD/MM/YYYY";
    }
  }

  static getColor(endDate, initialDueDateUtc, status) {
    const initialDueDate = moment(initialDueDateUtc).format("YYYY-MM-DD HH:mm");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD HH:mm");
    let colorValue = "green";

    if (initialDueDate == null) {
      colorValue = "green";
    } else if (endDateFormatted <= initialDueDate) {
      colorValue = "green";
    } else if (endDateFormatted > initialDueDate) {
      colorValue = "orange";
    }

    if (
      (status === "InProgress" || status === "Future") &&
      moment().format("YYYY-MM-DD HH:mm") > initialDueDate
    ) {
      colorValue = "orange";
    }
    return colorValue;
  }

  static statusCheck(status) {
    if (
      status === "Approved" ||
      status === "Completed" ||
      status === "Rejected"
    )
      return true;
  }

  renderer(rowData) {
    const {
      record: {
        originalData: {
          initialDueDateUtc,
          stepStatus,
          taskStatus,
          id,
          endDateUtc,
        },
        endDate,
      },
    } = rowData;

    const {
      column: {
        data: { wasRun },
      },
    } = rowData;

    let status = "Undefined";
    if (taskStatus) {
      status = taskStatus;
    } else if (stepStatus) {
      status = stepStatus;
    }
    if (
      EstimatedEndDateColumn.statusCheck(status) ||
      (id === "projectRow" && endDateUtc)
    )
      return "-";
    return renderToString(
      <Tag
        className={styles.setTagEstimatedEndDate}
        color={EstimatedEndDateColumn.getColor(
          endDate,
          initialDueDateUtc,
          status
        )}
        label={moment(endDate).format(
          EstimatedEndDateColumn.getTimeFormat(wasRun)
        )}
        size="medium"
        mode="light"
      />
    );
  }
}

export class EstimatedEndDateColumnCreation extends EstimatedEndDateColumn {
  static get type() {
    return "dueDateUtcCreation";
  }

  static getColor(endDate, initialDueDateUtc, status) {
    const initialDueDate = moment(initialDueDateUtc).format("YYYY-MM-DD HH:MM");
    const endDateFormatted = moment(endDate).format("YYYY-MM-DD HH:MM");
    let colorValue = "green";

    if (initialDueDate == null) {
      colorValue = "green";
    } else if (endDateFormatted <= initialDueDate) {
      colorValue = "green";
    } else if (endDateFormatted > initialDueDate) {
      colorValue = "orange";
    }

    if (
      (status === "InProgress" || status === "Future") &&
      moment().format("YYYY-MM-DD HH:MM") > initialDueDate
    ) {
      colorValue = "orange";
    }
    return colorValue;
  }

  static statusCheck(status) {
    if (
      status === "Approved" ||
      status === "Completed" ||
      status === "Rejected"
    )
      return true;
  }

  renderer({
    record: {
      originalData: {
        initialDueDateUtc,
        stepStatus,
        taskStatus,
        id,
        endDateUtc,
      },
      endDate,
    },
  }) {
    let status = "Undefined";
    if (taskStatus) {
      status = taskStatus;
    } else if (stepStatus) {
      status = stepStatus;
    }
    if (
      EstimatedEndDateColumn.statusCheck(status) ||
      (id === "projectRow" && endDateUtc)
    )
      return "-";
    return renderToString(
      <Tag
        className={styles.setTagEstimatedEndDate}
        color={EstimatedEndDateColumn.getColor(
          endDate,
          initialDueDateUtc,
          status
        )}
        label={moment(endDate).format("DD/MM/YYYY")}
        size="medium"
        mode="light"
      />
    );
  }
}

// Register both column types with the ColumnStore
ColumnStore.registerColumnType(EstimatedEndDateColumn);
ColumnStore.registerColumnType(EstimatedEndDateColumnCreation);
