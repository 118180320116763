import { defineMessages } from "react-intl";

export const statusNames = {
  Completed: "Completed",
  Approved: "Approved",
  Rejected: "Rejected",
  InProgress: "In progress",
  InProgressOneWord: "InProgress",
  Future: "Future",
  OnHold: "OnHold",
  Cancelled: "Cancelled",
  Undefined: "",
};

export const getStatusName = (status, intl) => {
  const statusName = defineMessages({
    Completed: {
      id: "constants.tasks.completed",
      defaultMessage: "Completed",
    },
    Approved: {
      id: "constants.tasks.approved",
      defaultMessage: "Approved",
    },
    Rejected: {
      id: "constants.tasks.rejected",
      defaultMessage: "Rejected",
    },
    InProgress: {
      id: "constants.tasks.inProgress",
      defaultMessage: "In Progress",
    },
    Future: {
      id: "constants.tasks.future",
      defaultMessage: "Future",
    },
    OnHold: {
      id: "constants.tasks.onHold",
      defaultMessage: "On Hold",
    },
    Cancelled: {
      id: "constants.tasks.cancelled",
      defaultMessage: "Cancelled",
    },
    Draft: {
      id: "constants.tasks.draft",
      defaultMessage: "Draft",
    },
    Archived: {
      id: "constants.tasks.archived",
      defaultMessage: "Archived",
    },
    Undefined: {
      id: "constants.tasks.undefined",
      defaultMessage: "-",
    },
  });
  return intl.formatMessage(statusName[status] ?? statusName.Undefined);
};

export const getProgressLabel = (progressCode, intl) => {
  const progressName = defineMessages({
    Behind: {
      id: "constants.tasks.progress.behind",
      defaultMessage: "Behind",
    },
    OnTrack: {
      id: "constants.tasks.progress.onTrack",
      defaultMessage: "On Track",
    },
    Late: {
      id: "constants.tasks.progress.late",
      defaultMessage: "Late",
    },
    OnTime: {
      id: "constants.tasks.progress.onTime",
      defaultMessage: "On Time",
    },
    Ahead: {
      id: "constants.tasks.progress.ahead",
      defaultMessage: "Ahead",
    },
    RemainingTime: {
      id: "constants.tasks.progress.remainingTime",
      defaultMessage: "Remaining",
    },
    Undefined: {
      id: "constants.tasks.undefined",
      defaultMessage: "-",
    },
  });
  return intl.formatMessage(
    progressName[progressCode] ?? progressName.Undefined
  );
};

export const getProgressName = (progressCode, progressDays, intl) => {
  const progressName = defineMessages({
    Behind: {
      id: "constants.tasks.progress.behind",
      defaultMessage: "Behind",
    },
    OnTime: {
      id: "constants.tasks.progress.onTime",
      defaultMessage: "On Time",
    },
    OnTrack: {
      id: "constants.tasks.progress.onTrack",
      defaultMessage: "On Track",
    },
    Late: {
      id: "constants.tasks.progress.late",
      defaultMessage: "Late",
    },
    Ahead: {
      id: "constants.tasks.progress.ahead",
      defaultMessage: "Ahead",
    },
    RemainingTime: {
      id: "constants.tasks.progress.remainingTime",
      defaultMessage: "Remaining",
    },
    Undefined: {
      id: "constants.tasks.undefined",
      defaultMessage: "-",
    },
  });
  return `${intl.formatMessage(
    progressName[progressCode] ?? progressName.Undefined
  )}${
    progressDays
      ? ` (${progressDays} ${intl.formatMessage({
          id: "tasks.components.taskDetail.taskHeader.days",
          defaultMessage: "days",
        })})`
      : ""
  }`;
};

export const statusNamesId = {
  Completed: "completed",
  Approved: "approved",
  Rejected: "rejected",
  InProgress: "inProgress",
  Future: "future",
  OnHold: "onHold",
  Cancelled: "cancelled",
  Draft: "draft",
};

export const tasksProgressId = {
  OnTrack: "onTrack",
  Late: "late",
};

export const getStatusStyle = {
  Completed: "green",
  Approved: "green",
  Rejected: "red",
  InProgress: "blue",
  Future: "purple",
  OnHold: "grey",
  Cancelled: "red",
  Undefined: "grey",
  Draft: "grey",
  Archived: "grey",
};

export const getProgressStyle = {
  Behind: "red",
  OnTime: "orange",
  Ahead: "green",
  RemainingTime: "blue",
  Undefined: "grey",
};

export const filterIdToName = (isMyTasksActive, intl) => {
  const filterInTaskList = defineMessages({
    myTasks: {
      id: "constants.tasks.myTasksFilter",
      defaultMessage: "My tasks",
    },
    myInProgress: {
      id: "constants.tasks.myInProgressFilter",
      defaultMessage: "My in progress tasks",
    },
    inProgress: {
      id: "constants.tasks.inProgressFilter",
      defaultMessage: "Tasks in progress",
    },
    myInProgressLate: {
      id: "constants.tasks.myInProgressLateFilter",
      defaultMessage: "My Late tasks",
    },
    late: {
      id: "constants.tasks.lateTasksFilter",
      defaultMessage: "Late tasks",
    },
    myFuture: {
      id: "constants.tasks.myFutureFilter",
      defaultMessage: "My future tasks",
    },
    future: {
      id: "constants.tasks.futureFilter",
      defaultMessage: "Future tasks",
    },
  });
  return {
    myTasks: intl.formatMessage(filterInTaskList.myTasks),
    inProgress: `${
      isMyTasksActive
        ? intl.formatMessage(filterInTaskList.myInProgress)
        : intl.formatMessage(filterInTaskList.inProgress)
    }`,
    late: `${
      isMyTasksActive
        ? intl.formatMessage(filterInTaskList.myInProgressLate)
        : intl.formatMessage(filterInTaskList.late)
    }`,
    future: `${
      isMyTasksActive
        ? intl.formatMessage(filterInTaskList.myFuture)
        : intl.formatMessage(filterInTaskList.future)
    }`,
  };
};

export const filterIds = {
  myTasks: "myTasks",
  future: "future",
  inProgress: "inProgress",
  late: "late",
};

export const filterIdToKpi = {
  myTasks: "myTasks",
  future: "futureTasks",
  inProgress: "inProgressTasks",
  late: "late",
};
