/* istanbul ignore file */
import { createStore } from "redux";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import Reducers from "./reducers";
import middlewares from "./middlewares";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["ganttView", "collapseKeys"],
};

const persistedReducer = persistReducer(persistConfig, Reducers);

export const Store = createStore(persistedReducer, middlewares);
export const persistor = persistStore(Store);
