import { SET_COLLAPSE_KEYS } from "../../constants";

export const initialState = {
  collapseKeys: ["0"],
};

const collapseKeysReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_COLLAPSE_KEYS:
      return {
        ...state,
        collapseKeys: payload,
      };
    default:
      return state;
  }
};

export default collapseKeysReducer;
