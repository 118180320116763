import React from "react";
import { Tooltip } from "@trace-one/react-components";
import styles from "./TeamMembers.module.less";
import { v4 as uuidv4 } from "uuid";
export interface TeamMembersProps {
  team?: any;
}
export const TeamMembers: React.FC<TeamMembersProps> = ({ team }) => {
  return (
    <ul className={styles.teamMembers}>
      {team.map(
        (
          { colour, userFirstName: fn, userLastName: ln, userPhotoUrl: photo },
          i
        ) => (
          <li
            key={uuidv4()}
            className={styles.teamMember}
            style={{ backgroundColor: colour }}
          >
            <Tooltip title={`${fn} ${ln}`}>
              {photo ? (
                <img src={photo} alt={`${fn} ${ln}`} />
              ) : (
                `${fn?.charAt(0)} ${ln?.charAt(0)}`
              )}
            </Tooltip>
          </li>
        )
      )}
    </ul>
  );
};
