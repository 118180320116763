import { SET_ERRORS_EVENTS, RESET_ERRORS_EVENTS } from "../../constants";

export const initialState = {
  errors: {
    hasError: false,
    error: {},
  },
};

export default function deploymentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ERRORS_EVENTS:
      return {
        ...state,
        errors: payload,
      };
    case RESET_ERRORS_EVENTS:
      return { ...initialState };
    default:
      return state;
  }
}
