import moment from "moment";

export const getLocalDate = date => moment(date).local().format("DD/MM/YYYY");

export const getLocalDateWithTime = date =>
  moment(date).local().format("DD/MM/YYYY HH:mm");

export const getBusinessDayAfterDuration = duration => {
  const currentDate = moment.utc();
  for (let i = 0; i < duration; i++) {
    currentDate.nextBusinessDay();
  }
  return currentDate;
};

export const getCorrectDateFormat = (locale, date) => {
  if (date) return moment(date).locale(locale).format("DD/MM/YYYY HH:mm");
};

export const customTimeTrackedCeilMethod = timetracked => {
  let integerPart = Math.floor(timetracked);
  let decimalPart = timetracked - integerPart;
  if (integerPart < 1) {
    return "<1";
  }
  if (decimalPart > 0 && decimalPart < 0.25) {
    return integerPart + 0.25;
  } else if (decimalPart >= 0.25 && decimalPart < 0.5) {
    return integerPart + 0.5;
  } else if (decimalPart >= 0.5 && decimalPart < 0.75) {
    return integerPart + 0.75;
  } else if (decimalPart >= 0.75) {
    return integerPart + 1;
  } else {
    return integerPart;
  }
};

export const getTimeTrackedInDays = daysValue => {
  const ceiledValue = customTimeTrackedCeilMethod(daysValue);
  if (Number.isNaN(ceiledValue)) {
    return "-";
  } else {
    const suffix = ceiledValue > 1 ? "s" : "";
    return `${ceiledValue} day${suffix}`;
  }
};
